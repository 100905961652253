import { FC, ReactNode } from 'react';
import LoadingScreen from 'src/components/LoadingScreen';

interface SuspenseLoaderProps {
    loading: boolean;
    children: ReactNode;
}

const SuspenseLoader: FC<SuspenseLoaderProps> = ({ loading, children }) => (
    <>
        {
            loading
                ? <LoadingScreen />
                : <>{children}</>
        }
    </>
)

export default SuspenseLoader;
