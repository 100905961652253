
import { Button, ButtonProps, DialogProps } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface ConfirmButtonProps extends Omit<ButtonProps, "onClick"> {
    dialogTitle?: string;
    dialogDescription?: string;
    dialogContent?: ReactNode;
    confirmationText?: string;
    cancellationText?: string;
    dialogProps?: Omit<DialogProps, "open">;
    children: ReactNode;
    onConfirm: () => void;
    onDeny?: () => void;
}

const ConfirmButton: FC<ConfirmButtonProps> = ({
    dialogTitle,
    dialogDescription,
    dialogContent,
    confirmationText,
    cancellationText,
    children,
    onConfirm,
    onDeny,
    ...others
}) => {
    const { t } = useTranslation();
    const confirm = useConfirm()
    return (
        <Button
            onClick={async () => {
                confirm({
                    title: !dialogTitle && !dialogDescription && !dialogContent ? `${t('glossary:confirm').capitalize()}?` : dialogTitle,
                    description: dialogDescription,
                    content: dialogContent,
                    confirmationText: confirmationText || t('glossary:ok').capitalize(),
                    cancellationText: cancellationText || t('glossary:cancel').capitalize()
                })
                    .then(async () => onConfirm && (await onConfirm()))
                    .catch(async () => onDeny && (await onDeny()))
            }}
            {...others}
        >
            {children}
        </Button >
    );
}

export default ConfirmButton;