
import { useState, Fragment, FC } from 'react';
import ReactDOM from "react-dom/client";
import { IconButton, Snackbar, SnackbarOrigin } from '@mui/material';
import { MdClose } from 'react-icons/md'

interface NotifyProps {
    message: string,
    position: SnackbarOrigin
}

const Toast: FC<NotifyProps> = (props) => {
    const [open, setOpen] = useState(true);

    const closeNotify = async () => {
        setOpen(false);
        let container = document.getElementById('notify');
        if (container)
            container.remove();
    }
    return (
        <>
            <Snackbar
                anchorOrigin={props.position}
                open={open}
                autoHideDuration={4000}
                onClose={closeNotify}
                message={props.message}
                action={
                    <Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={closeNotify}>
                            <MdClose />
                        </IconButton>
                    </Fragment>
                }
            />
        </>
    )
}

export const makeToast = (message: string, position = { vertical: 'top', horizontal: 'right' } as SnackbarOrigin): FC<NotifyProps> => {
    let container = document.createElement('div');
    container.id = "notify";
    document.body.append(container);
    ReactDOM.createRoot(document.getElementById('notify') as HTMLElement).render(
        <Toast message={message} position={position} />
    )
    return Toast;
}