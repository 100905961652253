import { useAtom } from 'jotai';
import type { FC, ReactNode } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthStore } from 'src/store';

interface GuestGuardProps {
  children?: ReactNode;
}

const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
  const [authStore,] = useAtom(AuthStore.atom)

  if (authStore.isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  return <>{children || <Outlet />}</>;
};

export default GuestGuard;
