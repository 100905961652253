/* tslint:disable */
/* eslint-disable */
/**
 * Archivio Andrea Marescalchi | API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Artwork
 */
export interface Artwork {
    /**
     * 
     * @type {number}
     * @memberof Artwork
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Artwork
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Artwork
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Artwork
     */
    deletedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Artwork
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof Artwork
     */
    notes?: string;
    /**
     * 
     * @type {number}
     * @memberof Artwork
     */
    year?: number;
    /**
     * 
     * @type {number}
     * @memberof Artwork
     */
    width?: number;
    /**
     * 
     * @type {number}
     * @memberof Artwork
     */
    height?: number;
    /**
     * 
     * @type {number}
     * @memberof Artwork
     */
    depth?: number;
    /**
     * 
     * @type {string}
     * @memberof Artwork
     */
    support?: ArtworkSupportEnum;
    /**
     * 
     * @type {string}
     * @memberof Artwork
     */
    digitalObject?: string;
    /**
     * 
     * @type {string}
     * @memberof Artwork
     */
    placement?: string;
    /**
     * 
     * @type {Technique}
     * @memberof Artwork
     */
    technique?: Technique;
    /**
     * 
     * @type {Array<Topic>}
     * @memberof Artwork
     */
    topics?: Array<Topic>;
    /**
     * 
     * @type {Array<Exhibition>}
     * @memberof Artwork
     */
    exhibitions?: Array<Exhibition>;
    /**
     * 
     * @type {Array<Document>}
     * @memberof Artwork
     */
    documents?: Array<Document>;
    /**
     * 
     * @type {Array<ArtworkOwner>}
     * @memberof Artwork
     */
    owners?: Array<ArtworkOwner>;
    /**
     * 
     * @type {Array<Media>}
     * @memberof Artwork
     */
    gallery?: Array<Media>;
}

/**
    * @export
    * @enum {string}
    */
export enum ArtworkSupportEnum {
    Tela = 'tela',
    Carta = 'carta',
    CartaIntelaiata = 'carta intelaiata',
    Scultura = 'scultura',
    Tavola = 'tavola'
}

/**
 * 
 * @export
 * @interface ArtworkOwner
 */
export interface ArtworkOwner {
    /**
     * 
     * @type {number}
     * @memberof ArtworkOwner
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ArtworkOwner
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ArtworkOwner
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ArtworkOwner
     */
    deletedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ArtworkOwner
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ArtworkOwner
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof ArtworkOwner
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ArtworkOwner
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof ArtworkOwner
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof ArtworkOwner
     */
    type?: ArtworkOwnerTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ArtworkOwner
     */
    taxCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ArtworkOwner
     */
    vatNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ArtworkOwner
     */
    notes?: string;
    /**
     * 
     * @type {Array<Artwork>}
     * @memberof ArtworkOwner
     */
    artworks?: Array<Artwork>;
    /**
     * 
     * @type {boolean}
     * @memberof ArtworkOwner
     */
    isCurrent?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ArtworkOwner
     */
    acquisitionDate?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ArtworkOwnerTypeEnum {
    Private = 'private',
    Company = 'company'
}

/**
 * 
 * @export
 * @interface CloneMediaDTO
 */
export interface CloneMediaDTO {
    /**
     * 
     * @type {Array<Media>}
     * @memberof CloneMediaDTO
     */
    media: Array<Media>;
    /**
     * 
     * @type {MediaPath}
     * @memberof CloneMediaDTO
     */
    path: MediaPath;
}
/**
 * 
 * @export
 * @interface CloneMediaPathDTO
 */
export interface CloneMediaPathDTO {
    /**
     * 
     * @type {Array<MediaPath>}
     * @memberof CloneMediaPathDTO
     */
    paths: Array<MediaPath>;
    /**
     * 
     * @type {MediaPath}
     * @memberof CloneMediaPathDTO
     */
    destination: MediaPath;
}
/**
 * 
 * @export
 * @interface CreateManyArtworkDto
 */
export interface CreateManyArtworkDto {
    /**
     * 
     * @type {Array<Artwork>}
     * @memberof CreateManyArtworkDto
     */
    bulk: Array<Artwork>;
}
/**
 * 
 * @export
 * @interface CreateManyDocumentDto
 */
export interface CreateManyDocumentDto {
    /**
     * 
     * @type {Array<Document>}
     * @memberof CreateManyDocumentDto
     */
    bulk: Array<Document>;
}
/**
 * 
 * @export
 * @interface CreateManyExhibitionDto
 */
export interface CreateManyExhibitionDto {
    /**
     * 
     * @type {Array<Exhibition>}
     * @memberof CreateManyExhibitionDto
     */
    bulk: Array<Exhibition>;
}
/**
 * 
 * @export
 * @interface CreateManyLanguageDto
 */
export interface CreateManyLanguageDto {
    /**
     * 
     * @type {Array<Language>}
     * @memberof CreateManyLanguageDto
     */
    bulk: Array<Language>;
}
/**
 * 
 * @export
 * @interface CreateManyMailDto
 */
export interface CreateManyMailDto {
    /**
     * 
     * @type {Array<Mail>}
     * @memberof CreateManyMailDto
     */
    bulk: Array<Mail>;
}
/**
 * 
 * @export
 * @interface CreateManyMediaDto
 */
export interface CreateManyMediaDto {
    /**
     * 
     * @type {Array<Media>}
     * @memberof CreateManyMediaDto
     */
    bulk: Array<Media>;
}
/**
 * 
 * @export
 * @interface CreateManyMediaPathDto
 */
export interface CreateManyMediaPathDto {
    /**
     * 
     * @type {Array<MediaPath>}
     * @memberof CreateManyMediaPathDto
     */
    bulk: Array<MediaPath>;
}
/**
 * 
 * @export
 * @interface CreateManyOwnerDto
 */
export interface CreateManyOwnerDto {
    /**
     * 
     * @type {Array<Owner>}
     * @memberof CreateManyOwnerDto
     */
    bulk: Array<Owner>;
}
/**
 * 
 * @export
 * @interface CreateManyPlaceDto
 */
export interface CreateManyPlaceDto {
    /**
     * 
     * @type {Array<Place>}
     * @memberof CreateManyPlaceDto
     */
    bulk: Array<Place>;
}
/**
 * 
 * @export
 * @interface CreateManyTechniqueDto
 */
export interface CreateManyTechniqueDto {
    /**
     * 
     * @type {Array<Technique>}
     * @memberof CreateManyTechniqueDto
     */
    bulk: Array<Technique>;
}
/**
 * 
 * @export
 * @interface CreateManyTopicDto
 */
export interface CreateManyTopicDto {
    /**
     * 
     * @type {Array<Topic>}
     * @memberof CreateManyTopicDto
     */
    bulk: Array<Topic>;
}
/**
 * 
 * @export
 * @interface CreateManyUserDto
 */
export interface CreateManyUserDto {
    /**
     * 
     * @type {Array<User>}
     * @memberof CreateManyUserDto
     */
    bulk: Array<User>;
}
/**
 * 
 * @export
 * @interface Document
 */
export interface Document {
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    deletedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    name?: string;
    /**
     * 
     * @type {Media}
     * @memberof Document
     */
    image?: Media;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    support?: DocumentSupportEnum;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    typology?: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    placement?: string;
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    year?: number;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    description?: string;
    /**
     * 
     * @type {Array<Artwork>}
     * @memberof Document
     */
    artworks?: Array<Artwork>;
    /**
     * 
     * @type {Array<Topic>}
     * @memberof Document
     */
    topics?: Array<Topic>;
}

/**
    * @export
    * @enum {string}
    */
export enum DocumentSupportEnum {
    Cartaceo = 'cartaceo',
    Digitale = 'digitale'
}

/**
 * 
 * @export
 * @interface EmailDto
 */
export interface EmailDto {
    /**
     * 
     * @type {string}
     * @memberof EmailDto
     */
    email: string;
}
/**
 * 
 * @export
 * @interface Exhibition
 */
export interface Exhibition {
    /**
     * 
     * @type {number}
     * @memberof Exhibition
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Exhibition
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Exhibition
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Exhibition
     */
    deletedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Exhibition
     */
    title?: string;
    /**
     * 
     * @type {Place}
     * @memberof Exhibition
     */
    place?: Place;
    /**
     * 
     * @type {string}
     * @memberof Exhibition
     */
    address?: string;
    /**
     * 
     * @type {number}
     * @memberof Exhibition
     */
    year?: number;
    /**
     * 
     * @type {number}
     * @memberof Exhibition
     */
    month?: number;
    /**
     * 
     * @type {Array<Artwork>}
     * @memberof Exhibition
     */
    artworks?: Array<Artwork>;
    /**
     * 
     * @type {Array<Media>}
     * @memberof Exhibition
     */
    gallery?: Array<Media>;
}
/**
 * 
 * @export
 * @interface GetManyArtworkResponseDto
 */
export interface GetManyArtworkResponseDto {
    /**
     * 
     * @type {Array<Artwork>}
     * @memberof GetManyArtworkResponseDto
     */
    data: Array<Artwork>;
    /**
     * 
     * @type {number}
     * @memberof GetManyArtworkResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyArtworkResponseDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyArtworkResponseDto
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyArtworkResponseDto
     */
    pageCount: number;
}
/**
 * 
 * @export
 * @interface GetManyDocumentResponseDto
 */
export interface GetManyDocumentResponseDto {
    /**
     * 
     * @type {Array<Document>}
     * @memberof GetManyDocumentResponseDto
     */
    data: Array<Document>;
    /**
     * 
     * @type {number}
     * @memberof GetManyDocumentResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyDocumentResponseDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyDocumentResponseDto
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyDocumentResponseDto
     */
    pageCount: number;
}
/**
 * 
 * @export
 * @interface GetManyExhibitionResponseDto
 */
export interface GetManyExhibitionResponseDto {
    /**
     * 
     * @type {Array<Exhibition>}
     * @memberof GetManyExhibitionResponseDto
     */
    data: Array<Exhibition>;
    /**
     * 
     * @type {number}
     * @memberof GetManyExhibitionResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyExhibitionResponseDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyExhibitionResponseDto
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyExhibitionResponseDto
     */
    pageCount: number;
}
/**
 * 
 * @export
 * @interface GetManyLanguageResponseDto
 */
export interface GetManyLanguageResponseDto {
    /**
     * 
     * @type {Array<Language>}
     * @memberof GetManyLanguageResponseDto
     */
    data: Array<Language>;
    /**
     * 
     * @type {number}
     * @memberof GetManyLanguageResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyLanguageResponseDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyLanguageResponseDto
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyLanguageResponseDto
     */
    pageCount: number;
}
/**
 * 
 * @export
 * @interface GetManyMailResponseDto
 */
export interface GetManyMailResponseDto {
    /**
     * 
     * @type {Array<Mail>}
     * @memberof GetManyMailResponseDto
     */
    data: Array<Mail>;
    /**
     * 
     * @type {number}
     * @memberof GetManyMailResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyMailResponseDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyMailResponseDto
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyMailResponseDto
     */
    pageCount: number;
}
/**
 * 
 * @export
 * @interface GetManyMediaPathResponseDto
 */
export interface GetManyMediaPathResponseDto {
    /**
     * 
     * @type {Array<MediaPath>}
     * @memberof GetManyMediaPathResponseDto
     */
    data: Array<MediaPath>;
    /**
     * 
     * @type {number}
     * @memberof GetManyMediaPathResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyMediaPathResponseDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyMediaPathResponseDto
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyMediaPathResponseDto
     */
    pageCount: number;
}
/**
 * 
 * @export
 * @interface GetManyMediaResponseDto
 */
export interface GetManyMediaResponseDto {
    /**
     * 
     * @type {Array<Media>}
     * @memberof GetManyMediaResponseDto
     */
    data: Array<Media>;
    /**
     * 
     * @type {number}
     * @memberof GetManyMediaResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyMediaResponseDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyMediaResponseDto
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyMediaResponseDto
     */
    pageCount: number;
}
/**
 * 
 * @export
 * @interface GetManyOwnerResponseDto
 */
export interface GetManyOwnerResponseDto {
    /**
     * 
     * @type {Array<Owner>}
     * @memberof GetManyOwnerResponseDto
     */
    data: Array<Owner>;
    /**
     * 
     * @type {number}
     * @memberof GetManyOwnerResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyOwnerResponseDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyOwnerResponseDto
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyOwnerResponseDto
     */
    pageCount: number;
}
/**
 * 
 * @export
 * @interface GetManyPlaceResponseDto
 */
export interface GetManyPlaceResponseDto {
    /**
     * 
     * @type {Array<Place>}
     * @memberof GetManyPlaceResponseDto
     */
    data: Array<Place>;
    /**
     * 
     * @type {number}
     * @memberof GetManyPlaceResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyPlaceResponseDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyPlaceResponseDto
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyPlaceResponseDto
     */
    pageCount: number;
}
/**
 * 
 * @export
 * @interface GetManyTechniqueResponseDto
 */
export interface GetManyTechniqueResponseDto {
    /**
     * 
     * @type {Array<Technique>}
     * @memberof GetManyTechniqueResponseDto
     */
    data: Array<Technique>;
    /**
     * 
     * @type {number}
     * @memberof GetManyTechniqueResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyTechniqueResponseDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyTechniqueResponseDto
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyTechniqueResponseDto
     */
    pageCount: number;
}
/**
 * 
 * @export
 * @interface GetManyTopicResponseDto
 */
export interface GetManyTopicResponseDto {
    /**
     * 
     * @type {Array<Topic>}
     * @memberof GetManyTopicResponseDto
     */
    data: Array<Topic>;
    /**
     * 
     * @type {number}
     * @memberof GetManyTopicResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyTopicResponseDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyTopicResponseDto
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyTopicResponseDto
     */
    pageCount: number;
}
/**
 * 
 * @export
 * @interface GetManyUserResponseDto
 */
export interface GetManyUserResponseDto {
    /**
     * 
     * @type {Array<User>}
     * @memberof GetManyUserResponseDto
     */
    data: Array<User>;
    /**
     * 
     * @type {number}
     * @memberof GetManyUserResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyUserResponseDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyUserResponseDto
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyUserResponseDto
     */
    pageCount: number;
}
/**
 * 
 * @export
 * @interface JwtDto
 */
export interface JwtDto {
    /**
     * 
     * @type {string}
     * @memberof JwtDto
     */
    token: string;
}
/**
 * 
 * @export
 * @interface JwtResponseDto
 */
export interface JwtResponseDto {
    /**
     * 
     * @type {string}
     * @memberof JwtResponseDto
     */
    jwt: string;
    /**
     * 
     * @type {boolean}
     * @memberof JwtResponseDto
     */
    isValid: boolean;
}
/**
 * 
 * @export
 * @interface Language
 */
export interface Language {
    /**
     * 
     * @type {number}
     * @memberof Language
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Language
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Language
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Language
     */
    deletedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Language
     */
    label?: string;
    /**
     * 
     * @type {string}
     * @memberof Language
     */
    code?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Language
     */
    primary?: boolean;
}
/**
 * 
 * @export
 * @interface LoginCredentialsDto
 */
export interface LoginCredentialsDto {
    /**
     * 
     * @type {string}
     * @memberof LoginCredentialsDto
     */
    userName: string;
    /**
     * 
     * @type {string}
     * @memberof LoginCredentialsDto
     */
    password: string;
}
/**
 * 
 * @export
 * @interface LoginResponseDto
 */
export interface LoginResponseDto {
    /**
     * 
     * @type {string}
     * @memberof LoginResponseDto
     */
    token: string;
    /**
     * 
     * @type {User}
     * @memberof LoginResponseDto
     */
    user: User;
}
/**
 * 
 * @export
 * @interface Mail
 */
export interface Mail {
    /**
     * 
     * @type {number}
     * @memberof Mail
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Mail
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Mail
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Mail
     */
    deletedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Mail
     */
    from?: string;
    /**
     * 
     * @type {string}
     * @memberof Mail
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof Mail
     */
    submissionDate?: string;
}
/**
 * 
 * @export
 * @interface Media
 */
export interface Media {
    /**
     * 
     * @type {number}
     * @memberof Media
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Media
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Media
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Media
     */
    deletedAt?: string;
    /**
     * 
     * @type {Media}
     * @memberof Media
     */
    parent?: Media;
    /**
     * 
     * @type {Array<Media>}
     * @memberof Media
     */
    children?: Array<Media>;
    /**
     * 
     * @type {string}
     * @memberof Media
     */
    name?: string;
    /**
     * 
     * @type {MediaPath}
     * @memberof Media
     */
    path?: MediaPath;
    /**
     * 
     * @type {string}
     * @memberof Media
     */
    filename?: string;
    /**
     * 
     * @type {string}
     * @memberof Media
     */
    ext?: string;
    /**
     * 
     * @type {string}
     * @memberof Media
     */
    mimeType?: string;
    /**
     * 
     * @type {string}
     * @memberof Media
     */
    base64?: string;
    /**
     * 
     * @type {MediaFormats}
     * @memberof Media
     */
    formats?: MediaFormats;
    /**
     * 
     * @type {boolean}
     * @memberof Media
     */
    hidden?: boolean;
    /**
     * 
     * @type {Array<User>}
     * @memberof Media
     */
    users?: Array<User>;
    /**
     * 
     * @type {Array<Document>}
     * @memberof Media
     */
    documents?: Array<Document>;
}
/**
 * 
 * @export
 * @interface MediaFormat
 */
export interface MediaFormat {
    /**
     * 
     * @type {string}
     * @memberof MediaFormat
     */
    url: string;
    /**
     * 
     * @type {number}
     * @memberof MediaFormat
     */
    width: number;
    /**
     * 
     * @type {number}
     * @memberof MediaFormat
     */
    height: number;
    /**
     * 
     * @type {number}
     * @memberof MediaFormat
     */
    size: number;
}
/**
 * 
 * @export
 * @interface MediaFormats
 */
export interface MediaFormats {
    /**
     * 
     * @type {MediaFormat}
     * @memberof MediaFormats
     */
    original: MediaFormat;
    /**
     * 
     * @type {MediaFormat}
     * @memberof MediaFormats
     */
    webp?: MediaFormat;
    /**
     * 
     * @type {MediaFormat}
     * @memberof MediaFormats
     */
    large?: MediaFormat;
    /**
     * 
     * @type {MediaFormat}
     * @memberof MediaFormats
     */
    medium?: MediaFormat;
    /**
     * 
     * @type {MediaFormat}
     * @memberof MediaFormats
     */
    thumbnail?: MediaFormat;
}
/**
 * 
 * @export
 * @interface MediaPath
 */
export interface MediaPath {
    /**
     * 
     * @type {number}
     * @memberof MediaPath
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof MediaPath
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaPath
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaPath
     */
    deletedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaPath
     */
    pathname?: string;
    /**
     * 
     * @type {MediaPathTreeDto}
     * @memberof MediaPath
     */
    parent?: MediaPathTreeDto;
    /**
     * 
     * @type {Array<MediaPathTreeDto>}
     * @memberof MediaPath
     */
    children?: Array<MediaPathTreeDto>;
    /**
     * 
     * @type {Array<Media>}
     * @memberof MediaPath
     */
    media?: Array<Media>;
}
/**
 * 
 * @export
 * @interface MediaPathTreeDto
 */
export interface MediaPathTreeDto {
    /**
     * 
     * @type {number}
     * @memberof MediaPathTreeDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof MediaPathTreeDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaPathTreeDto
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaPathTreeDto
     */
    deletedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaPathTreeDto
     */
    pathname?: string;
    /**
     * 
     * @type {Array<MediaPathTreeDto>}
     * @memberof MediaPathTreeDto
     */
    children?: Array<MediaPathTreeDto>;
}
/**
 * 
 * @export
 * @interface Owner
 */
export interface Owner {
    /**
     * 
     * @type {number}
     * @memberof Owner
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    deletedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    type?: OwnerTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    taxCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    vatNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    notes?: string;
    /**
     * 
     * @type {Array<Artwork>}
     * @memberof Owner
     */
    artworks?: Array<Artwork>;
}

/**
    * @export
    * @enum {string}
    */
export enum OwnerTypeEnum {
    Private = 'private',
    Company = 'company'
}

/**
 * 
 * @export
 * @interface Place
 */
export interface Place {
    /**
     * 
     * @type {number}
     * @memberof Place
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Place
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Place
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Place
     */
    deletedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Place
     */
    name?: string;
    /**
     * 
     * @type {Array<Artwork>}
     * @memberof Place
     */
    artworks?: Array<Artwork>;
    /**
     * 
     * @type {Array<Exhibition>}
     * @memberof Place
     */
    exhibitions?: Array<Exhibition>;
}
/**
 * 
 * @export
 * @interface SimpleContactDto
 */
export interface SimpleContactDto {
    /**
     * 
     * @type {string}
     * @memberof SimpleContactDto
     */
    email: string;
    /**
     * 
     * @type {object}
     * @memberof SimpleContactDto
     */
    formData: object;
}
/**
 * 
 * @export
 * @interface Technique
 */
export interface Technique {
    /**
     * 
     * @type {number}
     * @memberof Technique
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Technique
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Technique
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Technique
     */
    deletedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Technique
     */
    name?: string;
    /**
     * 
     * @type {Array<Artwork>}
     * @memberof Technique
     */
    artworks?: Array<Artwork>;
}
/**
 * 
 * @export
 * @interface Topic
 */
export interface Topic {
    /**
     * 
     * @type {number}
     * @memberof Topic
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Topic
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Topic
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Topic
     */
    deletedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Topic
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Topic
     */
    notes?: string;
    /**
     * 
     * @type {Array<Artwork>}
     * @memberof Topic
     */
    artworks?: Array<Artwork>;
    /**
     * 
     * @type {Array<Document>}
     * @memberof Topic
     */
    documents?: Array<Document>;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    deletedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    userName?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    avatarUrl?: string;
    /**
     * 
     * @type {Media}
     * @memberof User
     */
    avatar?: Media;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    role?: UserRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    password?: string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    provider?: UserProviderEnum;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    lastSocketId?: string;
    /**
     * 
     * @type {Array<Media>}
     * @memberof User
     */
    gallery?: Array<Media>;
}

/**
    * @export
    * @enum {string}
    */
export enum UserRoleEnum {
    Admin = 'admin',
    Guest = 'guest'
}
/**
    * @export
    * @enum {string}
    */
export enum UserProviderEnum {
    Local = 'local',
    Google = 'google',
    Facebook = 'facebook'
}


/**
 * ArtworkApi - axios parameter creator
 * @export
 */
export const ArtworkApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Artworks total
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countArtworks: async (acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/artworks/total/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create multiple Artworks
         * @param {CreateManyArtworkDto} createManyArtworkDto 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManyArtwork: async (createManyArtworkDto: CreateManyArtworkDto, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createManyArtworkDto' is not null or undefined
            if (createManyArtworkDto === null || createManyArtworkDto === undefined) {
                throw new RequiredError('createManyArtworkDto','Required parameter createManyArtworkDto was null or undefined when calling createManyArtwork.');
            }
            const localVarPath = `/artworks/bulk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createManyArtworkDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createManyArtworkDto !== undefined ? createManyArtworkDto : {})
                : (createManyArtworkDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a single Artwork
         * @param {Artwork} artwork 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneArtwork: async (artwork: Artwork, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'artwork' is not null or undefined
            if (artwork === null || artwork === undefined) {
                throw new RequiredError('artwork','Required parameter artwork was null or undefined when calling createOneArtwork.');
            }
            const localVarPath = `/artworks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof artwork !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(artwork !== undefined ? artwork : {})
                : (artwork || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete multiple Artworks
         * @param {Array<string>} requestBody 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyArtworks: async (requestBody: Array<string>, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            if (requestBody === null || requestBody === undefined) {
                throw new RequiredError('requestBody','Required parameter requestBody was null or undefined when calling deleteManyArtworks.');
            }
            const localVarPath = `/artworks/bulk/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof requestBody !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(requestBody !== undefined ? requestBody : {})
                : (requestBody || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a single Artwork
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneArtwork: async (id: number, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteOneArtwork.');
            }
            const localVarPath = `/artworks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple Artworks
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyArtwork: async (acceptLanguage?: string, fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/artworks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single Artwork
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneArtwork: async (id: number, acceptLanguage?: string, fields?: Array<string>, join?: Array<string>, cache?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOneArtwork.');
            }
            const localVarPath = `/artworks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Replace a single Artwork
         * @param {number} id 
         * @param {Artwork} artwork 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceOneArtwork: async (id: number, artwork: Artwork, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling replaceOneArtwork.');
            }
            // verify required parameter 'artwork' is not null or undefined
            if (artwork === null || artwork === undefined) {
                throw new RequiredError('artwork','Required parameter artwork was null or undefined when calling replaceOneArtwork.');
            }
            const localVarPath = `/artworks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof artwork !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(artwork !== undefined ? artwork : {})
                : (artwork || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update multiple Artworks
         * @param {Array<Artwork>} artwork 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateManyArtworks: async (artwork: Array<Artwork>, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'artwork' is not null or undefined
            if (artwork === null || artwork === undefined) {
                throw new RequiredError('artwork','Required parameter artwork was null or undefined when calling updateManyArtworks.');
            }
            const localVarPath = `/artworks/bulk/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof artwork !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(artwork !== undefined ? artwork : {})
                : (artwork || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a single Artwork
         * @param {number} id 
         * @param {Artwork} artwork 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneArtwork: async (id: number, artwork: Artwork, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateOneArtwork.');
            }
            // verify required parameter 'artwork' is not null or undefined
            if (artwork === null || artwork === undefined) {
                throw new RequiredError('artwork','Required parameter artwork was null or undefined when calling updateOneArtwork.');
            }
            const localVarPath = `/artworks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof artwork !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(artwork !== undefined ? artwork : {})
                : (artwork || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ArtworkApi - functional programming interface
 * @export
 */
export const ArtworkApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Artworks total
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countArtworks(acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await ArtworkApiAxiosParamCreator(configuration).countArtworks(acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create multiple Artworks
         * @param {CreateManyArtworkDto} createManyArtworkDto 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createManyArtwork(createManyArtworkDto: CreateManyArtworkDto, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Artwork>>> {
            const localVarAxiosArgs = await ArtworkApiAxiosParamCreator(configuration).createManyArtwork(createManyArtworkDto, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create a single Artwork
         * @param {Artwork} artwork 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOneArtwork(artwork: Artwork, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Artwork>> {
            const localVarAxiosArgs = await ArtworkApiAxiosParamCreator(configuration).createOneArtwork(artwork, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete multiple Artworks
         * @param {Array<string>} requestBody 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteManyArtworks(requestBody: Array<string>, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ArtworkApiAxiosParamCreator(configuration).deleteManyArtworks(requestBody, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete a single Artwork
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOneArtwork(id: number, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Artwork>> {
            const localVarAxiosArgs = await ArtworkApiAxiosParamCreator(configuration).deleteOneArtwork(id, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieve multiple Artworks
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyArtwork(acceptLanguage?: string, fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyArtworkResponseDto>> {
            const localVarAxiosArgs = await ArtworkApiAxiosParamCreator(configuration).getManyArtwork(acceptLanguage, fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieve a single Artwork
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneArtwork(id: number, acceptLanguage?: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Artwork>> {
            const localVarAxiosArgs = await ArtworkApiAxiosParamCreator(configuration).getOneArtwork(id, acceptLanguage, fields, join, cache, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Replace a single Artwork
         * @param {number} id 
         * @param {Artwork} artwork 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceOneArtwork(id: number, artwork: Artwork, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Artwork>> {
            const localVarAxiosArgs = await ArtworkApiAxiosParamCreator(configuration).replaceOneArtwork(id, artwork, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update multiple Artworks
         * @param {Array<Artwork>} artwork 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateManyArtworks(artwork: Array<Artwork>, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Artwork>>> {
            const localVarAxiosArgs = await ArtworkApiAxiosParamCreator(configuration).updateManyArtworks(artwork, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update a single Artwork
         * @param {number} id 
         * @param {Artwork} artwork 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOneArtwork(id: number, artwork: Artwork, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Artwork>> {
            const localVarAxiosArgs = await ArtworkApiAxiosParamCreator(configuration).updateOneArtwork(id, artwork, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ArtworkApi - factory interface
 * @export
 */
export const ArtworkApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get Artworks total
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countArtworks(acceptLanguage?: string, options?: any): AxiosPromise<number> {
            return ArtworkApiFp(configuration).countArtworks(acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create multiple Artworks
         * @param {CreateManyArtworkDto} createManyArtworkDto 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManyArtwork(createManyArtworkDto: CreateManyArtworkDto, acceptLanguage?: string, options?: any): AxiosPromise<Array<Artwork>> {
            return ArtworkApiFp(configuration).createManyArtwork(createManyArtworkDto, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a single Artwork
         * @param {Artwork} artwork 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneArtwork(artwork: Artwork, acceptLanguage?: string, options?: any): AxiosPromise<Artwork> {
            return ArtworkApiFp(configuration).createOneArtwork(artwork, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete multiple Artworks
         * @param {Array<string>} requestBody 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyArtworks(requestBody: Array<string>, acceptLanguage?: string, options?: any): AxiosPromise<void> {
            return ArtworkApiFp(configuration).deleteManyArtworks(requestBody, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a single Artwork
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneArtwork(id: number, acceptLanguage?: string, options?: any): AxiosPromise<Artwork> {
            return ArtworkApiFp(configuration).deleteOneArtwork(id, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple Artworks
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyArtwork(acceptLanguage?: string, fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<GetManyArtworkResponseDto> {
            return ArtworkApiFp(configuration).getManyArtwork(acceptLanguage, fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single Artwork
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneArtwork(id: number, acceptLanguage?: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<Artwork> {
            return ArtworkApiFp(configuration).getOneArtwork(id, acceptLanguage, fields, join, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Replace a single Artwork
         * @param {number} id 
         * @param {Artwork} artwork 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceOneArtwork(id: number, artwork: Artwork, acceptLanguage?: string, options?: any): AxiosPromise<Artwork> {
            return ArtworkApiFp(configuration).replaceOneArtwork(id, artwork, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update multiple Artworks
         * @param {Array<Artwork>} artwork 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateManyArtworks(artwork: Array<Artwork>, acceptLanguage?: string, options?: any): AxiosPromise<Array<Artwork>> {
            return ArtworkApiFp(configuration).updateManyArtworks(artwork, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a single Artwork
         * @param {number} id 
         * @param {Artwork} artwork 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneArtwork(id: number, artwork: Artwork, acceptLanguage?: string, options?: any): AxiosPromise<Artwork> {
            return ArtworkApiFp(configuration).updateOneArtwork(id, artwork, acceptLanguage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for countArtworks operation in ArtworkApi.
 * @export
 * @interface ArtworkApiCountArtworksRequest
 */
export interface ArtworkApiCountArtworksRequest {
    /**
     * Request language
     * @type {string}
     * @memberof ArtworkApiCountArtworks
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for createManyArtwork operation in ArtworkApi.
 * @export
 * @interface ArtworkApiCreateManyArtworkRequest
 */
export interface ArtworkApiCreateManyArtworkRequest {
    /**
     * 
     * @type {CreateManyArtworkDto}
     * @memberof ArtworkApiCreateManyArtwork
     */
    readonly createManyArtworkDto: CreateManyArtworkDto

    /**
     * Request language
     * @type {string}
     * @memberof ArtworkApiCreateManyArtwork
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for createOneArtwork operation in ArtworkApi.
 * @export
 * @interface ArtworkApiCreateOneArtworkRequest
 */
export interface ArtworkApiCreateOneArtworkRequest {
    /**
     * 
     * @type {Artwork}
     * @memberof ArtworkApiCreateOneArtwork
     */
    readonly artwork: Artwork

    /**
     * Request language
     * @type {string}
     * @memberof ArtworkApiCreateOneArtwork
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for deleteManyArtworks operation in ArtworkApi.
 * @export
 * @interface ArtworkApiDeleteManyArtworksRequest
 */
export interface ArtworkApiDeleteManyArtworksRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ArtworkApiDeleteManyArtworks
     */
    readonly requestBody: Array<string>

    /**
     * Request language
     * @type {string}
     * @memberof ArtworkApiDeleteManyArtworks
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for deleteOneArtwork operation in ArtworkApi.
 * @export
 * @interface ArtworkApiDeleteOneArtworkRequest
 */
export interface ArtworkApiDeleteOneArtworkRequest {
    /**
     * 
     * @type {number}
     * @memberof ArtworkApiDeleteOneArtwork
     */
    readonly id: number

    /**
     * Request language
     * @type {string}
     * @memberof ArtworkApiDeleteOneArtwork
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for getManyArtwork operation in ArtworkApi.
 * @export
 * @interface ArtworkApiGetManyArtworkRequest
 */
export interface ArtworkApiGetManyArtworkRequest {
    /**
     * Request language
     * @type {string}
     * @memberof ArtworkApiGetManyArtwork
     */
    readonly acceptLanguage?: string

    /**
     * Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof ArtworkApiGetManyArtwork
     */
    readonly fields?: Array<string>

    /**
     * Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {string}
     * @memberof ArtworkApiGetManyArtwork
     */
    readonly s?: string

    /**
     * Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof ArtworkApiGetManyArtwork
     */
    readonly filter?: Array<string>

    /**
     * Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof ArtworkApiGetManyArtwork
     */
    readonly or?: Array<string>

    /**
     * Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof ArtworkApiGetManyArtwork
     */
    readonly sort?: Array<string>

    /**
     * Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof ArtworkApiGetManyArtwork
     */
    readonly join?: Array<string>

    /**
     * Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof ArtworkApiGetManyArtwork
     */
    readonly limit?: number

    /**
     * Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof ArtworkApiGetManyArtwork
     */
    readonly offset?: number

    /**
     * Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof ArtworkApiGetManyArtwork
     */
    readonly page?: number

    /**
     * Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof ArtworkApiGetManyArtwork
     */
    readonly cache?: number
}

/**
 * Request parameters for getOneArtwork operation in ArtworkApi.
 * @export
 * @interface ArtworkApiGetOneArtworkRequest
 */
export interface ArtworkApiGetOneArtworkRequest {
    /**
     * 
     * @type {number}
     * @memberof ArtworkApiGetOneArtwork
     */
    readonly id: number

    /**
     * Request language
     * @type {string}
     * @memberof ArtworkApiGetOneArtwork
     */
    readonly acceptLanguage?: string

    /**
     * Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof ArtworkApiGetOneArtwork
     */
    readonly fields?: Array<string>

    /**
     * Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof ArtworkApiGetOneArtwork
     */
    readonly join?: Array<string>

    /**
     * Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof ArtworkApiGetOneArtwork
     */
    readonly cache?: number
}

/**
 * Request parameters for replaceOneArtwork operation in ArtworkApi.
 * @export
 * @interface ArtworkApiReplaceOneArtworkRequest
 */
export interface ArtworkApiReplaceOneArtworkRequest {
    /**
     * 
     * @type {number}
     * @memberof ArtworkApiReplaceOneArtwork
     */
    readonly id: number

    /**
     * 
     * @type {Artwork}
     * @memberof ArtworkApiReplaceOneArtwork
     */
    readonly artwork: Artwork

    /**
     * Request language
     * @type {string}
     * @memberof ArtworkApiReplaceOneArtwork
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for updateManyArtworks operation in ArtworkApi.
 * @export
 * @interface ArtworkApiUpdateManyArtworksRequest
 */
export interface ArtworkApiUpdateManyArtworksRequest {
    /**
     * 
     * @type {Array<Artwork>}
     * @memberof ArtworkApiUpdateManyArtworks
     */
    readonly artwork: Array<Artwork>

    /**
     * Request language
     * @type {string}
     * @memberof ArtworkApiUpdateManyArtworks
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for updateOneArtwork operation in ArtworkApi.
 * @export
 * @interface ArtworkApiUpdateOneArtworkRequest
 */
export interface ArtworkApiUpdateOneArtworkRequest {
    /**
     * 
     * @type {number}
     * @memberof ArtworkApiUpdateOneArtwork
     */
    readonly id: number

    /**
     * 
     * @type {Artwork}
     * @memberof ArtworkApiUpdateOneArtwork
     */
    readonly artwork: Artwork

    /**
     * Request language
     * @type {string}
     * @memberof ArtworkApiUpdateOneArtwork
     */
    readonly acceptLanguage?: string
}

/**
 * ArtworkApi - object-oriented interface
 * @export
 * @class ArtworkApi
 * @extends {BaseAPI}
 */
export class ArtworkApi extends BaseAPI {
    /**
     * 
     * @summary Get Artworks total
     * @param {ArtworkApiCountArtworksRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtworkApi
     */
    public countArtworks(requestParameters: ArtworkApiCountArtworksRequest = {}, options?: any) {
        return ArtworkApiFp(this.configuration).countArtworks(requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create multiple Artworks
     * @param {ArtworkApiCreateManyArtworkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtworkApi
     */
    public createManyArtwork(requestParameters: ArtworkApiCreateManyArtworkRequest, options?: any) {
        return ArtworkApiFp(this.configuration).createManyArtwork(requestParameters.createManyArtworkDto, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a single Artwork
     * @param {ArtworkApiCreateOneArtworkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtworkApi
     */
    public createOneArtwork(requestParameters: ArtworkApiCreateOneArtworkRequest, options?: any) {
        return ArtworkApiFp(this.configuration).createOneArtwork(requestParameters.artwork, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete multiple Artworks
     * @param {ArtworkApiDeleteManyArtworksRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtworkApi
     */
    public deleteManyArtworks(requestParameters: ArtworkApiDeleteManyArtworksRequest, options?: any) {
        return ArtworkApiFp(this.configuration).deleteManyArtworks(requestParameters.requestBody, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a single Artwork
     * @param {ArtworkApiDeleteOneArtworkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtworkApi
     */
    public deleteOneArtwork(requestParameters: ArtworkApiDeleteOneArtworkRequest, options?: any) {
        return ArtworkApiFp(this.configuration).deleteOneArtwork(requestParameters.id, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple Artworks
     * @param {ArtworkApiGetManyArtworkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtworkApi
     */
    public getManyArtwork(requestParameters: ArtworkApiGetManyArtworkRequest = {}, options?: any) {
        return ArtworkApiFp(this.configuration).getManyArtwork(requestParameters.acceptLanguage, requestParameters.fields, requestParameters.s, requestParameters.filter, requestParameters.or, requestParameters.sort, requestParameters.join, requestParameters.limit, requestParameters.offset, requestParameters.page, requestParameters.cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single Artwork
     * @param {ArtworkApiGetOneArtworkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtworkApi
     */
    public getOneArtwork(requestParameters: ArtworkApiGetOneArtworkRequest, options?: any) {
        return ArtworkApiFp(this.configuration).getOneArtwork(requestParameters.id, requestParameters.acceptLanguage, requestParameters.fields, requestParameters.join, requestParameters.cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Replace a single Artwork
     * @param {ArtworkApiReplaceOneArtworkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtworkApi
     */
    public replaceOneArtwork(requestParameters: ArtworkApiReplaceOneArtworkRequest, options?: any) {
        return ArtworkApiFp(this.configuration).replaceOneArtwork(requestParameters.id, requestParameters.artwork, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update multiple Artworks
     * @param {ArtworkApiUpdateManyArtworksRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtworkApi
     */
    public updateManyArtworks(requestParameters: ArtworkApiUpdateManyArtworksRequest, options?: any) {
        return ArtworkApiFp(this.configuration).updateManyArtworks(requestParameters.artwork, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a single Artwork
     * @param {ArtworkApiUpdateOneArtworkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtworkApi
     */
    public updateOneArtwork(requestParameters: ArtworkApiUpdateOneArtworkRequest, options?: any) {
        return ArtworkApiFp(this.configuration).updateOneArtwork(requestParameters.id, requestParameters.artwork, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get jwt token route.
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jwt: async (acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/jwt`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Login route.
         * @param {LoginCredentialsDto} loginCredentialsDto 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (loginCredentialsDto: LoginCredentialsDto, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginCredentialsDto' is not null or undefined
            if (loginCredentialsDto === null || loginCredentialsDto === undefined) {
                throw new RequiredError('loginCredentialsDto','Required parameter loginCredentialsDto was null or undefined when calling login.');
            }
            const localVarPath = `/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof loginCredentialsDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(loginCredentialsDto !== undefined ? loginCredentialsDto : {})
                : (loginCredentialsDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Verify token route.
         * @param {JwtDto} jwtDto 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyJwt: async (jwtDto: JwtDto, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'jwtDto' is not null or undefined
            if (jwtDto === null || jwtDto === undefined) {
                throw new RequiredError('jwtDto','Required parameter jwtDto was null or undefined when calling verifyJwt.');
            }
            const localVarPath = `/verify-jwt`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof jwtDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(jwtDto !== undefined ? jwtDto : {})
                : (jwtDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get jwt token route.
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async jwt(acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JwtResponseDto>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).jwt(acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Login route.
         * @param {LoginCredentialsDto} loginCredentialsDto 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(loginCredentialsDto: LoginCredentialsDto, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponseDto>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).login(loginCredentialsDto, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Verify token route.
         * @param {JwtDto} jwtDto 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyJwt(jwtDto: JwtDto, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JwtResponseDto>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).verifyJwt(jwtDto, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get jwt token route.
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jwt(acceptLanguage?: string, options?: any): AxiosPromise<JwtResponseDto> {
            return AuthApiFp(configuration).jwt(acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Login route.
         * @param {LoginCredentialsDto} loginCredentialsDto 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(loginCredentialsDto: LoginCredentialsDto, acceptLanguage?: string, options?: any): AxiosPromise<LoginResponseDto> {
            return AuthApiFp(configuration).login(loginCredentialsDto, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Verify token route.
         * @param {JwtDto} jwtDto 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyJwt(jwtDto: JwtDto, acceptLanguage?: string, options?: any): AxiosPromise<JwtResponseDto> {
            return AuthApiFp(configuration).verifyJwt(jwtDto, acceptLanguage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for jwt operation in AuthApi.
 * @export
 * @interface AuthApiJwtRequest
 */
export interface AuthApiJwtRequest {
    /**
     * Request language
     * @type {string}
     * @memberof AuthApiJwt
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for login operation in AuthApi.
 * @export
 * @interface AuthApiLoginRequest
 */
export interface AuthApiLoginRequest {
    /**
     * 
     * @type {LoginCredentialsDto}
     * @memberof AuthApiLogin
     */
    readonly loginCredentialsDto: LoginCredentialsDto

    /**
     * Request language
     * @type {string}
     * @memberof AuthApiLogin
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for verifyJwt operation in AuthApi.
 * @export
 * @interface AuthApiVerifyJwtRequest
 */
export interface AuthApiVerifyJwtRequest {
    /**
     * 
     * @type {JwtDto}
     * @memberof AuthApiVerifyJwt
     */
    readonly jwtDto: JwtDto

    /**
     * Request language
     * @type {string}
     * @memberof AuthApiVerifyJwt
     */
    readonly acceptLanguage?: string
}

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @summary Get jwt token route.
     * @param {AuthApiJwtRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public jwt(requestParameters: AuthApiJwtRequest = {}, options?: any) {
        return AuthApiFp(this.configuration).jwt(requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Login route.
     * @param {AuthApiLoginRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public login(requestParameters: AuthApiLoginRequest, options?: any) {
        return AuthApiFp(this.configuration).login(requestParameters.loginCredentialsDto, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Verify token route.
     * @param {AuthApiVerifyJwtRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public verifyJwt(requestParameters: AuthApiVerifyJwtRequest, options?: any) {
        return AuthApiFp(this.configuration).verifyJwt(requestParameters.jwtDto, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DocumentApi - axios parameter creator
 * @export
 */
export const DocumentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Documents total
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countDocuments: async (acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/documents/total/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create multiple Documents
         * @param {CreateManyDocumentDto} createManyDocumentDto 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManyDocument: async (createManyDocumentDto: CreateManyDocumentDto, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createManyDocumentDto' is not null or undefined
            if (createManyDocumentDto === null || createManyDocumentDto === undefined) {
                throw new RequiredError('createManyDocumentDto','Required parameter createManyDocumentDto was null or undefined when calling createManyDocument.');
            }
            const localVarPath = `/documents/bulk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createManyDocumentDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createManyDocumentDto !== undefined ? createManyDocumentDto : {})
                : (createManyDocumentDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a single Document
         * @param {Document} document 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneDocument: async (document: Document, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'document' is not null or undefined
            if (document === null || document === undefined) {
                throw new RequiredError('document','Required parameter document was null or undefined when calling createOneDocument.');
            }
            const localVarPath = `/documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof document !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(document !== undefined ? document : {})
                : (document || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete multiple Documents
         * @param {Array<string>} requestBody 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyDocuments: async (requestBody: Array<string>, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            if (requestBody === null || requestBody === undefined) {
                throw new RequiredError('requestBody','Required parameter requestBody was null or undefined when calling deleteManyDocuments.');
            }
            const localVarPath = `/documents/bulk/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof requestBody !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(requestBody !== undefined ? requestBody : {})
                : (requestBody || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a single Document
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneDocument: async (id: number, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteOneDocument.');
            }
            const localVarPath = `/documents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple Documents
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyDocument: async (acceptLanguage?: string, fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single Document
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneDocument: async (id: number, acceptLanguage?: string, fields?: Array<string>, join?: Array<string>, cache?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOneDocument.');
            }
            const localVarPath = `/documents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Replace a single Document
         * @param {number} id 
         * @param {Document} document 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceOneDocument: async (id: number, document: Document, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling replaceOneDocument.');
            }
            // verify required parameter 'document' is not null or undefined
            if (document === null || document === undefined) {
                throw new RequiredError('document','Required parameter document was null or undefined when calling replaceOneDocument.');
            }
            const localVarPath = `/documents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof document !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(document !== undefined ? document : {})
                : (document || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update multiple Documents
         * @param {Array<Document>} document 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateManyDocuments: async (document: Array<Document>, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'document' is not null or undefined
            if (document === null || document === undefined) {
                throw new RequiredError('document','Required parameter document was null or undefined when calling updateManyDocuments.');
            }
            const localVarPath = `/documents/bulk/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof document !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(document !== undefined ? document : {})
                : (document || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a single Document
         * @param {number} id 
         * @param {Document} document 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneDocument: async (id: number, document: Document, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateOneDocument.');
            }
            // verify required parameter 'document' is not null or undefined
            if (document === null || document === undefined) {
                throw new RequiredError('document','Required parameter document was null or undefined when calling updateOneDocument.');
            }
            const localVarPath = `/documents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof document !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(document !== undefined ? document : {})
                : (document || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentApi - functional programming interface
 * @export
 */
export const DocumentApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Documents total
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countDocuments(acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await DocumentApiAxiosParamCreator(configuration).countDocuments(acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create multiple Documents
         * @param {CreateManyDocumentDto} createManyDocumentDto 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createManyDocument(createManyDocumentDto: CreateManyDocumentDto, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Document>>> {
            const localVarAxiosArgs = await DocumentApiAxiosParamCreator(configuration).createManyDocument(createManyDocumentDto, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create a single Document
         * @param {Document} document 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOneDocument(document: Document, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Document>> {
            const localVarAxiosArgs = await DocumentApiAxiosParamCreator(configuration).createOneDocument(document, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete multiple Documents
         * @param {Array<string>} requestBody 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteManyDocuments(requestBody: Array<string>, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DocumentApiAxiosParamCreator(configuration).deleteManyDocuments(requestBody, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete a single Document
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOneDocument(id: number, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Document>> {
            const localVarAxiosArgs = await DocumentApiAxiosParamCreator(configuration).deleteOneDocument(id, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieve multiple Documents
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyDocument(acceptLanguage?: string, fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyDocumentResponseDto>> {
            const localVarAxiosArgs = await DocumentApiAxiosParamCreator(configuration).getManyDocument(acceptLanguage, fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieve a single Document
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneDocument(id: number, acceptLanguage?: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Document>> {
            const localVarAxiosArgs = await DocumentApiAxiosParamCreator(configuration).getOneDocument(id, acceptLanguage, fields, join, cache, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Replace a single Document
         * @param {number} id 
         * @param {Document} document 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceOneDocument(id: number, document: Document, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Document>> {
            const localVarAxiosArgs = await DocumentApiAxiosParamCreator(configuration).replaceOneDocument(id, document, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update multiple Documents
         * @param {Array<Document>} document 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateManyDocuments(document: Array<Document>, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Document>>> {
            const localVarAxiosArgs = await DocumentApiAxiosParamCreator(configuration).updateManyDocuments(document, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update a single Document
         * @param {number} id 
         * @param {Document} document 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOneDocument(id: number, document: Document, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Document>> {
            const localVarAxiosArgs = await DocumentApiAxiosParamCreator(configuration).updateOneDocument(id, document, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DocumentApi - factory interface
 * @export
 */
export const DocumentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get Documents total
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countDocuments(acceptLanguage?: string, options?: any): AxiosPromise<number> {
            return DocumentApiFp(configuration).countDocuments(acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create multiple Documents
         * @param {CreateManyDocumentDto} createManyDocumentDto 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManyDocument(createManyDocumentDto: CreateManyDocumentDto, acceptLanguage?: string, options?: any): AxiosPromise<Array<Document>> {
            return DocumentApiFp(configuration).createManyDocument(createManyDocumentDto, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a single Document
         * @param {Document} document 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneDocument(document: Document, acceptLanguage?: string, options?: any): AxiosPromise<Document> {
            return DocumentApiFp(configuration).createOneDocument(document, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete multiple Documents
         * @param {Array<string>} requestBody 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyDocuments(requestBody: Array<string>, acceptLanguage?: string, options?: any): AxiosPromise<void> {
            return DocumentApiFp(configuration).deleteManyDocuments(requestBody, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a single Document
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneDocument(id: number, acceptLanguage?: string, options?: any): AxiosPromise<Document> {
            return DocumentApiFp(configuration).deleteOneDocument(id, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple Documents
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyDocument(acceptLanguage?: string, fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<GetManyDocumentResponseDto> {
            return DocumentApiFp(configuration).getManyDocument(acceptLanguage, fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single Document
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneDocument(id: number, acceptLanguage?: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<Document> {
            return DocumentApiFp(configuration).getOneDocument(id, acceptLanguage, fields, join, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Replace a single Document
         * @param {number} id 
         * @param {Document} document 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceOneDocument(id: number, document: Document, acceptLanguage?: string, options?: any): AxiosPromise<Document> {
            return DocumentApiFp(configuration).replaceOneDocument(id, document, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update multiple Documents
         * @param {Array<Document>} document 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateManyDocuments(document: Array<Document>, acceptLanguage?: string, options?: any): AxiosPromise<Array<Document>> {
            return DocumentApiFp(configuration).updateManyDocuments(document, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a single Document
         * @param {number} id 
         * @param {Document} document 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneDocument(id: number, document: Document, acceptLanguage?: string, options?: any): AxiosPromise<Document> {
            return DocumentApiFp(configuration).updateOneDocument(id, document, acceptLanguage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for countDocuments operation in DocumentApi.
 * @export
 * @interface DocumentApiCountDocumentsRequest
 */
export interface DocumentApiCountDocumentsRequest {
    /**
     * Request language
     * @type {string}
     * @memberof DocumentApiCountDocuments
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for createManyDocument operation in DocumentApi.
 * @export
 * @interface DocumentApiCreateManyDocumentRequest
 */
export interface DocumentApiCreateManyDocumentRequest {
    /**
     * 
     * @type {CreateManyDocumentDto}
     * @memberof DocumentApiCreateManyDocument
     */
    readonly createManyDocumentDto: CreateManyDocumentDto

    /**
     * Request language
     * @type {string}
     * @memberof DocumentApiCreateManyDocument
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for createOneDocument operation in DocumentApi.
 * @export
 * @interface DocumentApiCreateOneDocumentRequest
 */
export interface DocumentApiCreateOneDocumentRequest {
    /**
     * 
     * @type {Document}
     * @memberof DocumentApiCreateOneDocument
     */
    readonly document: Document

    /**
     * Request language
     * @type {string}
     * @memberof DocumentApiCreateOneDocument
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for deleteManyDocuments operation in DocumentApi.
 * @export
 * @interface DocumentApiDeleteManyDocumentsRequest
 */
export interface DocumentApiDeleteManyDocumentsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof DocumentApiDeleteManyDocuments
     */
    readonly requestBody: Array<string>

    /**
     * Request language
     * @type {string}
     * @memberof DocumentApiDeleteManyDocuments
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for deleteOneDocument operation in DocumentApi.
 * @export
 * @interface DocumentApiDeleteOneDocumentRequest
 */
export interface DocumentApiDeleteOneDocumentRequest {
    /**
     * 
     * @type {number}
     * @memberof DocumentApiDeleteOneDocument
     */
    readonly id: number

    /**
     * Request language
     * @type {string}
     * @memberof DocumentApiDeleteOneDocument
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for getManyDocument operation in DocumentApi.
 * @export
 * @interface DocumentApiGetManyDocumentRequest
 */
export interface DocumentApiGetManyDocumentRequest {
    /**
     * Request language
     * @type {string}
     * @memberof DocumentApiGetManyDocument
     */
    readonly acceptLanguage?: string

    /**
     * Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof DocumentApiGetManyDocument
     */
    readonly fields?: Array<string>

    /**
     * Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {string}
     * @memberof DocumentApiGetManyDocument
     */
    readonly s?: string

    /**
     * Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof DocumentApiGetManyDocument
     */
    readonly filter?: Array<string>

    /**
     * Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof DocumentApiGetManyDocument
     */
    readonly or?: Array<string>

    /**
     * Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof DocumentApiGetManyDocument
     */
    readonly sort?: Array<string>

    /**
     * Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof DocumentApiGetManyDocument
     */
    readonly join?: Array<string>

    /**
     * Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof DocumentApiGetManyDocument
     */
    readonly limit?: number

    /**
     * Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof DocumentApiGetManyDocument
     */
    readonly offset?: number

    /**
     * Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof DocumentApiGetManyDocument
     */
    readonly page?: number

    /**
     * Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof DocumentApiGetManyDocument
     */
    readonly cache?: number
}

/**
 * Request parameters for getOneDocument operation in DocumentApi.
 * @export
 * @interface DocumentApiGetOneDocumentRequest
 */
export interface DocumentApiGetOneDocumentRequest {
    /**
     * 
     * @type {number}
     * @memberof DocumentApiGetOneDocument
     */
    readonly id: number

    /**
     * Request language
     * @type {string}
     * @memberof DocumentApiGetOneDocument
     */
    readonly acceptLanguage?: string

    /**
     * Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof DocumentApiGetOneDocument
     */
    readonly fields?: Array<string>

    /**
     * Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof DocumentApiGetOneDocument
     */
    readonly join?: Array<string>

    /**
     * Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof DocumentApiGetOneDocument
     */
    readonly cache?: number
}

/**
 * Request parameters for replaceOneDocument operation in DocumentApi.
 * @export
 * @interface DocumentApiReplaceOneDocumentRequest
 */
export interface DocumentApiReplaceOneDocumentRequest {
    /**
     * 
     * @type {number}
     * @memberof DocumentApiReplaceOneDocument
     */
    readonly id: number

    /**
     * 
     * @type {Document}
     * @memberof DocumentApiReplaceOneDocument
     */
    readonly document: Document

    /**
     * Request language
     * @type {string}
     * @memberof DocumentApiReplaceOneDocument
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for updateManyDocuments operation in DocumentApi.
 * @export
 * @interface DocumentApiUpdateManyDocumentsRequest
 */
export interface DocumentApiUpdateManyDocumentsRequest {
    /**
     * 
     * @type {Array<Document>}
     * @memberof DocumentApiUpdateManyDocuments
     */
    readonly document: Array<Document>

    /**
     * Request language
     * @type {string}
     * @memberof DocumentApiUpdateManyDocuments
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for updateOneDocument operation in DocumentApi.
 * @export
 * @interface DocumentApiUpdateOneDocumentRequest
 */
export interface DocumentApiUpdateOneDocumentRequest {
    /**
     * 
     * @type {number}
     * @memberof DocumentApiUpdateOneDocument
     */
    readonly id: number

    /**
     * 
     * @type {Document}
     * @memberof DocumentApiUpdateOneDocument
     */
    readonly document: Document

    /**
     * Request language
     * @type {string}
     * @memberof DocumentApiUpdateOneDocument
     */
    readonly acceptLanguage?: string
}

/**
 * DocumentApi - object-oriented interface
 * @export
 * @class DocumentApi
 * @extends {BaseAPI}
 */
export class DocumentApi extends BaseAPI {
    /**
     * 
     * @summary Get Documents total
     * @param {DocumentApiCountDocumentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public countDocuments(requestParameters: DocumentApiCountDocumentsRequest = {}, options?: any) {
        return DocumentApiFp(this.configuration).countDocuments(requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create multiple Documents
     * @param {DocumentApiCreateManyDocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public createManyDocument(requestParameters: DocumentApiCreateManyDocumentRequest, options?: any) {
        return DocumentApiFp(this.configuration).createManyDocument(requestParameters.createManyDocumentDto, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a single Document
     * @param {DocumentApiCreateOneDocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public createOneDocument(requestParameters: DocumentApiCreateOneDocumentRequest, options?: any) {
        return DocumentApiFp(this.configuration).createOneDocument(requestParameters.document, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete multiple Documents
     * @param {DocumentApiDeleteManyDocumentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public deleteManyDocuments(requestParameters: DocumentApiDeleteManyDocumentsRequest, options?: any) {
        return DocumentApiFp(this.configuration).deleteManyDocuments(requestParameters.requestBody, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a single Document
     * @param {DocumentApiDeleteOneDocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public deleteOneDocument(requestParameters: DocumentApiDeleteOneDocumentRequest, options?: any) {
        return DocumentApiFp(this.configuration).deleteOneDocument(requestParameters.id, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple Documents
     * @param {DocumentApiGetManyDocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public getManyDocument(requestParameters: DocumentApiGetManyDocumentRequest = {}, options?: any) {
        return DocumentApiFp(this.configuration).getManyDocument(requestParameters.acceptLanguage, requestParameters.fields, requestParameters.s, requestParameters.filter, requestParameters.or, requestParameters.sort, requestParameters.join, requestParameters.limit, requestParameters.offset, requestParameters.page, requestParameters.cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single Document
     * @param {DocumentApiGetOneDocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public getOneDocument(requestParameters: DocumentApiGetOneDocumentRequest, options?: any) {
        return DocumentApiFp(this.configuration).getOneDocument(requestParameters.id, requestParameters.acceptLanguage, requestParameters.fields, requestParameters.join, requestParameters.cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Replace a single Document
     * @param {DocumentApiReplaceOneDocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public replaceOneDocument(requestParameters: DocumentApiReplaceOneDocumentRequest, options?: any) {
        return DocumentApiFp(this.configuration).replaceOneDocument(requestParameters.id, requestParameters.document, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update multiple Documents
     * @param {DocumentApiUpdateManyDocumentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public updateManyDocuments(requestParameters: DocumentApiUpdateManyDocumentsRequest, options?: any) {
        return DocumentApiFp(this.configuration).updateManyDocuments(requestParameters.document, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a single Document
     * @param {DocumentApiUpdateOneDocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public updateOneDocument(requestParameters: DocumentApiUpdateOneDocumentRequest, options?: any) {
        return DocumentApiFp(this.configuration).updateOneDocument(requestParameters.id, requestParameters.document, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ExhibitionApi - axios parameter creator
 * @export
 */
export const ExhibitionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Exhibitions total
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countExhibitions: async (acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/exhibitions/total/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create multiple Exhibitions
         * @param {CreateManyExhibitionDto} createManyExhibitionDto 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManyExhibition: async (createManyExhibitionDto: CreateManyExhibitionDto, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createManyExhibitionDto' is not null or undefined
            if (createManyExhibitionDto === null || createManyExhibitionDto === undefined) {
                throw new RequiredError('createManyExhibitionDto','Required parameter createManyExhibitionDto was null or undefined when calling createManyExhibition.');
            }
            const localVarPath = `/exhibitions/bulk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createManyExhibitionDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createManyExhibitionDto !== undefined ? createManyExhibitionDto : {})
                : (createManyExhibitionDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a single Exhibition
         * @param {Exhibition} exhibition 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneExhibition: async (exhibition: Exhibition, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'exhibition' is not null or undefined
            if (exhibition === null || exhibition === undefined) {
                throw new RequiredError('exhibition','Required parameter exhibition was null or undefined when calling createOneExhibition.');
            }
            const localVarPath = `/exhibitions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof exhibition !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(exhibition !== undefined ? exhibition : {})
                : (exhibition || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete multiple Exhibitions
         * @param {Array<string>} requestBody 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyExhibitions: async (requestBody: Array<string>, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            if (requestBody === null || requestBody === undefined) {
                throw new RequiredError('requestBody','Required parameter requestBody was null or undefined when calling deleteManyExhibitions.');
            }
            const localVarPath = `/exhibitions/bulk/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof requestBody !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(requestBody !== undefined ? requestBody : {})
                : (requestBody || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a single Exhibition
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneExhibition: async (id: number, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteOneExhibition.');
            }
            const localVarPath = `/exhibitions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple Exhibitions
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyExhibition: async (acceptLanguage?: string, fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/exhibitions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single Exhibition
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneExhibition: async (id: number, acceptLanguage?: string, fields?: Array<string>, join?: Array<string>, cache?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOneExhibition.');
            }
            const localVarPath = `/exhibitions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Replace a single Exhibition
         * @param {number} id 
         * @param {Exhibition} exhibition 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceOneExhibition: async (id: number, exhibition: Exhibition, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling replaceOneExhibition.');
            }
            // verify required parameter 'exhibition' is not null or undefined
            if (exhibition === null || exhibition === undefined) {
                throw new RequiredError('exhibition','Required parameter exhibition was null or undefined when calling replaceOneExhibition.');
            }
            const localVarPath = `/exhibitions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof exhibition !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(exhibition !== undefined ? exhibition : {})
                : (exhibition || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update multiple Exhibitions
         * @param {Array<Exhibition>} exhibition 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateManyExhibitions: async (exhibition: Array<Exhibition>, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'exhibition' is not null or undefined
            if (exhibition === null || exhibition === undefined) {
                throw new RequiredError('exhibition','Required parameter exhibition was null or undefined when calling updateManyExhibitions.');
            }
            const localVarPath = `/exhibitions/bulk/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof exhibition !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(exhibition !== undefined ? exhibition : {})
                : (exhibition || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a single Exhibition
         * @param {number} id 
         * @param {Exhibition} exhibition 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneExhibition: async (id: number, exhibition: Exhibition, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateOneExhibition.');
            }
            // verify required parameter 'exhibition' is not null or undefined
            if (exhibition === null || exhibition === undefined) {
                throw new RequiredError('exhibition','Required parameter exhibition was null or undefined when calling updateOneExhibition.');
            }
            const localVarPath = `/exhibitions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof exhibition !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(exhibition !== undefined ? exhibition : {})
                : (exhibition || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExhibitionApi - functional programming interface
 * @export
 */
export const ExhibitionApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Exhibitions total
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countExhibitions(acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await ExhibitionApiAxiosParamCreator(configuration).countExhibitions(acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create multiple Exhibitions
         * @param {CreateManyExhibitionDto} createManyExhibitionDto 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createManyExhibition(createManyExhibitionDto: CreateManyExhibitionDto, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Exhibition>>> {
            const localVarAxiosArgs = await ExhibitionApiAxiosParamCreator(configuration).createManyExhibition(createManyExhibitionDto, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create a single Exhibition
         * @param {Exhibition} exhibition 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOneExhibition(exhibition: Exhibition, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Exhibition>> {
            const localVarAxiosArgs = await ExhibitionApiAxiosParamCreator(configuration).createOneExhibition(exhibition, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete multiple Exhibitions
         * @param {Array<string>} requestBody 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteManyExhibitions(requestBody: Array<string>, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ExhibitionApiAxiosParamCreator(configuration).deleteManyExhibitions(requestBody, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete a single Exhibition
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOneExhibition(id: number, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Exhibition>> {
            const localVarAxiosArgs = await ExhibitionApiAxiosParamCreator(configuration).deleteOneExhibition(id, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieve multiple Exhibitions
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyExhibition(acceptLanguage?: string, fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyExhibitionResponseDto>> {
            const localVarAxiosArgs = await ExhibitionApiAxiosParamCreator(configuration).getManyExhibition(acceptLanguage, fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieve a single Exhibition
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneExhibition(id: number, acceptLanguage?: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Exhibition>> {
            const localVarAxiosArgs = await ExhibitionApiAxiosParamCreator(configuration).getOneExhibition(id, acceptLanguage, fields, join, cache, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Replace a single Exhibition
         * @param {number} id 
         * @param {Exhibition} exhibition 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceOneExhibition(id: number, exhibition: Exhibition, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Exhibition>> {
            const localVarAxiosArgs = await ExhibitionApiAxiosParamCreator(configuration).replaceOneExhibition(id, exhibition, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update multiple Exhibitions
         * @param {Array<Exhibition>} exhibition 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateManyExhibitions(exhibition: Array<Exhibition>, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Exhibition>>> {
            const localVarAxiosArgs = await ExhibitionApiAxiosParamCreator(configuration).updateManyExhibitions(exhibition, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update a single Exhibition
         * @param {number} id 
         * @param {Exhibition} exhibition 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOneExhibition(id: number, exhibition: Exhibition, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Exhibition>> {
            const localVarAxiosArgs = await ExhibitionApiAxiosParamCreator(configuration).updateOneExhibition(id, exhibition, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ExhibitionApi - factory interface
 * @export
 */
export const ExhibitionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get Exhibitions total
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countExhibitions(acceptLanguage?: string, options?: any): AxiosPromise<number> {
            return ExhibitionApiFp(configuration).countExhibitions(acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create multiple Exhibitions
         * @param {CreateManyExhibitionDto} createManyExhibitionDto 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManyExhibition(createManyExhibitionDto: CreateManyExhibitionDto, acceptLanguage?: string, options?: any): AxiosPromise<Array<Exhibition>> {
            return ExhibitionApiFp(configuration).createManyExhibition(createManyExhibitionDto, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a single Exhibition
         * @param {Exhibition} exhibition 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneExhibition(exhibition: Exhibition, acceptLanguage?: string, options?: any): AxiosPromise<Exhibition> {
            return ExhibitionApiFp(configuration).createOneExhibition(exhibition, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete multiple Exhibitions
         * @param {Array<string>} requestBody 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyExhibitions(requestBody: Array<string>, acceptLanguage?: string, options?: any): AxiosPromise<void> {
            return ExhibitionApiFp(configuration).deleteManyExhibitions(requestBody, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a single Exhibition
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneExhibition(id: number, acceptLanguage?: string, options?: any): AxiosPromise<Exhibition> {
            return ExhibitionApiFp(configuration).deleteOneExhibition(id, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple Exhibitions
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyExhibition(acceptLanguage?: string, fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<GetManyExhibitionResponseDto> {
            return ExhibitionApiFp(configuration).getManyExhibition(acceptLanguage, fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single Exhibition
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneExhibition(id: number, acceptLanguage?: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<Exhibition> {
            return ExhibitionApiFp(configuration).getOneExhibition(id, acceptLanguage, fields, join, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Replace a single Exhibition
         * @param {number} id 
         * @param {Exhibition} exhibition 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceOneExhibition(id: number, exhibition: Exhibition, acceptLanguage?: string, options?: any): AxiosPromise<Exhibition> {
            return ExhibitionApiFp(configuration).replaceOneExhibition(id, exhibition, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update multiple Exhibitions
         * @param {Array<Exhibition>} exhibition 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateManyExhibitions(exhibition: Array<Exhibition>, acceptLanguage?: string, options?: any): AxiosPromise<Array<Exhibition>> {
            return ExhibitionApiFp(configuration).updateManyExhibitions(exhibition, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a single Exhibition
         * @param {number} id 
         * @param {Exhibition} exhibition 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneExhibition(id: number, exhibition: Exhibition, acceptLanguage?: string, options?: any): AxiosPromise<Exhibition> {
            return ExhibitionApiFp(configuration).updateOneExhibition(id, exhibition, acceptLanguage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for countExhibitions operation in ExhibitionApi.
 * @export
 * @interface ExhibitionApiCountExhibitionsRequest
 */
export interface ExhibitionApiCountExhibitionsRequest {
    /**
     * Request language
     * @type {string}
     * @memberof ExhibitionApiCountExhibitions
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for createManyExhibition operation in ExhibitionApi.
 * @export
 * @interface ExhibitionApiCreateManyExhibitionRequest
 */
export interface ExhibitionApiCreateManyExhibitionRequest {
    /**
     * 
     * @type {CreateManyExhibitionDto}
     * @memberof ExhibitionApiCreateManyExhibition
     */
    readonly createManyExhibitionDto: CreateManyExhibitionDto

    /**
     * Request language
     * @type {string}
     * @memberof ExhibitionApiCreateManyExhibition
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for createOneExhibition operation in ExhibitionApi.
 * @export
 * @interface ExhibitionApiCreateOneExhibitionRequest
 */
export interface ExhibitionApiCreateOneExhibitionRequest {
    /**
     * 
     * @type {Exhibition}
     * @memberof ExhibitionApiCreateOneExhibition
     */
    readonly exhibition: Exhibition

    /**
     * Request language
     * @type {string}
     * @memberof ExhibitionApiCreateOneExhibition
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for deleteManyExhibitions operation in ExhibitionApi.
 * @export
 * @interface ExhibitionApiDeleteManyExhibitionsRequest
 */
export interface ExhibitionApiDeleteManyExhibitionsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ExhibitionApiDeleteManyExhibitions
     */
    readonly requestBody: Array<string>

    /**
     * Request language
     * @type {string}
     * @memberof ExhibitionApiDeleteManyExhibitions
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for deleteOneExhibition operation in ExhibitionApi.
 * @export
 * @interface ExhibitionApiDeleteOneExhibitionRequest
 */
export interface ExhibitionApiDeleteOneExhibitionRequest {
    /**
     * 
     * @type {number}
     * @memberof ExhibitionApiDeleteOneExhibition
     */
    readonly id: number

    /**
     * Request language
     * @type {string}
     * @memberof ExhibitionApiDeleteOneExhibition
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for getManyExhibition operation in ExhibitionApi.
 * @export
 * @interface ExhibitionApiGetManyExhibitionRequest
 */
export interface ExhibitionApiGetManyExhibitionRequest {
    /**
     * Request language
     * @type {string}
     * @memberof ExhibitionApiGetManyExhibition
     */
    readonly acceptLanguage?: string

    /**
     * Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof ExhibitionApiGetManyExhibition
     */
    readonly fields?: Array<string>

    /**
     * Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {string}
     * @memberof ExhibitionApiGetManyExhibition
     */
    readonly s?: string

    /**
     * Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof ExhibitionApiGetManyExhibition
     */
    readonly filter?: Array<string>

    /**
     * Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof ExhibitionApiGetManyExhibition
     */
    readonly or?: Array<string>

    /**
     * Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof ExhibitionApiGetManyExhibition
     */
    readonly sort?: Array<string>

    /**
     * Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof ExhibitionApiGetManyExhibition
     */
    readonly join?: Array<string>

    /**
     * Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof ExhibitionApiGetManyExhibition
     */
    readonly limit?: number

    /**
     * Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof ExhibitionApiGetManyExhibition
     */
    readonly offset?: number

    /**
     * Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof ExhibitionApiGetManyExhibition
     */
    readonly page?: number

    /**
     * Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof ExhibitionApiGetManyExhibition
     */
    readonly cache?: number
}

/**
 * Request parameters for getOneExhibition operation in ExhibitionApi.
 * @export
 * @interface ExhibitionApiGetOneExhibitionRequest
 */
export interface ExhibitionApiGetOneExhibitionRequest {
    /**
     * 
     * @type {number}
     * @memberof ExhibitionApiGetOneExhibition
     */
    readonly id: number

    /**
     * Request language
     * @type {string}
     * @memberof ExhibitionApiGetOneExhibition
     */
    readonly acceptLanguage?: string

    /**
     * Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof ExhibitionApiGetOneExhibition
     */
    readonly fields?: Array<string>

    /**
     * Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof ExhibitionApiGetOneExhibition
     */
    readonly join?: Array<string>

    /**
     * Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof ExhibitionApiGetOneExhibition
     */
    readonly cache?: number
}

/**
 * Request parameters for replaceOneExhibition operation in ExhibitionApi.
 * @export
 * @interface ExhibitionApiReplaceOneExhibitionRequest
 */
export interface ExhibitionApiReplaceOneExhibitionRequest {
    /**
     * 
     * @type {number}
     * @memberof ExhibitionApiReplaceOneExhibition
     */
    readonly id: number

    /**
     * 
     * @type {Exhibition}
     * @memberof ExhibitionApiReplaceOneExhibition
     */
    readonly exhibition: Exhibition

    /**
     * Request language
     * @type {string}
     * @memberof ExhibitionApiReplaceOneExhibition
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for updateManyExhibitions operation in ExhibitionApi.
 * @export
 * @interface ExhibitionApiUpdateManyExhibitionsRequest
 */
export interface ExhibitionApiUpdateManyExhibitionsRequest {
    /**
     * 
     * @type {Array<Exhibition>}
     * @memberof ExhibitionApiUpdateManyExhibitions
     */
    readonly exhibition: Array<Exhibition>

    /**
     * Request language
     * @type {string}
     * @memberof ExhibitionApiUpdateManyExhibitions
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for updateOneExhibition operation in ExhibitionApi.
 * @export
 * @interface ExhibitionApiUpdateOneExhibitionRequest
 */
export interface ExhibitionApiUpdateOneExhibitionRequest {
    /**
     * 
     * @type {number}
     * @memberof ExhibitionApiUpdateOneExhibition
     */
    readonly id: number

    /**
     * 
     * @type {Exhibition}
     * @memberof ExhibitionApiUpdateOneExhibition
     */
    readonly exhibition: Exhibition

    /**
     * Request language
     * @type {string}
     * @memberof ExhibitionApiUpdateOneExhibition
     */
    readonly acceptLanguage?: string
}

/**
 * ExhibitionApi - object-oriented interface
 * @export
 * @class ExhibitionApi
 * @extends {BaseAPI}
 */
export class ExhibitionApi extends BaseAPI {
    /**
     * 
     * @summary Get Exhibitions total
     * @param {ExhibitionApiCountExhibitionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExhibitionApi
     */
    public countExhibitions(requestParameters: ExhibitionApiCountExhibitionsRequest = {}, options?: any) {
        return ExhibitionApiFp(this.configuration).countExhibitions(requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create multiple Exhibitions
     * @param {ExhibitionApiCreateManyExhibitionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExhibitionApi
     */
    public createManyExhibition(requestParameters: ExhibitionApiCreateManyExhibitionRequest, options?: any) {
        return ExhibitionApiFp(this.configuration).createManyExhibition(requestParameters.createManyExhibitionDto, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a single Exhibition
     * @param {ExhibitionApiCreateOneExhibitionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExhibitionApi
     */
    public createOneExhibition(requestParameters: ExhibitionApiCreateOneExhibitionRequest, options?: any) {
        return ExhibitionApiFp(this.configuration).createOneExhibition(requestParameters.exhibition, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete multiple Exhibitions
     * @param {ExhibitionApiDeleteManyExhibitionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExhibitionApi
     */
    public deleteManyExhibitions(requestParameters: ExhibitionApiDeleteManyExhibitionsRequest, options?: any) {
        return ExhibitionApiFp(this.configuration).deleteManyExhibitions(requestParameters.requestBody, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a single Exhibition
     * @param {ExhibitionApiDeleteOneExhibitionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExhibitionApi
     */
    public deleteOneExhibition(requestParameters: ExhibitionApiDeleteOneExhibitionRequest, options?: any) {
        return ExhibitionApiFp(this.configuration).deleteOneExhibition(requestParameters.id, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple Exhibitions
     * @param {ExhibitionApiGetManyExhibitionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExhibitionApi
     */
    public getManyExhibition(requestParameters: ExhibitionApiGetManyExhibitionRequest = {}, options?: any) {
        return ExhibitionApiFp(this.configuration).getManyExhibition(requestParameters.acceptLanguage, requestParameters.fields, requestParameters.s, requestParameters.filter, requestParameters.or, requestParameters.sort, requestParameters.join, requestParameters.limit, requestParameters.offset, requestParameters.page, requestParameters.cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single Exhibition
     * @param {ExhibitionApiGetOneExhibitionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExhibitionApi
     */
    public getOneExhibition(requestParameters: ExhibitionApiGetOneExhibitionRequest, options?: any) {
        return ExhibitionApiFp(this.configuration).getOneExhibition(requestParameters.id, requestParameters.acceptLanguage, requestParameters.fields, requestParameters.join, requestParameters.cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Replace a single Exhibition
     * @param {ExhibitionApiReplaceOneExhibitionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExhibitionApi
     */
    public replaceOneExhibition(requestParameters: ExhibitionApiReplaceOneExhibitionRequest, options?: any) {
        return ExhibitionApiFp(this.configuration).replaceOneExhibition(requestParameters.id, requestParameters.exhibition, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update multiple Exhibitions
     * @param {ExhibitionApiUpdateManyExhibitionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExhibitionApi
     */
    public updateManyExhibitions(requestParameters: ExhibitionApiUpdateManyExhibitionsRequest, options?: any) {
        return ExhibitionApiFp(this.configuration).updateManyExhibitions(requestParameters.exhibition, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a single Exhibition
     * @param {ExhibitionApiUpdateOneExhibitionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExhibitionApi
     */
    public updateOneExhibition(requestParameters: ExhibitionApiUpdateOneExhibitionRequest, options?: any) {
        return ExhibitionApiFp(this.configuration).updateOneExhibition(requestParameters.id, requestParameters.exhibition, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LanguageApi - axios parameter creator
 * @export
 */
export const LanguageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Languages total
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countLanguages: async (acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/languages/total/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create multiple Languages
         * @param {CreateManyLanguageDto} createManyLanguageDto 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManyLanguage: async (createManyLanguageDto: CreateManyLanguageDto, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createManyLanguageDto' is not null or undefined
            if (createManyLanguageDto === null || createManyLanguageDto === undefined) {
                throw new RequiredError('createManyLanguageDto','Required parameter createManyLanguageDto was null or undefined when calling createManyLanguage.');
            }
            const localVarPath = `/languages/bulk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createManyLanguageDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createManyLanguageDto !== undefined ? createManyLanguageDto : {})
                : (createManyLanguageDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a single Language
         * @param {Language} language 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneLanguage: async (language: Language, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'language' is not null or undefined
            if (language === null || language === undefined) {
                throw new RequiredError('language','Required parameter language was null or undefined when calling createOneLanguage.');
            }
            const localVarPath = `/languages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof language !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(language !== undefined ? language : {})
                : (language || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete multiple Languages
         * @param {Array<string>} requestBody 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyLanguages: async (requestBody: Array<string>, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            if (requestBody === null || requestBody === undefined) {
                throw new RequiredError('requestBody','Required parameter requestBody was null or undefined when calling deleteManyLanguages.');
            }
            const localVarPath = `/languages/bulk/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof requestBody !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(requestBody !== undefined ? requestBody : {})
                : (requestBody || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a single Language
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneLanguage: async (id: number, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteOneLanguage.');
            }
            const localVarPath = `/languages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple languages
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyLanguage: async (acceptLanguage?: string, fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/languages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single Language
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneLanguage: async (id: number, acceptLanguage?: string, fields?: Array<string>, join?: Array<string>, cache?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOneLanguage.');
            }
            const localVarPath = `/languages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Replace a single Language
         * @param {number} id 
         * @param {Language} language 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceOneLanguage: async (id: number, language: Language, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling replaceOneLanguage.');
            }
            // verify required parameter 'language' is not null or undefined
            if (language === null || language === undefined) {
                throw new RequiredError('language','Required parameter language was null or undefined when calling replaceOneLanguage.');
            }
            const localVarPath = `/languages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof language !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(language !== undefined ? language : {})
                : (language || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update multiple Languages
         * @param {Array<Language>} language 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateManyLanguages: async (language: Array<Language>, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'language' is not null or undefined
            if (language === null || language === undefined) {
                throw new RequiredError('language','Required parameter language was null or undefined when calling updateManyLanguages.');
            }
            const localVarPath = `/languages/bulk/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof language !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(language !== undefined ? language : {})
                : (language || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a single Language
         * @param {number} id 
         * @param {Language} language 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneLanguage: async (id: number, language: Language, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateOneLanguage.');
            }
            // verify required parameter 'language' is not null or undefined
            if (language === null || language === undefined) {
                throw new RequiredError('language','Required parameter language was null or undefined when calling updateOneLanguage.');
            }
            const localVarPath = `/languages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof language !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(language !== undefined ? language : {})
                : (language || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LanguageApi - functional programming interface
 * @export
 */
export const LanguageApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Languages total
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countLanguages(acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await LanguageApiAxiosParamCreator(configuration).countLanguages(acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create multiple Languages
         * @param {CreateManyLanguageDto} createManyLanguageDto 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createManyLanguage(createManyLanguageDto: CreateManyLanguageDto, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Language>>> {
            const localVarAxiosArgs = await LanguageApiAxiosParamCreator(configuration).createManyLanguage(createManyLanguageDto, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create a single Language
         * @param {Language} language 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOneLanguage(language: Language, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Language>> {
            const localVarAxiosArgs = await LanguageApiAxiosParamCreator(configuration).createOneLanguage(language, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete multiple Languages
         * @param {Array<string>} requestBody 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteManyLanguages(requestBody: Array<string>, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await LanguageApiAxiosParamCreator(configuration).deleteManyLanguages(requestBody, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete a single Language
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOneLanguage(id: number, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Language>> {
            const localVarAxiosArgs = await LanguageApiAxiosParamCreator(configuration).deleteOneLanguage(id, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieve multiple languages
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyLanguage(acceptLanguage?: string, fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyLanguageResponseDto>> {
            const localVarAxiosArgs = await LanguageApiAxiosParamCreator(configuration).getManyLanguage(acceptLanguage, fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieve a single Language
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneLanguage(id: number, acceptLanguage?: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Language>> {
            const localVarAxiosArgs = await LanguageApiAxiosParamCreator(configuration).getOneLanguage(id, acceptLanguage, fields, join, cache, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Replace a single Language
         * @param {number} id 
         * @param {Language} language 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceOneLanguage(id: number, language: Language, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Language>> {
            const localVarAxiosArgs = await LanguageApiAxiosParamCreator(configuration).replaceOneLanguage(id, language, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update multiple Languages
         * @param {Array<Language>} language 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateManyLanguages(language: Array<Language>, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Language>>> {
            const localVarAxiosArgs = await LanguageApiAxiosParamCreator(configuration).updateManyLanguages(language, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update a single Language
         * @param {number} id 
         * @param {Language} language 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOneLanguage(id: number, language: Language, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Language>> {
            const localVarAxiosArgs = await LanguageApiAxiosParamCreator(configuration).updateOneLanguage(id, language, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * LanguageApi - factory interface
 * @export
 */
export const LanguageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get Languages total
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countLanguages(acceptLanguage?: string, options?: any): AxiosPromise<number> {
            return LanguageApiFp(configuration).countLanguages(acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create multiple Languages
         * @param {CreateManyLanguageDto} createManyLanguageDto 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManyLanguage(createManyLanguageDto: CreateManyLanguageDto, acceptLanguage?: string, options?: any): AxiosPromise<Array<Language>> {
            return LanguageApiFp(configuration).createManyLanguage(createManyLanguageDto, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a single Language
         * @param {Language} language 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneLanguage(language: Language, acceptLanguage?: string, options?: any): AxiosPromise<Language> {
            return LanguageApiFp(configuration).createOneLanguage(language, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete multiple Languages
         * @param {Array<string>} requestBody 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyLanguages(requestBody: Array<string>, acceptLanguage?: string, options?: any): AxiosPromise<void> {
            return LanguageApiFp(configuration).deleteManyLanguages(requestBody, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a single Language
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneLanguage(id: number, acceptLanguage?: string, options?: any): AxiosPromise<Language> {
            return LanguageApiFp(configuration).deleteOneLanguage(id, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple languages
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyLanguage(acceptLanguage?: string, fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<GetManyLanguageResponseDto> {
            return LanguageApiFp(configuration).getManyLanguage(acceptLanguage, fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single Language
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneLanguage(id: number, acceptLanguage?: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<Language> {
            return LanguageApiFp(configuration).getOneLanguage(id, acceptLanguage, fields, join, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Replace a single Language
         * @param {number} id 
         * @param {Language} language 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceOneLanguage(id: number, language: Language, acceptLanguage?: string, options?: any): AxiosPromise<Language> {
            return LanguageApiFp(configuration).replaceOneLanguage(id, language, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update multiple Languages
         * @param {Array<Language>} language 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateManyLanguages(language: Array<Language>, acceptLanguage?: string, options?: any): AxiosPromise<Array<Language>> {
            return LanguageApiFp(configuration).updateManyLanguages(language, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a single Language
         * @param {number} id 
         * @param {Language} language 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneLanguage(id: number, language: Language, acceptLanguage?: string, options?: any): AxiosPromise<Language> {
            return LanguageApiFp(configuration).updateOneLanguage(id, language, acceptLanguage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for countLanguages operation in LanguageApi.
 * @export
 * @interface LanguageApiCountLanguagesRequest
 */
export interface LanguageApiCountLanguagesRequest {
    /**
     * Request language
     * @type {string}
     * @memberof LanguageApiCountLanguages
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for createManyLanguage operation in LanguageApi.
 * @export
 * @interface LanguageApiCreateManyLanguageRequest
 */
export interface LanguageApiCreateManyLanguageRequest {
    /**
     * 
     * @type {CreateManyLanguageDto}
     * @memberof LanguageApiCreateManyLanguage
     */
    readonly createManyLanguageDto: CreateManyLanguageDto

    /**
     * Request language
     * @type {string}
     * @memberof LanguageApiCreateManyLanguage
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for createOneLanguage operation in LanguageApi.
 * @export
 * @interface LanguageApiCreateOneLanguageRequest
 */
export interface LanguageApiCreateOneLanguageRequest {
    /**
     * 
     * @type {Language}
     * @memberof LanguageApiCreateOneLanguage
     */
    readonly language: Language

    /**
     * Request language
     * @type {string}
     * @memberof LanguageApiCreateOneLanguage
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for deleteManyLanguages operation in LanguageApi.
 * @export
 * @interface LanguageApiDeleteManyLanguagesRequest
 */
export interface LanguageApiDeleteManyLanguagesRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof LanguageApiDeleteManyLanguages
     */
    readonly requestBody: Array<string>

    /**
     * Request language
     * @type {string}
     * @memberof LanguageApiDeleteManyLanguages
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for deleteOneLanguage operation in LanguageApi.
 * @export
 * @interface LanguageApiDeleteOneLanguageRequest
 */
export interface LanguageApiDeleteOneLanguageRequest {
    /**
     * 
     * @type {number}
     * @memberof LanguageApiDeleteOneLanguage
     */
    readonly id: number

    /**
     * Request language
     * @type {string}
     * @memberof LanguageApiDeleteOneLanguage
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for getManyLanguage operation in LanguageApi.
 * @export
 * @interface LanguageApiGetManyLanguageRequest
 */
export interface LanguageApiGetManyLanguageRequest {
    /**
     * Request language
     * @type {string}
     * @memberof LanguageApiGetManyLanguage
     */
    readonly acceptLanguage?: string

    /**
     * Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof LanguageApiGetManyLanguage
     */
    readonly fields?: Array<string>

    /**
     * Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {string}
     * @memberof LanguageApiGetManyLanguage
     */
    readonly s?: string

    /**
     * Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof LanguageApiGetManyLanguage
     */
    readonly filter?: Array<string>

    /**
     * Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof LanguageApiGetManyLanguage
     */
    readonly or?: Array<string>

    /**
     * Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof LanguageApiGetManyLanguage
     */
    readonly sort?: Array<string>

    /**
     * Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof LanguageApiGetManyLanguage
     */
    readonly join?: Array<string>

    /**
     * Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof LanguageApiGetManyLanguage
     */
    readonly limit?: number

    /**
     * Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof LanguageApiGetManyLanguage
     */
    readonly offset?: number

    /**
     * Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof LanguageApiGetManyLanguage
     */
    readonly page?: number

    /**
     * Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof LanguageApiGetManyLanguage
     */
    readonly cache?: number
}

/**
 * Request parameters for getOneLanguage operation in LanguageApi.
 * @export
 * @interface LanguageApiGetOneLanguageRequest
 */
export interface LanguageApiGetOneLanguageRequest {
    /**
     * 
     * @type {number}
     * @memberof LanguageApiGetOneLanguage
     */
    readonly id: number

    /**
     * Request language
     * @type {string}
     * @memberof LanguageApiGetOneLanguage
     */
    readonly acceptLanguage?: string

    /**
     * Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof LanguageApiGetOneLanguage
     */
    readonly fields?: Array<string>

    /**
     * Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof LanguageApiGetOneLanguage
     */
    readonly join?: Array<string>

    /**
     * Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof LanguageApiGetOneLanguage
     */
    readonly cache?: number
}

/**
 * Request parameters for replaceOneLanguage operation in LanguageApi.
 * @export
 * @interface LanguageApiReplaceOneLanguageRequest
 */
export interface LanguageApiReplaceOneLanguageRequest {
    /**
     * 
     * @type {number}
     * @memberof LanguageApiReplaceOneLanguage
     */
    readonly id: number

    /**
     * 
     * @type {Language}
     * @memberof LanguageApiReplaceOneLanguage
     */
    readonly language: Language

    /**
     * Request language
     * @type {string}
     * @memberof LanguageApiReplaceOneLanguage
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for updateManyLanguages operation in LanguageApi.
 * @export
 * @interface LanguageApiUpdateManyLanguagesRequest
 */
export interface LanguageApiUpdateManyLanguagesRequest {
    /**
     * 
     * @type {Array<Language>}
     * @memberof LanguageApiUpdateManyLanguages
     */
    readonly language: Array<Language>

    /**
     * Request language
     * @type {string}
     * @memberof LanguageApiUpdateManyLanguages
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for updateOneLanguage operation in LanguageApi.
 * @export
 * @interface LanguageApiUpdateOneLanguageRequest
 */
export interface LanguageApiUpdateOneLanguageRequest {
    /**
     * 
     * @type {number}
     * @memberof LanguageApiUpdateOneLanguage
     */
    readonly id: number

    /**
     * 
     * @type {Language}
     * @memberof LanguageApiUpdateOneLanguage
     */
    readonly language: Language

    /**
     * Request language
     * @type {string}
     * @memberof LanguageApiUpdateOneLanguage
     */
    readonly acceptLanguage?: string
}

/**
 * LanguageApi - object-oriented interface
 * @export
 * @class LanguageApi
 * @extends {BaseAPI}
 */
export class LanguageApi extends BaseAPI {
    /**
     * 
     * @summary Get Languages total
     * @param {LanguageApiCountLanguagesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguageApi
     */
    public countLanguages(requestParameters: LanguageApiCountLanguagesRequest = {}, options?: any) {
        return LanguageApiFp(this.configuration).countLanguages(requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create multiple Languages
     * @param {LanguageApiCreateManyLanguageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguageApi
     */
    public createManyLanguage(requestParameters: LanguageApiCreateManyLanguageRequest, options?: any) {
        return LanguageApiFp(this.configuration).createManyLanguage(requestParameters.createManyLanguageDto, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a single Language
     * @param {LanguageApiCreateOneLanguageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguageApi
     */
    public createOneLanguage(requestParameters: LanguageApiCreateOneLanguageRequest, options?: any) {
        return LanguageApiFp(this.configuration).createOneLanguage(requestParameters.language, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete multiple Languages
     * @param {LanguageApiDeleteManyLanguagesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguageApi
     */
    public deleteManyLanguages(requestParameters: LanguageApiDeleteManyLanguagesRequest, options?: any) {
        return LanguageApiFp(this.configuration).deleteManyLanguages(requestParameters.requestBody, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a single Language
     * @param {LanguageApiDeleteOneLanguageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguageApi
     */
    public deleteOneLanguage(requestParameters: LanguageApiDeleteOneLanguageRequest, options?: any) {
        return LanguageApiFp(this.configuration).deleteOneLanguage(requestParameters.id, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple languages
     * @param {LanguageApiGetManyLanguageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguageApi
     */
    public getManyLanguage(requestParameters: LanguageApiGetManyLanguageRequest = {}, options?: any) {
        return LanguageApiFp(this.configuration).getManyLanguage(requestParameters.acceptLanguage, requestParameters.fields, requestParameters.s, requestParameters.filter, requestParameters.or, requestParameters.sort, requestParameters.join, requestParameters.limit, requestParameters.offset, requestParameters.page, requestParameters.cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single Language
     * @param {LanguageApiGetOneLanguageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguageApi
     */
    public getOneLanguage(requestParameters: LanguageApiGetOneLanguageRequest, options?: any) {
        return LanguageApiFp(this.configuration).getOneLanguage(requestParameters.id, requestParameters.acceptLanguage, requestParameters.fields, requestParameters.join, requestParameters.cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Replace a single Language
     * @param {LanguageApiReplaceOneLanguageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguageApi
     */
    public replaceOneLanguage(requestParameters: LanguageApiReplaceOneLanguageRequest, options?: any) {
        return LanguageApiFp(this.configuration).replaceOneLanguage(requestParameters.id, requestParameters.language, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update multiple Languages
     * @param {LanguageApiUpdateManyLanguagesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguageApi
     */
    public updateManyLanguages(requestParameters: LanguageApiUpdateManyLanguagesRequest, options?: any) {
        return LanguageApiFp(this.configuration).updateManyLanguages(requestParameters.language, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a single Language
     * @param {LanguageApiUpdateOneLanguageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguageApi
     */
    public updateOneLanguage(requestParameters: LanguageApiUpdateOneLanguageRequest, options?: any) {
        return LanguageApiFp(this.configuration).updateOneLanguage(requestParameters.id, requestParameters.language, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MailApi - axios parameter creator
 * @export
 */
export const MailApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary User activation mail.
         * @param {EmailDto} emailDto 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateUser: async (emailDto: EmailDto, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailDto' is not null or undefined
            if (emailDto === null || emailDto === undefined) {
                throw new RequiredError('emailDto','Required parameter emailDto was null or undefined when calling activateUser.');
            }
            const localVarPath = `/mail/activate-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof emailDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(emailDto !== undefined ? emailDto : {})
                : (emailDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Mails total
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countMails: async (acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/mail/total/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create multiple Mails
         * @param {CreateManyMailDto} createManyMailDto 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManyMail: async (createManyMailDto: CreateManyMailDto, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createManyMailDto' is not null or undefined
            if (createManyMailDto === null || createManyMailDto === undefined) {
                throw new RequiredError('createManyMailDto','Required parameter createManyMailDto was null or undefined when calling createManyMail.');
            }
            const localVarPath = `/mail/bulk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createManyMailDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createManyMailDto !== undefined ? createManyMailDto : {})
                : (createManyMailDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a single Mail
         * @param {Mail} mail 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneMail: async (mail: Mail, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mail' is not null or undefined
            if (mail === null || mail === undefined) {
                throw new RequiredError('mail','Required parameter mail was null or undefined when calling createOneMail.');
            }
            const localVarPath = `/mail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof mail !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(mail !== undefined ? mail : {})
                : (mail || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete multiple Mails
         * @param {Array<string>} requestBody 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyMails: async (requestBody: Array<string>, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            if (requestBody === null || requestBody === undefined) {
                throw new RequiredError('requestBody','Required parameter requestBody was null or undefined when calling deleteManyMails.');
            }
            const localVarPath = `/mail/bulk/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof requestBody !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(requestBody !== undefined ? requestBody : {})
                : (requestBody || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a single Mail
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneMail: async (id: number, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteOneMail.');
            }
            const localVarPath = `/mail/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple Mails
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyMail: async (acceptLanguage?: string, fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/mail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single Mail
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneMail: async (id: number, acceptLanguage?: string, fields?: Array<string>, join?: Array<string>, cache?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOneMail.');
            }
            const localVarPath = `/mail/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Password recovery mail.
         * @param {EmailDto} emailDto 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordRecovery: async (emailDto: EmailDto, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailDto' is not null or undefined
            if (emailDto === null || emailDto === undefined) {
                throw new RequiredError('emailDto','Required parameter emailDto was null or undefined when calling passwordRecovery.');
            }
            const localVarPath = `/mail/password-recovery`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof emailDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(emailDto !== undefined ? emailDto : {})
                : (emailDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Replace a single Mail
         * @param {number} id 
         * @param {Mail} mail 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceOneMail: async (id: number, mail: Mail, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling replaceOneMail.');
            }
            // verify required parameter 'mail' is not null or undefined
            if (mail === null || mail === undefined) {
                throw new RequiredError('mail','Required parameter mail was null or undefined when calling replaceOneMail.');
            }
            const localVarPath = `/mail/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof mail !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(mail !== undefined ? mail : {})
                : (mail || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Simple contact mail.
         * @param {SimpleContactDto} simpleContactDto 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        simpleContact: async (simpleContactDto: SimpleContactDto, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'simpleContactDto' is not null or undefined
            if (simpleContactDto === null || simpleContactDto === undefined) {
                throw new RequiredError('simpleContactDto','Required parameter simpleContactDto was null or undefined when calling simpleContact.');
            }
            const localVarPath = `/mail/simple-contact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof simpleContactDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(simpleContactDto !== undefined ? simpleContactDto : {})
                : (simpleContactDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update multiple Mails
         * @param {Array<Mail>} mail 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateManyMails: async (mail: Array<Mail>, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mail' is not null or undefined
            if (mail === null || mail === undefined) {
                throw new RequiredError('mail','Required parameter mail was null or undefined when calling updateManyMails.');
            }
            const localVarPath = `/mail/bulk/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof mail !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(mail !== undefined ? mail : {})
                : (mail || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a single Mail
         * @param {number} id 
         * @param {Mail} mail 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneMail: async (id: number, mail: Mail, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateOneMail.');
            }
            // verify required parameter 'mail' is not null or undefined
            if (mail === null || mail === undefined) {
                throw new RequiredError('mail','Required parameter mail was null or undefined when calling updateOneMail.');
            }
            const localVarPath = `/mail/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof mail !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(mail !== undefined ? mail : {})
                : (mail || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MailApi - functional programming interface
 * @export
 */
export const MailApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary User activation mail.
         * @param {EmailDto} emailDto 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activateUser(emailDto: EmailDto, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MailApiAxiosParamCreator(configuration).activateUser(emailDto, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get Mails total
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countMails(acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await MailApiAxiosParamCreator(configuration).countMails(acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create multiple Mails
         * @param {CreateManyMailDto} createManyMailDto 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createManyMail(createManyMailDto: CreateManyMailDto, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Mail>>> {
            const localVarAxiosArgs = await MailApiAxiosParamCreator(configuration).createManyMail(createManyMailDto, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create a single Mail
         * @param {Mail} mail 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOneMail(mail: Mail, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Mail>> {
            const localVarAxiosArgs = await MailApiAxiosParamCreator(configuration).createOneMail(mail, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete multiple Mails
         * @param {Array<string>} requestBody 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteManyMails(requestBody: Array<string>, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MailApiAxiosParamCreator(configuration).deleteManyMails(requestBody, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete a single Mail
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOneMail(id: number, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Mail>> {
            const localVarAxiosArgs = await MailApiAxiosParamCreator(configuration).deleteOneMail(id, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieve multiple Mails
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyMail(acceptLanguage?: string, fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyMailResponseDto>> {
            const localVarAxiosArgs = await MailApiAxiosParamCreator(configuration).getManyMail(acceptLanguage, fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieve a single Mail
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneMail(id: number, acceptLanguage?: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Mail>> {
            const localVarAxiosArgs = await MailApiAxiosParamCreator(configuration).getOneMail(id, acceptLanguage, fields, join, cache, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Password recovery mail.
         * @param {EmailDto} emailDto 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async passwordRecovery(emailDto: EmailDto, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MailApiAxiosParamCreator(configuration).passwordRecovery(emailDto, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Replace a single Mail
         * @param {number} id 
         * @param {Mail} mail 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceOneMail(id: number, mail: Mail, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Mail>> {
            const localVarAxiosArgs = await MailApiAxiosParamCreator(configuration).replaceOneMail(id, mail, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Simple contact mail.
         * @param {SimpleContactDto} simpleContactDto 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async simpleContact(simpleContactDto: SimpleContactDto, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MailApiAxiosParamCreator(configuration).simpleContact(simpleContactDto, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update multiple Mails
         * @param {Array<Mail>} mail 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateManyMails(mail: Array<Mail>, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Mail>>> {
            const localVarAxiosArgs = await MailApiAxiosParamCreator(configuration).updateManyMails(mail, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update a single Mail
         * @param {number} id 
         * @param {Mail} mail 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOneMail(id: number, mail: Mail, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Mail>> {
            const localVarAxiosArgs = await MailApiAxiosParamCreator(configuration).updateOneMail(id, mail, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * MailApi - factory interface
 * @export
 */
export const MailApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary User activation mail.
         * @param {EmailDto} emailDto 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateUser(emailDto: EmailDto, acceptLanguage?: string, options?: any): AxiosPromise<void> {
            return MailApiFp(configuration).activateUser(emailDto, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Mails total
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countMails(acceptLanguage?: string, options?: any): AxiosPromise<number> {
            return MailApiFp(configuration).countMails(acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create multiple Mails
         * @param {CreateManyMailDto} createManyMailDto 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManyMail(createManyMailDto: CreateManyMailDto, acceptLanguage?: string, options?: any): AxiosPromise<Array<Mail>> {
            return MailApiFp(configuration).createManyMail(createManyMailDto, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a single Mail
         * @param {Mail} mail 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneMail(mail: Mail, acceptLanguage?: string, options?: any): AxiosPromise<Mail> {
            return MailApiFp(configuration).createOneMail(mail, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete multiple Mails
         * @param {Array<string>} requestBody 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyMails(requestBody: Array<string>, acceptLanguage?: string, options?: any): AxiosPromise<void> {
            return MailApiFp(configuration).deleteManyMails(requestBody, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a single Mail
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneMail(id: number, acceptLanguage?: string, options?: any): AxiosPromise<Mail> {
            return MailApiFp(configuration).deleteOneMail(id, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple Mails
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyMail(acceptLanguage?: string, fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<GetManyMailResponseDto> {
            return MailApiFp(configuration).getManyMail(acceptLanguage, fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single Mail
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneMail(id: number, acceptLanguage?: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<Mail> {
            return MailApiFp(configuration).getOneMail(id, acceptLanguage, fields, join, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Password recovery mail.
         * @param {EmailDto} emailDto 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordRecovery(emailDto: EmailDto, acceptLanguage?: string, options?: any): AxiosPromise<void> {
            return MailApiFp(configuration).passwordRecovery(emailDto, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Replace a single Mail
         * @param {number} id 
         * @param {Mail} mail 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceOneMail(id: number, mail: Mail, acceptLanguage?: string, options?: any): AxiosPromise<Mail> {
            return MailApiFp(configuration).replaceOneMail(id, mail, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Simple contact mail.
         * @param {SimpleContactDto} simpleContactDto 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        simpleContact(simpleContactDto: SimpleContactDto, acceptLanguage?: string, options?: any): AxiosPromise<void> {
            return MailApiFp(configuration).simpleContact(simpleContactDto, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update multiple Mails
         * @param {Array<Mail>} mail 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateManyMails(mail: Array<Mail>, acceptLanguage?: string, options?: any): AxiosPromise<Array<Mail>> {
            return MailApiFp(configuration).updateManyMails(mail, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a single Mail
         * @param {number} id 
         * @param {Mail} mail 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneMail(id: number, mail: Mail, acceptLanguage?: string, options?: any): AxiosPromise<Mail> {
            return MailApiFp(configuration).updateOneMail(id, mail, acceptLanguage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for activateUser operation in MailApi.
 * @export
 * @interface MailApiActivateUserRequest
 */
export interface MailApiActivateUserRequest {
    /**
     * 
     * @type {EmailDto}
     * @memberof MailApiActivateUser
     */
    readonly emailDto: EmailDto

    /**
     * Request language
     * @type {string}
     * @memberof MailApiActivateUser
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for countMails operation in MailApi.
 * @export
 * @interface MailApiCountMailsRequest
 */
export interface MailApiCountMailsRequest {
    /**
     * Request language
     * @type {string}
     * @memberof MailApiCountMails
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for createManyMail operation in MailApi.
 * @export
 * @interface MailApiCreateManyMailRequest
 */
export interface MailApiCreateManyMailRequest {
    /**
     * 
     * @type {CreateManyMailDto}
     * @memberof MailApiCreateManyMail
     */
    readonly createManyMailDto: CreateManyMailDto

    /**
     * Request language
     * @type {string}
     * @memberof MailApiCreateManyMail
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for createOneMail operation in MailApi.
 * @export
 * @interface MailApiCreateOneMailRequest
 */
export interface MailApiCreateOneMailRequest {
    /**
     * 
     * @type {Mail}
     * @memberof MailApiCreateOneMail
     */
    readonly mail: Mail

    /**
     * Request language
     * @type {string}
     * @memberof MailApiCreateOneMail
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for deleteManyMails operation in MailApi.
 * @export
 * @interface MailApiDeleteManyMailsRequest
 */
export interface MailApiDeleteManyMailsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof MailApiDeleteManyMails
     */
    readonly requestBody: Array<string>

    /**
     * Request language
     * @type {string}
     * @memberof MailApiDeleteManyMails
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for deleteOneMail operation in MailApi.
 * @export
 * @interface MailApiDeleteOneMailRequest
 */
export interface MailApiDeleteOneMailRequest {
    /**
     * 
     * @type {number}
     * @memberof MailApiDeleteOneMail
     */
    readonly id: number

    /**
     * Request language
     * @type {string}
     * @memberof MailApiDeleteOneMail
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for getManyMail operation in MailApi.
 * @export
 * @interface MailApiGetManyMailRequest
 */
export interface MailApiGetManyMailRequest {
    /**
     * Request language
     * @type {string}
     * @memberof MailApiGetManyMail
     */
    readonly acceptLanguage?: string

    /**
     * Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof MailApiGetManyMail
     */
    readonly fields?: Array<string>

    /**
     * Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {string}
     * @memberof MailApiGetManyMail
     */
    readonly s?: string

    /**
     * Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof MailApiGetManyMail
     */
    readonly filter?: Array<string>

    /**
     * Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof MailApiGetManyMail
     */
    readonly or?: Array<string>

    /**
     * Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof MailApiGetManyMail
     */
    readonly sort?: Array<string>

    /**
     * Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof MailApiGetManyMail
     */
    readonly join?: Array<string>

    /**
     * Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof MailApiGetManyMail
     */
    readonly limit?: number

    /**
     * Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof MailApiGetManyMail
     */
    readonly offset?: number

    /**
     * Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof MailApiGetManyMail
     */
    readonly page?: number

    /**
     * Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof MailApiGetManyMail
     */
    readonly cache?: number
}

/**
 * Request parameters for getOneMail operation in MailApi.
 * @export
 * @interface MailApiGetOneMailRequest
 */
export interface MailApiGetOneMailRequest {
    /**
     * 
     * @type {number}
     * @memberof MailApiGetOneMail
     */
    readonly id: number

    /**
     * Request language
     * @type {string}
     * @memberof MailApiGetOneMail
     */
    readonly acceptLanguage?: string

    /**
     * Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof MailApiGetOneMail
     */
    readonly fields?: Array<string>

    /**
     * Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof MailApiGetOneMail
     */
    readonly join?: Array<string>

    /**
     * Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof MailApiGetOneMail
     */
    readonly cache?: number
}

/**
 * Request parameters for passwordRecovery operation in MailApi.
 * @export
 * @interface MailApiPasswordRecoveryRequest
 */
export interface MailApiPasswordRecoveryRequest {
    /**
     * 
     * @type {EmailDto}
     * @memberof MailApiPasswordRecovery
     */
    readonly emailDto: EmailDto

    /**
     * Request language
     * @type {string}
     * @memberof MailApiPasswordRecovery
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for replaceOneMail operation in MailApi.
 * @export
 * @interface MailApiReplaceOneMailRequest
 */
export interface MailApiReplaceOneMailRequest {
    /**
     * 
     * @type {number}
     * @memberof MailApiReplaceOneMail
     */
    readonly id: number

    /**
     * 
     * @type {Mail}
     * @memberof MailApiReplaceOneMail
     */
    readonly mail: Mail

    /**
     * Request language
     * @type {string}
     * @memberof MailApiReplaceOneMail
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for simpleContact operation in MailApi.
 * @export
 * @interface MailApiSimpleContactRequest
 */
export interface MailApiSimpleContactRequest {
    /**
     * 
     * @type {SimpleContactDto}
     * @memberof MailApiSimpleContact
     */
    readonly simpleContactDto: SimpleContactDto

    /**
     * Request language
     * @type {string}
     * @memberof MailApiSimpleContact
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for updateManyMails operation in MailApi.
 * @export
 * @interface MailApiUpdateManyMailsRequest
 */
export interface MailApiUpdateManyMailsRequest {
    /**
     * 
     * @type {Array<Mail>}
     * @memberof MailApiUpdateManyMails
     */
    readonly mail: Array<Mail>

    /**
     * Request language
     * @type {string}
     * @memberof MailApiUpdateManyMails
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for updateOneMail operation in MailApi.
 * @export
 * @interface MailApiUpdateOneMailRequest
 */
export interface MailApiUpdateOneMailRequest {
    /**
     * 
     * @type {number}
     * @memberof MailApiUpdateOneMail
     */
    readonly id: number

    /**
     * 
     * @type {Mail}
     * @memberof MailApiUpdateOneMail
     */
    readonly mail: Mail

    /**
     * Request language
     * @type {string}
     * @memberof MailApiUpdateOneMail
     */
    readonly acceptLanguage?: string
}

/**
 * MailApi - object-oriented interface
 * @export
 * @class MailApi
 * @extends {BaseAPI}
 */
export class MailApi extends BaseAPI {
    /**
     * 
     * @summary User activation mail.
     * @param {MailApiActivateUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApi
     */
    public activateUser(requestParameters: MailApiActivateUserRequest, options?: any) {
        return MailApiFp(this.configuration).activateUser(requestParameters.emailDto, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Mails total
     * @param {MailApiCountMailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApi
     */
    public countMails(requestParameters: MailApiCountMailsRequest = {}, options?: any) {
        return MailApiFp(this.configuration).countMails(requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create multiple Mails
     * @param {MailApiCreateManyMailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApi
     */
    public createManyMail(requestParameters: MailApiCreateManyMailRequest, options?: any) {
        return MailApiFp(this.configuration).createManyMail(requestParameters.createManyMailDto, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a single Mail
     * @param {MailApiCreateOneMailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApi
     */
    public createOneMail(requestParameters: MailApiCreateOneMailRequest, options?: any) {
        return MailApiFp(this.configuration).createOneMail(requestParameters.mail, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete multiple Mails
     * @param {MailApiDeleteManyMailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApi
     */
    public deleteManyMails(requestParameters: MailApiDeleteManyMailsRequest, options?: any) {
        return MailApiFp(this.configuration).deleteManyMails(requestParameters.requestBody, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a single Mail
     * @param {MailApiDeleteOneMailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApi
     */
    public deleteOneMail(requestParameters: MailApiDeleteOneMailRequest, options?: any) {
        return MailApiFp(this.configuration).deleteOneMail(requestParameters.id, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple Mails
     * @param {MailApiGetManyMailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApi
     */
    public getManyMail(requestParameters: MailApiGetManyMailRequest = {}, options?: any) {
        return MailApiFp(this.configuration).getManyMail(requestParameters.acceptLanguage, requestParameters.fields, requestParameters.s, requestParameters.filter, requestParameters.or, requestParameters.sort, requestParameters.join, requestParameters.limit, requestParameters.offset, requestParameters.page, requestParameters.cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single Mail
     * @param {MailApiGetOneMailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApi
     */
    public getOneMail(requestParameters: MailApiGetOneMailRequest, options?: any) {
        return MailApiFp(this.configuration).getOneMail(requestParameters.id, requestParameters.acceptLanguage, requestParameters.fields, requestParameters.join, requestParameters.cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Password recovery mail.
     * @param {MailApiPasswordRecoveryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApi
     */
    public passwordRecovery(requestParameters: MailApiPasswordRecoveryRequest, options?: any) {
        return MailApiFp(this.configuration).passwordRecovery(requestParameters.emailDto, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Replace a single Mail
     * @param {MailApiReplaceOneMailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApi
     */
    public replaceOneMail(requestParameters: MailApiReplaceOneMailRequest, options?: any) {
        return MailApiFp(this.configuration).replaceOneMail(requestParameters.id, requestParameters.mail, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Simple contact mail.
     * @param {MailApiSimpleContactRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApi
     */
    public simpleContact(requestParameters: MailApiSimpleContactRequest, options?: any) {
        return MailApiFp(this.configuration).simpleContact(requestParameters.simpleContactDto, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update multiple Mails
     * @param {MailApiUpdateManyMailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApi
     */
    public updateManyMails(requestParameters: MailApiUpdateManyMailsRequest, options?: any) {
        return MailApiFp(this.configuration).updateManyMails(requestParameters.mail, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a single Mail
     * @param {MailApiUpdateOneMailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApi
     */
    public updateOneMail(requestParameters: MailApiUpdateOneMailRequest, options?: any) {
        return MailApiFp(this.configuration).updateOneMail(requestParameters.id, requestParameters.mail, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MediaApi - axios parameter creator
 * @export
 */
export const MediaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Clone media file(s)
         * @param {CloneMediaDTO} cloneMediaDTO 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneMedia: async (cloneMediaDTO: CloneMediaDTO, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cloneMediaDTO' is not null or undefined
            if (cloneMediaDTO === null || cloneMediaDTO === undefined) {
                throw new RequiredError('cloneMediaDTO','Required parameter cloneMediaDTO was null or undefined when calling cloneMedia.');
            }
            const localVarPath = `/media/clone`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof cloneMediaDTO !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(cloneMediaDTO !== undefined ? cloneMediaDTO : {})
                : (cloneMediaDTO || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Medias total
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countMedias: async (acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/media/total/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create multiple Medias
         * @param {CreateManyMediaDto} createManyMediaDto 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManyMedia: async (createManyMediaDto: CreateManyMediaDto, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createManyMediaDto' is not null or undefined
            if (createManyMediaDto === null || createManyMediaDto === undefined) {
                throw new RequiredError('createManyMediaDto','Required parameter createManyMediaDto was null or undefined when calling createManyMedia.');
            }
            const localVarPath = `/media/bulk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createManyMediaDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createManyMediaDto !== undefined ? createManyMediaDto : {})
                : (createManyMediaDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a single Media
         * @param {Media} media 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneMedia: async (media: Media, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'media' is not null or undefined
            if (media === null || media === undefined) {
                throw new RequiredError('media','Required parameter media was null or undefined when calling createOneMedia.');
            }
            const localVarPath = `/media`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof media !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(media !== undefined ? media : {})
                : (media || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete multiple Medias
         * @param {Array<string>} requestBody 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyMedias: async (requestBody: Array<string>, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            if (requestBody === null || requestBody === undefined) {
                throw new RequiredError('requestBody','Required parameter requestBody was null or undefined when calling deleteManyMedias.');
            }
            const localVarPath = `/media/bulk/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof requestBody !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(requestBody !== undefined ? requestBody : {})
                : (requestBody || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a single Media
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneMedia: async (id: number, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteOneMedia.');
            }
            const localVarPath = `/media/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple Medias
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyMedia: async (acceptLanguage?: string, fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/media`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single Media
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneMedia: async (id: number, acceptLanguage?: string, fields?: Array<string>, join?: Array<string>, cache?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOneMedia.');
            }
            const localVarPath = `/media/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Replace a single Media
         * @param {number} id 
         * @param {Media} media 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceOneMedia: async (id: number, media: Media, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling replaceOneMedia.');
            }
            // verify required parameter 'media' is not null or undefined
            if (media === null || media === undefined) {
                throw new RequiredError('media','Required parameter media was null or undefined when calling replaceOneMedia.');
            }
            const localVarPath = `/media/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof media !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(media !== undefined ? media : {})
                : (media || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update multiple Medias
         * @param {Array<Media>} media 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateManyMedias: async (media: Array<Media>, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'media' is not null or undefined
            if (media === null || media === undefined) {
                throw new RequiredError('media','Required parameter media was null or undefined when calling updateManyMedias.');
            }
            const localVarPath = `/media/bulk/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof media !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(media !== undefined ? media : {})
                : (media || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a single Media
         * @param {number} id 
         * @param {Media} media 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneMedia: async (id: number, media: Media, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateOneMedia.');
            }
            // verify required parameter 'media' is not null or undefined
            if (media === null || media === undefined) {
                throw new RequiredError('media','Required parameter media was null or undefined when calling updateOneMedia.');
            }
            const localVarPath = `/media/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof media !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(media !== undefined ? media : {})
                : (media || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload media file(s)
         * @param {Array<any>} files 
         * @param {string} [acceptLanguage] Request language
         * @param {boolean} [hidden] 
         * @param {Media} [mediaInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadMedia: async (files: Array<any>, acceptLanguage?: string, hidden?: boolean, mediaInfo?: Media, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'files' is not null or undefined
            if (files === null || files === undefined) {
                throw new RequiredError('files','Required parameter files was null or undefined when calling uploadMedia.');
            }
            const localVarPath = `/media/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }

            if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

    
            if (hidden !== undefined) { 
                localVarFormParams.append('hidden', hidden as any);
            }
    
            if (mediaInfo !== undefined) { 
                localVarFormParams.append('mediaInfo', new Blob([JSON.stringify(mediaInfo)], { type: "application/json", }));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
try { function dataJsonFix(key, value) { return new Promise((resolve, reject) => { let reader = new FileReader(); reader.onload = () => { if(reader.result) localVarFormParams.set(key, reader.result); resolve(reader.result); }; reader.onerror = reject; reader.readAsText(value); }) } for (const [key, value] of localVarFormParams.entries()) if (value.type === "application/json") await dataJsonFix(key, value); } catch (error) { console.error(error); }
    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MediaApi - functional programming interface
 * @export
 */
export const MediaApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Clone media file(s)
         * @param {CloneMediaDTO} cloneMediaDTO 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cloneMedia(cloneMediaDTO: CloneMediaDTO, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Media>>> {
            const localVarAxiosArgs = await MediaApiAxiosParamCreator(configuration).cloneMedia(cloneMediaDTO, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get Medias total
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countMedias(acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await MediaApiAxiosParamCreator(configuration).countMedias(acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create multiple Medias
         * @param {CreateManyMediaDto} createManyMediaDto 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createManyMedia(createManyMediaDto: CreateManyMediaDto, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Media>>> {
            const localVarAxiosArgs = await MediaApiAxiosParamCreator(configuration).createManyMedia(createManyMediaDto, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create a single Media
         * @param {Media} media 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOneMedia(media: Media, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Media>> {
            const localVarAxiosArgs = await MediaApiAxiosParamCreator(configuration).createOneMedia(media, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete multiple Medias
         * @param {Array<string>} requestBody 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteManyMedias(requestBody: Array<string>, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MediaApiAxiosParamCreator(configuration).deleteManyMedias(requestBody, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete a single Media
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOneMedia(id: number, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Media>> {
            const localVarAxiosArgs = await MediaApiAxiosParamCreator(configuration).deleteOneMedia(id, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieve multiple Medias
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyMedia(acceptLanguage?: string, fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyMediaResponseDto>> {
            const localVarAxiosArgs = await MediaApiAxiosParamCreator(configuration).getManyMedia(acceptLanguage, fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieve a single Media
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneMedia(id: number, acceptLanguage?: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Media>> {
            const localVarAxiosArgs = await MediaApiAxiosParamCreator(configuration).getOneMedia(id, acceptLanguage, fields, join, cache, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Replace a single Media
         * @param {number} id 
         * @param {Media} media 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceOneMedia(id: number, media: Media, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Media>> {
            const localVarAxiosArgs = await MediaApiAxiosParamCreator(configuration).replaceOneMedia(id, media, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update multiple Medias
         * @param {Array<Media>} media 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateManyMedias(media: Array<Media>, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Media>>> {
            const localVarAxiosArgs = await MediaApiAxiosParamCreator(configuration).updateManyMedias(media, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update a single Media
         * @param {number} id 
         * @param {Media} media 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOneMedia(id: number, media: Media, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Media>> {
            const localVarAxiosArgs = await MediaApiAxiosParamCreator(configuration).updateOneMedia(id, media, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Upload media file(s)
         * @param {Array<any>} files 
         * @param {string} [acceptLanguage] Request language
         * @param {boolean} [hidden] 
         * @param {Media} [mediaInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadMedia(files: Array<any>, acceptLanguage?: string, hidden?: boolean, mediaInfo?: Media, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Media>>> {
            const localVarAxiosArgs = await MediaApiAxiosParamCreator(configuration).uploadMedia(files, acceptLanguage, hidden, mediaInfo, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * MediaApi - factory interface
 * @export
 */
export const MediaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Clone media file(s)
         * @param {CloneMediaDTO} cloneMediaDTO 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneMedia(cloneMediaDTO: CloneMediaDTO, acceptLanguage?: string, options?: any): AxiosPromise<Array<Media>> {
            return MediaApiFp(configuration).cloneMedia(cloneMediaDTO, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Medias total
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countMedias(acceptLanguage?: string, options?: any): AxiosPromise<number> {
            return MediaApiFp(configuration).countMedias(acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create multiple Medias
         * @param {CreateManyMediaDto} createManyMediaDto 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManyMedia(createManyMediaDto: CreateManyMediaDto, acceptLanguage?: string, options?: any): AxiosPromise<Array<Media>> {
            return MediaApiFp(configuration).createManyMedia(createManyMediaDto, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a single Media
         * @param {Media} media 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneMedia(media: Media, acceptLanguage?: string, options?: any): AxiosPromise<Media> {
            return MediaApiFp(configuration).createOneMedia(media, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete multiple Medias
         * @param {Array<string>} requestBody 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyMedias(requestBody: Array<string>, acceptLanguage?: string, options?: any): AxiosPromise<void> {
            return MediaApiFp(configuration).deleteManyMedias(requestBody, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a single Media
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneMedia(id: number, acceptLanguage?: string, options?: any): AxiosPromise<Media> {
            return MediaApiFp(configuration).deleteOneMedia(id, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple Medias
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyMedia(acceptLanguage?: string, fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<GetManyMediaResponseDto> {
            return MediaApiFp(configuration).getManyMedia(acceptLanguage, fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single Media
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneMedia(id: number, acceptLanguage?: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<Media> {
            return MediaApiFp(configuration).getOneMedia(id, acceptLanguage, fields, join, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Replace a single Media
         * @param {number} id 
         * @param {Media} media 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceOneMedia(id: number, media: Media, acceptLanguage?: string, options?: any): AxiosPromise<Media> {
            return MediaApiFp(configuration).replaceOneMedia(id, media, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update multiple Medias
         * @param {Array<Media>} media 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateManyMedias(media: Array<Media>, acceptLanguage?: string, options?: any): AxiosPromise<Array<Media>> {
            return MediaApiFp(configuration).updateManyMedias(media, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a single Media
         * @param {number} id 
         * @param {Media} media 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneMedia(id: number, media: Media, acceptLanguage?: string, options?: any): AxiosPromise<Media> {
            return MediaApiFp(configuration).updateOneMedia(id, media, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload media file(s)
         * @param {Array<any>} files 
         * @param {string} [acceptLanguage] Request language
         * @param {boolean} [hidden] 
         * @param {Media} [mediaInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadMedia(files: Array<any>, acceptLanguage?: string, hidden?: boolean, mediaInfo?: Media, options?: any): AxiosPromise<Array<Media>> {
            return MediaApiFp(configuration).uploadMedia(files, acceptLanguage, hidden, mediaInfo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for cloneMedia operation in MediaApi.
 * @export
 * @interface MediaApiCloneMediaRequest
 */
export interface MediaApiCloneMediaRequest {
    /**
     * 
     * @type {CloneMediaDTO}
     * @memberof MediaApiCloneMedia
     */
    readonly cloneMediaDTO: CloneMediaDTO

    /**
     * Request language
     * @type {string}
     * @memberof MediaApiCloneMedia
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for countMedias operation in MediaApi.
 * @export
 * @interface MediaApiCountMediasRequest
 */
export interface MediaApiCountMediasRequest {
    /**
     * Request language
     * @type {string}
     * @memberof MediaApiCountMedias
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for createManyMedia operation in MediaApi.
 * @export
 * @interface MediaApiCreateManyMediaRequest
 */
export interface MediaApiCreateManyMediaRequest {
    /**
     * 
     * @type {CreateManyMediaDto}
     * @memberof MediaApiCreateManyMedia
     */
    readonly createManyMediaDto: CreateManyMediaDto

    /**
     * Request language
     * @type {string}
     * @memberof MediaApiCreateManyMedia
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for createOneMedia operation in MediaApi.
 * @export
 * @interface MediaApiCreateOneMediaRequest
 */
export interface MediaApiCreateOneMediaRequest {
    /**
     * 
     * @type {Media}
     * @memberof MediaApiCreateOneMedia
     */
    readonly media: Media

    /**
     * Request language
     * @type {string}
     * @memberof MediaApiCreateOneMedia
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for deleteManyMedias operation in MediaApi.
 * @export
 * @interface MediaApiDeleteManyMediasRequest
 */
export interface MediaApiDeleteManyMediasRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof MediaApiDeleteManyMedias
     */
    readonly requestBody: Array<string>

    /**
     * Request language
     * @type {string}
     * @memberof MediaApiDeleteManyMedias
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for deleteOneMedia operation in MediaApi.
 * @export
 * @interface MediaApiDeleteOneMediaRequest
 */
export interface MediaApiDeleteOneMediaRequest {
    /**
     * 
     * @type {number}
     * @memberof MediaApiDeleteOneMedia
     */
    readonly id: number

    /**
     * Request language
     * @type {string}
     * @memberof MediaApiDeleteOneMedia
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for getManyMedia operation in MediaApi.
 * @export
 * @interface MediaApiGetManyMediaRequest
 */
export interface MediaApiGetManyMediaRequest {
    /**
     * Request language
     * @type {string}
     * @memberof MediaApiGetManyMedia
     */
    readonly acceptLanguage?: string

    /**
     * Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof MediaApiGetManyMedia
     */
    readonly fields?: Array<string>

    /**
     * Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {string}
     * @memberof MediaApiGetManyMedia
     */
    readonly s?: string

    /**
     * Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof MediaApiGetManyMedia
     */
    readonly filter?: Array<string>

    /**
     * Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof MediaApiGetManyMedia
     */
    readonly or?: Array<string>

    /**
     * Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof MediaApiGetManyMedia
     */
    readonly sort?: Array<string>

    /**
     * Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof MediaApiGetManyMedia
     */
    readonly join?: Array<string>

    /**
     * Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof MediaApiGetManyMedia
     */
    readonly limit?: number

    /**
     * Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof MediaApiGetManyMedia
     */
    readonly offset?: number

    /**
     * Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof MediaApiGetManyMedia
     */
    readonly page?: number

    /**
     * Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof MediaApiGetManyMedia
     */
    readonly cache?: number
}

/**
 * Request parameters for getOneMedia operation in MediaApi.
 * @export
 * @interface MediaApiGetOneMediaRequest
 */
export interface MediaApiGetOneMediaRequest {
    /**
     * 
     * @type {number}
     * @memberof MediaApiGetOneMedia
     */
    readonly id: number

    /**
     * Request language
     * @type {string}
     * @memberof MediaApiGetOneMedia
     */
    readonly acceptLanguage?: string

    /**
     * Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof MediaApiGetOneMedia
     */
    readonly fields?: Array<string>

    /**
     * Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof MediaApiGetOneMedia
     */
    readonly join?: Array<string>

    /**
     * Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof MediaApiGetOneMedia
     */
    readonly cache?: number
}

/**
 * Request parameters for replaceOneMedia operation in MediaApi.
 * @export
 * @interface MediaApiReplaceOneMediaRequest
 */
export interface MediaApiReplaceOneMediaRequest {
    /**
     * 
     * @type {number}
     * @memberof MediaApiReplaceOneMedia
     */
    readonly id: number

    /**
     * 
     * @type {Media}
     * @memberof MediaApiReplaceOneMedia
     */
    readonly media: Media

    /**
     * Request language
     * @type {string}
     * @memberof MediaApiReplaceOneMedia
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for updateManyMedias operation in MediaApi.
 * @export
 * @interface MediaApiUpdateManyMediasRequest
 */
export interface MediaApiUpdateManyMediasRequest {
    /**
     * 
     * @type {Array<Media>}
     * @memberof MediaApiUpdateManyMedias
     */
    readonly media: Array<Media>

    /**
     * Request language
     * @type {string}
     * @memberof MediaApiUpdateManyMedias
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for updateOneMedia operation in MediaApi.
 * @export
 * @interface MediaApiUpdateOneMediaRequest
 */
export interface MediaApiUpdateOneMediaRequest {
    /**
     * 
     * @type {number}
     * @memberof MediaApiUpdateOneMedia
     */
    readonly id: number

    /**
     * 
     * @type {Media}
     * @memberof MediaApiUpdateOneMedia
     */
    readonly media: Media

    /**
     * Request language
     * @type {string}
     * @memberof MediaApiUpdateOneMedia
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for uploadMedia operation in MediaApi.
 * @export
 * @interface MediaApiUploadMediaRequest
 */
export interface MediaApiUploadMediaRequest {
    /**
     * 
     * @type {Array<any>}
     * @memberof MediaApiUploadMedia
     */
    readonly files: Array<any>

    /**
     * Request language
     * @type {string}
     * @memberof MediaApiUploadMedia
     */
    readonly acceptLanguage?: string

    /**
     * 
     * @type {boolean}
     * @memberof MediaApiUploadMedia
     */
    readonly hidden?: boolean

    /**
     * 
     * @type {Media}
     * @memberof MediaApiUploadMedia
     */
    readonly mediaInfo?: Media
}

/**
 * MediaApi - object-oriented interface
 * @export
 * @class MediaApi
 * @extends {BaseAPI}
 */
export class MediaApi extends BaseAPI {
    /**
     * 
     * @summary Clone media file(s)
     * @param {MediaApiCloneMediaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public cloneMedia(requestParameters: MediaApiCloneMediaRequest, options?: any) {
        return MediaApiFp(this.configuration).cloneMedia(requestParameters.cloneMediaDTO, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Medias total
     * @param {MediaApiCountMediasRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public countMedias(requestParameters: MediaApiCountMediasRequest = {}, options?: any) {
        return MediaApiFp(this.configuration).countMedias(requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create multiple Medias
     * @param {MediaApiCreateManyMediaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public createManyMedia(requestParameters: MediaApiCreateManyMediaRequest, options?: any) {
        return MediaApiFp(this.configuration).createManyMedia(requestParameters.createManyMediaDto, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a single Media
     * @param {MediaApiCreateOneMediaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public createOneMedia(requestParameters: MediaApiCreateOneMediaRequest, options?: any) {
        return MediaApiFp(this.configuration).createOneMedia(requestParameters.media, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete multiple Medias
     * @param {MediaApiDeleteManyMediasRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public deleteManyMedias(requestParameters: MediaApiDeleteManyMediasRequest, options?: any) {
        return MediaApiFp(this.configuration).deleteManyMedias(requestParameters.requestBody, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a single Media
     * @param {MediaApiDeleteOneMediaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public deleteOneMedia(requestParameters: MediaApiDeleteOneMediaRequest, options?: any) {
        return MediaApiFp(this.configuration).deleteOneMedia(requestParameters.id, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple Medias
     * @param {MediaApiGetManyMediaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public getManyMedia(requestParameters: MediaApiGetManyMediaRequest = {}, options?: any) {
        return MediaApiFp(this.configuration).getManyMedia(requestParameters.acceptLanguage, requestParameters.fields, requestParameters.s, requestParameters.filter, requestParameters.or, requestParameters.sort, requestParameters.join, requestParameters.limit, requestParameters.offset, requestParameters.page, requestParameters.cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single Media
     * @param {MediaApiGetOneMediaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public getOneMedia(requestParameters: MediaApiGetOneMediaRequest, options?: any) {
        return MediaApiFp(this.configuration).getOneMedia(requestParameters.id, requestParameters.acceptLanguage, requestParameters.fields, requestParameters.join, requestParameters.cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Replace a single Media
     * @param {MediaApiReplaceOneMediaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public replaceOneMedia(requestParameters: MediaApiReplaceOneMediaRequest, options?: any) {
        return MediaApiFp(this.configuration).replaceOneMedia(requestParameters.id, requestParameters.media, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update multiple Medias
     * @param {MediaApiUpdateManyMediasRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public updateManyMedias(requestParameters: MediaApiUpdateManyMediasRequest, options?: any) {
        return MediaApiFp(this.configuration).updateManyMedias(requestParameters.media, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a single Media
     * @param {MediaApiUpdateOneMediaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public updateOneMedia(requestParameters: MediaApiUpdateOneMediaRequest, options?: any) {
        return MediaApiFp(this.configuration).updateOneMedia(requestParameters.id, requestParameters.media, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload media file(s)
     * @param {MediaApiUploadMediaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public uploadMedia(requestParameters: MediaApiUploadMediaRequest, options?: any) {
        return MediaApiFp(this.configuration).uploadMedia(requestParameters.files, requestParameters.acceptLanguage, requestParameters.hidden, requestParameters.mediaInfo, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MediaPathApi - axios parameter creator
 * @export
 */
export const MediaPathApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Clone media path(s)
         * @param {CloneMediaPathDTO} cloneMediaPathDTO 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneMediaPaths: async (cloneMediaPathDTO: CloneMediaPathDTO, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cloneMediaPathDTO' is not null or undefined
            if (cloneMediaPathDTO === null || cloneMediaPathDTO === undefined) {
                throw new RequiredError('cloneMediaPathDTO','Required parameter cloneMediaPathDTO was null or undefined when calling cloneMediaPaths.');
            }
            const localVarPath = `/mediaPaths/clone`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof cloneMediaPathDTO !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(cloneMediaPathDTO !== undefined ? cloneMediaPathDTO : {})
                : (cloneMediaPathDTO || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get MediaPaths total
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countMediaPaths: async (acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/mediaPaths/total/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create multiple MediaPaths
         * @param {CreateManyMediaPathDto} createManyMediaPathDto 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManyMediaPath: async (createManyMediaPathDto: CreateManyMediaPathDto, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createManyMediaPathDto' is not null or undefined
            if (createManyMediaPathDto === null || createManyMediaPathDto === undefined) {
                throw new RequiredError('createManyMediaPathDto','Required parameter createManyMediaPathDto was null or undefined when calling createManyMediaPath.');
            }
            const localVarPath = `/mediaPaths/bulk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createManyMediaPathDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createManyMediaPathDto !== undefined ? createManyMediaPathDto : {})
                : (createManyMediaPathDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a single MediaPath
         * @param {MediaPath} mediaPath 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneMediaPath: async (mediaPath: MediaPath, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mediaPath' is not null or undefined
            if (mediaPath === null || mediaPath === undefined) {
                throw new RequiredError('mediaPath','Required parameter mediaPath was null or undefined when calling createOneMediaPath.');
            }
            const localVarPath = `/mediaPaths`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof mediaPath !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(mediaPath !== undefined ? mediaPath : {})
                : (mediaPath || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete multiple MediaPaths
         * @param {Array<string>} requestBody 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyMediaPaths: async (requestBody: Array<string>, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            if (requestBody === null || requestBody === undefined) {
                throw new RequiredError('requestBody','Required parameter requestBody was null or undefined when calling deleteManyMediaPaths.');
            }
            const localVarPath = `/mediaPaths/bulk/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof requestBody !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(requestBody !== undefined ? requestBody : {})
                : (requestBody || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a single MediaPath
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneMediaPath: async (id: number, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteOneMediaPath.');
            }
            const localVarPath = `/mediaPaths/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Media Path\'s full path in Tree (ids array)
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMPath: async (id: number, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getMPath.');
            }
            const localVarPath = `/mediaPaths/path/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple MediaPaths
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyMediaPath: async (acceptLanguage?: string, fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/mediaPaths`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Media Paths ancestors
         * @param {MediaPath} mediaPath 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMediaPathAncestors: async (mediaPath: MediaPath, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mediaPath' is not null or undefined
            if (mediaPath === null || mediaPath === undefined) {
                throw new RequiredError('mediaPath','Required parameter mediaPath was null or undefined when calling getMediaPathAncestors.');
            }
            const localVarPath = `/mediaPaths/ancestors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof mediaPath !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(mediaPath !== undefined ? mediaPath : {})
                : (mediaPath || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Media Paths descendants
         * @param {MediaPath} mediaPath 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMediaPathDescendants: async (mediaPath: MediaPath, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mediaPath' is not null or undefined
            if (mediaPath === null || mediaPath === undefined) {
                throw new RequiredError('mediaPath','Required parameter mediaPath was null or undefined when calling getMediaPathDescendants.');
            }
            const localVarPath = `/mediaPaths/descendants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof mediaPath !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(mediaPath !== undefined ? mediaPath : {})
                : (mediaPath || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Media Paths Tree
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMediaPathsTree: async (acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/mediaPaths/tree`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single MediaPath
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneMediaPath: async (id: number, acceptLanguage?: string, fields?: Array<string>, join?: Array<string>, cache?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOneMediaPath.');
            }
            const localVarPath = `/mediaPaths/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Replace a single MediaPath
         * @param {number} id 
         * @param {MediaPath} mediaPath 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceOneMediaPath: async (id: number, mediaPath: MediaPath, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling replaceOneMediaPath.');
            }
            // verify required parameter 'mediaPath' is not null or undefined
            if (mediaPath === null || mediaPath === undefined) {
                throw new RequiredError('mediaPath','Required parameter mediaPath was null or undefined when calling replaceOneMediaPath.');
            }
            const localVarPath = `/mediaPaths/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof mediaPath !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(mediaPath !== undefined ? mediaPath : {})
                : (mediaPath || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update multiple MediaPaths
         * @param {Array<MediaPath>} mediaPath 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateManyMediaPaths: async (mediaPath: Array<MediaPath>, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mediaPath' is not null or undefined
            if (mediaPath === null || mediaPath === undefined) {
                throw new RequiredError('mediaPath','Required parameter mediaPath was null or undefined when calling updateManyMediaPaths.');
            }
            const localVarPath = `/mediaPaths/bulk/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof mediaPath !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(mediaPath !== undefined ? mediaPath : {})
                : (mediaPath || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a single MediaPath
         * @param {number} id 
         * @param {MediaPath} mediaPath 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneMediaPath: async (id: number, mediaPath: MediaPath, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateOneMediaPath.');
            }
            // verify required parameter 'mediaPath' is not null or undefined
            if (mediaPath === null || mediaPath === undefined) {
                throw new RequiredError('mediaPath','Required parameter mediaPath was null or undefined when calling updateOneMediaPath.');
            }
            const localVarPath = `/mediaPaths/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof mediaPath !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(mediaPath !== undefined ? mediaPath : {})
                : (mediaPath || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MediaPathApi - functional programming interface
 * @export
 */
export const MediaPathApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Clone media path(s)
         * @param {CloneMediaPathDTO} cloneMediaPathDTO 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cloneMediaPaths(cloneMediaPathDTO: CloneMediaPathDTO, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MediaPath>>> {
            const localVarAxiosArgs = await MediaPathApiAxiosParamCreator(configuration).cloneMediaPaths(cloneMediaPathDTO, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get MediaPaths total
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countMediaPaths(acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await MediaPathApiAxiosParamCreator(configuration).countMediaPaths(acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create multiple MediaPaths
         * @param {CreateManyMediaPathDto} createManyMediaPathDto 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createManyMediaPath(createManyMediaPathDto: CreateManyMediaPathDto, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MediaPath>>> {
            const localVarAxiosArgs = await MediaPathApiAxiosParamCreator(configuration).createManyMediaPath(createManyMediaPathDto, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create a single MediaPath
         * @param {MediaPath} mediaPath 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOneMediaPath(mediaPath: MediaPath, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MediaPath>> {
            const localVarAxiosArgs = await MediaPathApiAxiosParamCreator(configuration).createOneMediaPath(mediaPath, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete multiple MediaPaths
         * @param {Array<string>} requestBody 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteManyMediaPaths(requestBody: Array<string>, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MediaPathApiAxiosParamCreator(configuration).deleteManyMediaPaths(requestBody, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete a single MediaPath
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOneMediaPath(id: number, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MediaPath>> {
            const localVarAxiosArgs = await MediaPathApiAxiosParamCreator(configuration).deleteOneMediaPath(id, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get Media Path\'s full path in Tree (ids array)
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMPath(id: number, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await MediaPathApiAxiosParamCreator(configuration).getMPath(id, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieve multiple MediaPaths
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyMediaPath(acceptLanguage?: string, fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyMediaPathResponseDto>> {
            const localVarAxiosArgs = await MediaPathApiAxiosParamCreator(configuration).getManyMediaPath(acceptLanguage, fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get Media Paths ancestors
         * @param {MediaPath} mediaPath 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMediaPathAncestors(mediaPath: MediaPath, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MediaPath>>> {
            const localVarAxiosArgs = await MediaPathApiAxiosParamCreator(configuration).getMediaPathAncestors(mediaPath, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get Media Paths descendants
         * @param {MediaPath} mediaPath 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMediaPathDescendants(mediaPath: MediaPath, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MediaPath>>> {
            const localVarAxiosArgs = await MediaPathApiAxiosParamCreator(configuration).getMediaPathDescendants(mediaPath, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get Media Paths Tree
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMediaPathsTree(acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MediaPathTreeDto>>> {
            const localVarAxiosArgs = await MediaPathApiAxiosParamCreator(configuration).getMediaPathsTree(acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieve a single MediaPath
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneMediaPath(id: number, acceptLanguage?: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MediaPath>> {
            const localVarAxiosArgs = await MediaPathApiAxiosParamCreator(configuration).getOneMediaPath(id, acceptLanguage, fields, join, cache, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Replace a single MediaPath
         * @param {number} id 
         * @param {MediaPath} mediaPath 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceOneMediaPath(id: number, mediaPath: MediaPath, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MediaPath>> {
            const localVarAxiosArgs = await MediaPathApiAxiosParamCreator(configuration).replaceOneMediaPath(id, mediaPath, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update multiple MediaPaths
         * @param {Array<MediaPath>} mediaPath 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateManyMediaPaths(mediaPath: Array<MediaPath>, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MediaPath>>> {
            const localVarAxiosArgs = await MediaPathApiAxiosParamCreator(configuration).updateManyMediaPaths(mediaPath, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update a single MediaPath
         * @param {number} id 
         * @param {MediaPath} mediaPath 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOneMediaPath(id: number, mediaPath: MediaPath, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MediaPath>> {
            const localVarAxiosArgs = await MediaPathApiAxiosParamCreator(configuration).updateOneMediaPath(id, mediaPath, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * MediaPathApi - factory interface
 * @export
 */
export const MediaPathApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Clone media path(s)
         * @param {CloneMediaPathDTO} cloneMediaPathDTO 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneMediaPaths(cloneMediaPathDTO: CloneMediaPathDTO, acceptLanguage?: string, options?: any): AxiosPromise<Array<MediaPath>> {
            return MediaPathApiFp(configuration).cloneMediaPaths(cloneMediaPathDTO, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get MediaPaths total
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countMediaPaths(acceptLanguage?: string, options?: any): AxiosPromise<number> {
            return MediaPathApiFp(configuration).countMediaPaths(acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create multiple MediaPaths
         * @param {CreateManyMediaPathDto} createManyMediaPathDto 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManyMediaPath(createManyMediaPathDto: CreateManyMediaPathDto, acceptLanguage?: string, options?: any): AxiosPromise<Array<MediaPath>> {
            return MediaPathApiFp(configuration).createManyMediaPath(createManyMediaPathDto, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a single MediaPath
         * @param {MediaPath} mediaPath 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneMediaPath(mediaPath: MediaPath, acceptLanguage?: string, options?: any): AxiosPromise<MediaPath> {
            return MediaPathApiFp(configuration).createOneMediaPath(mediaPath, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete multiple MediaPaths
         * @param {Array<string>} requestBody 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyMediaPaths(requestBody: Array<string>, acceptLanguage?: string, options?: any): AxiosPromise<void> {
            return MediaPathApiFp(configuration).deleteManyMediaPaths(requestBody, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a single MediaPath
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneMediaPath(id: number, acceptLanguage?: string, options?: any): AxiosPromise<MediaPath> {
            return MediaPathApiFp(configuration).deleteOneMediaPath(id, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Media Path\'s full path in Tree (ids array)
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMPath(id: number, acceptLanguage?: string, options?: any): AxiosPromise<Array<string>> {
            return MediaPathApiFp(configuration).getMPath(id, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple MediaPaths
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyMediaPath(acceptLanguage?: string, fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<GetManyMediaPathResponseDto> {
            return MediaPathApiFp(configuration).getManyMediaPath(acceptLanguage, fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Media Paths ancestors
         * @param {MediaPath} mediaPath 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMediaPathAncestors(mediaPath: MediaPath, acceptLanguage?: string, options?: any): AxiosPromise<Array<MediaPath>> {
            return MediaPathApiFp(configuration).getMediaPathAncestors(mediaPath, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Media Paths descendants
         * @param {MediaPath} mediaPath 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMediaPathDescendants(mediaPath: MediaPath, acceptLanguage?: string, options?: any): AxiosPromise<Array<MediaPath>> {
            return MediaPathApiFp(configuration).getMediaPathDescendants(mediaPath, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Media Paths Tree
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMediaPathsTree(acceptLanguage?: string, options?: any): AxiosPromise<Array<MediaPathTreeDto>> {
            return MediaPathApiFp(configuration).getMediaPathsTree(acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single MediaPath
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneMediaPath(id: number, acceptLanguage?: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<MediaPath> {
            return MediaPathApiFp(configuration).getOneMediaPath(id, acceptLanguage, fields, join, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Replace a single MediaPath
         * @param {number} id 
         * @param {MediaPath} mediaPath 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceOneMediaPath(id: number, mediaPath: MediaPath, acceptLanguage?: string, options?: any): AxiosPromise<MediaPath> {
            return MediaPathApiFp(configuration).replaceOneMediaPath(id, mediaPath, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update multiple MediaPaths
         * @param {Array<MediaPath>} mediaPath 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateManyMediaPaths(mediaPath: Array<MediaPath>, acceptLanguage?: string, options?: any): AxiosPromise<Array<MediaPath>> {
            return MediaPathApiFp(configuration).updateManyMediaPaths(mediaPath, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a single MediaPath
         * @param {number} id 
         * @param {MediaPath} mediaPath 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneMediaPath(id: number, mediaPath: MediaPath, acceptLanguage?: string, options?: any): AxiosPromise<MediaPath> {
            return MediaPathApiFp(configuration).updateOneMediaPath(id, mediaPath, acceptLanguage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for cloneMediaPaths operation in MediaPathApi.
 * @export
 * @interface MediaPathApiCloneMediaPathsRequest
 */
export interface MediaPathApiCloneMediaPathsRequest {
    /**
     * 
     * @type {CloneMediaPathDTO}
     * @memberof MediaPathApiCloneMediaPaths
     */
    readonly cloneMediaPathDTO: CloneMediaPathDTO

    /**
     * Request language
     * @type {string}
     * @memberof MediaPathApiCloneMediaPaths
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for countMediaPaths operation in MediaPathApi.
 * @export
 * @interface MediaPathApiCountMediaPathsRequest
 */
export interface MediaPathApiCountMediaPathsRequest {
    /**
     * Request language
     * @type {string}
     * @memberof MediaPathApiCountMediaPaths
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for createManyMediaPath operation in MediaPathApi.
 * @export
 * @interface MediaPathApiCreateManyMediaPathRequest
 */
export interface MediaPathApiCreateManyMediaPathRequest {
    /**
     * 
     * @type {CreateManyMediaPathDto}
     * @memberof MediaPathApiCreateManyMediaPath
     */
    readonly createManyMediaPathDto: CreateManyMediaPathDto

    /**
     * Request language
     * @type {string}
     * @memberof MediaPathApiCreateManyMediaPath
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for createOneMediaPath operation in MediaPathApi.
 * @export
 * @interface MediaPathApiCreateOneMediaPathRequest
 */
export interface MediaPathApiCreateOneMediaPathRequest {
    /**
     * 
     * @type {MediaPath}
     * @memberof MediaPathApiCreateOneMediaPath
     */
    readonly mediaPath: MediaPath

    /**
     * Request language
     * @type {string}
     * @memberof MediaPathApiCreateOneMediaPath
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for deleteManyMediaPaths operation in MediaPathApi.
 * @export
 * @interface MediaPathApiDeleteManyMediaPathsRequest
 */
export interface MediaPathApiDeleteManyMediaPathsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof MediaPathApiDeleteManyMediaPaths
     */
    readonly requestBody: Array<string>

    /**
     * Request language
     * @type {string}
     * @memberof MediaPathApiDeleteManyMediaPaths
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for deleteOneMediaPath operation in MediaPathApi.
 * @export
 * @interface MediaPathApiDeleteOneMediaPathRequest
 */
export interface MediaPathApiDeleteOneMediaPathRequest {
    /**
     * 
     * @type {number}
     * @memberof MediaPathApiDeleteOneMediaPath
     */
    readonly id: number

    /**
     * Request language
     * @type {string}
     * @memberof MediaPathApiDeleteOneMediaPath
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for getMPath operation in MediaPathApi.
 * @export
 * @interface MediaPathApiGetMPathRequest
 */
export interface MediaPathApiGetMPathRequest {
    /**
     * 
     * @type {number}
     * @memberof MediaPathApiGetMPath
     */
    readonly id: number

    /**
     * Request language
     * @type {string}
     * @memberof MediaPathApiGetMPath
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for getManyMediaPath operation in MediaPathApi.
 * @export
 * @interface MediaPathApiGetManyMediaPathRequest
 */
export interface MediaPathApiGetManyMediaPathRequest {
    /**
     * Request language
     * @type {string}
     * @memberof MediaPathApiGetManyMediaPath
     */
    readonly acceptLanguage?: string

    /**
     * Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof MediaPathApiGetManyMediaPath
     */
    readonly fields?: Array<string>

    /**
     * Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {string}
     * @memberof MediaPathApiGetManyMediaPath
     */
    readonly s?: string

    /**
     * Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof MediaPathApiGetManyMediaPath
     */
    readonly filter?: Array<string>

    /**
     * Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof MediaPathApiGetManyMediaPath
     */
    readonly or?: Array<string>

    /**
     * Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof MediaPathApiGetManyMediaPath
     */
    readonly sort?: Array<string>

    /**
     * Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof MediaPathApiGetManyMediaPath
     */
    readonly join?: Array<string>

    /**
     * Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof MediaPathApiGetManyMediaPath
     */
    readonly limit?: number

    /**
     * Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof MediaPathApiGetManyMediaPath
     */
    readonly offset?: number

    /**
     * Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof MediaPathApiGetManyMediaPath
     */
    readonly page?: number

    /**
     * Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof MediaPathApiGetManyMediaPath
     */
    readonly cache?: number
}

/**
 * Request parameters for getMediaPathAncestors operation in MediaPathApi.
 * @export
 * @interface MediaPathApiGetMediaPathAncestorsRequest
 */
export interface MediaPathApiGetMediaPathAncestorsRequest {
    /**
     * 
     * @type {MediaPath}
     * @memberof MediaPathApiGetMediaPathAncestors
     */
    readonly mediaPath: MediaPath

    /**
     * Request language
     * @type {string}
     * @memberof MediaPathApiGetMediaPathAncestors
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for getMediaPathDescendants operation in MediaPathApi.
 * @export
 * @interface MediaPathApiGetMediaPathDescendantsRequest
 */
export interface MediaPathApiGetMediaPathDescendantsRequest {
    /**
     * 
     * @type {MediaPath}
     * @memberof MediaPathApiGetMediaPathDescendants
     */
    readonly mediaPath: MediaPath

    /**
     * Request language
     * @type {string}
     * @memberof MediaPathApiGetMediaPathDescendants
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for getMediaPathsTree operation in MediaPathApi.
 * @export
 * @interface MediaPathApiGetMediaPathsTreeRequest
 */
export interface MediaPathApiGetMediaPathsTreeRequest {
    /**
     * Request language
     * @type {string}
     * @memberof MediaPathApiGetMediaPathsTree
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for getOneMediaPath operation in MediaPathApi.
 * @export
 * @interface MediaPathApiGetOneMediaPathRequest
 */
export interface MediaPathApiGetOneMediaPathRequest {
    /**
     * 
     * @type {number}
     * @memberof MediaPathApiGetOneMediaPath
     */
    readonly id: number

    /**
     * Request language
     * @type {string}
     * @memberof MediaPathApiGetOneMediaPath
     */
    readonly acceptLanguage?: string

    /**
     * Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof MediaPathApiGetOneMediaPath
     */
    readonly fields?: Array<string>

    /**
     * Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof MediaPathApiGetOneMediaPath
     */
    readonly join?: Array<string>

    /**
     * Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof MediaPathApiGetOneMediaPath
     */
    readonly cache?: number
}

/**
 * Request parameters for replaceOneMediaPath operation in MediaPathApi.
 * @export
 * @interface MediaPathApiReplaceOneMediaPathRequest
 */
export interface MediaPathApiReplaceOneMediaPathRequest {
    /**
     * 
     * @type {number}
     * @memberof MediaPathApiReplaceOneMediaPath
     */
    readonly id: number

    /**
     * 
     * @type {MediaPath}
     * @memberof MediaPathApiReplaceOneMediaPath
     */
    readonly mediaPath: MediaPath

    /**
     * Request language
     * @type {string}
     * @memberof MediaPathApiReplaceOneMediaPath
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for updateManyMediaPaths operation in MediaPathApi.
 * @export
 * @interface MediaPathApiUpdateManyMediaPathsRequest
 */
export interface MediaPathApiUpdateManyMediaPathsRequest {
    /**
     * 
     * @type {Array<MediaPath>}
     * @memberof MediaPathApiUpdateManyMediaPaths
     */
    readonly mediaPath: Array<MediaPath>

    /**
     * Request language
     * @type {string}
     * @memberof MediaPathApiUpdateManyMediaPaths
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for updateOneMediaPath operation in MediaPathApi.
 * @export
 * @interface MediaPathApiUpdateOneMediaPathRequest
 */
export interface MediaPathApiUpdateOneMediaPathRequest {
    /**
     * 
     * @type {number}
     * @memberof MediaPathApiUpdateOneMediaPath
     */
    readonly id: number

    /**
     * 
     * @type {MediaPath}
     * @memberof MediaPathApiUpdateOneMediaPath
     */
    readonly mediaPath: MediaPath

    /**
     * Request language
     * @type {string}
     * @memberof MediaPathApiUpdateOneMediaPath
     */
    readonly acceptLanguage?: string
}

/**
 * MediaPathApi - object-oriented interface
 * @export
 * @class MediaPathApi
 * @extends {BaseAPI}
 */
export class MediaPathApi extends BaseAPI {
    /**
     * 
     * @summary Clone media path(s)
     * @param {MediaPathApiCloneMediaPathsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaPathApi
     */
    public cloneMediaPaths(requestParameters: MediaPathApiCloneMediaPathsRequest, options?: any) {
        return MediaPathApiFp(this.configuration).cloneMediaPaths(requestParameters.cloneMediaPathDTO, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get MediaPaths total
     * @param {MediaPathApiCountMediaPathsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaPathApi
     */
    public countMediaPaths(requestParameters: MediaPathApiCountMediaPathsRequest = {}, options?: any) {
        return MediaPathApiFp(this.configuration).countMediaPaths(requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create multiple MediaPaths
     * @param {MediaPathApiCreateManyMediaPathRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaPathApi
     */
    public createManyMediaPath(requestParameters: MediaPathApiCreateManyMediaPathRequest, options?: any) {
        return MediaPathApiFp(this.configuration).createManyMediaPath(requestParameters.createManyMediaPathDto, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a single MediaPath
     * @param {MediaPathApiCreateOneMediaPathRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaPathApi
     */
    public createOneMediaPath(requestParameters: MediaPathApiCreateOneMediaPathRequest, options?: any) {
        return MediaPathApiFp(this.configuration).createOneMediaPath(requestParameters.mediaPath, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete multiple MediaPaths
     * @param {MediaPathApiDeleteManyMediaPathsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaPathApi
     */
    public deleteManyMediaPaths(requestParameters: MediaPathApiDeleteManyMediaPathsRequest, options?: any) {
        return MediaPathApiFp(this.configuration).deleteManyMediaPaths(requestParameters.requestBody, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a single MediaPath
     * @param {MediaPathApiDeleteOneMediaPathRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaPathApi
     */
    public deleteOneMediaPath(requestParameters: MediaPathApiDeleteOneMediaPathRequest, options?: any) {
        return MediaPathApiFp(this.configuration).deleteOneMediaPath(requestParameters.id, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Media Path\'s full path in Tree (ids array)
     * @param {MediaPathApiGetMPathRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaPathApi
     */
    public getMPath(requestParameters: MediaPathApiGetMPathRequest, options?: any) {
        return MediaPathApiFp(this.configuration).getMPath(requestParameters.id, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple MediaPaths
     * @param {MediaPathApiGetManyMediaPathRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaPathApi
     */
    public getManyMediaPath(requestParameters: MediaPathApiGetManyMediaPathRequest = {}, options?: any) {
        return MediaPathApiFp(this.configuration).getManyMediaPath(requestParameters.acceptLanguage, requestParameters.fields, requestParameters.s, requestParameters.filter, requestParameters.or, requestParameters.sort, requestParameters.join, requestParameters.limit, requestParameters.offset, requestParameters.page, requestParameters.cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Media Paths ancestors
     * @param {MediaPathApiGetMediaPathAncestorsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaPathApi
     */
    public getMediaPathAncestors(requestParameters: MediaPathApiGetMediaPathAncestorsRequest, options?: any) {
        return MediaPathApiFp(this.configuration).getMediaPathAncestors(requestParameters.mediaPath, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Media Paths descendants
     * @param {MediaPathApiGetMediaPathDescendantsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaPathApi
     */
    public getMediaPathDescendants(requestParameters: MediaPathApiGetMediaPathDescendantsRequest, options?: any) {
        return MediaPathApiFp(this.configuration).getMediaPathDescendants(requestParameters.mediaPath, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Media Paths Tree
     * @param {MediaPathApiGetMediaPathsTreeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaPathApi
     */
    public getMediaPathsTree(requestParameters: MediaPathApiGetMediaPathsTreeRequest = {}, options?: any) {
        return MediaPathApiFp(this.configuration).getMediaPathsTree(requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single MediaPath
     * @param {MediaPathApiGetOneMediaPathRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaPathApi
     */
    public getOneMediaPath(requestParameters: MediaPathApiGetOneMediaPathRequest, options?: any) {
        return MediaPathApiFp(this.configuration).getOneMediaPath(requestParameters.id, requestParameters.acceptLanguage, requestParameters.fields, requestParameters.join, requestParameters.cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Replace a single MediaPath
     * @param {MediaPathApiReplaceOneMediaPathRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaPathApi
     */
    public replaceOneMediaPath(requestParameters: MediaPathApiReplaceOneMediaPathRequest, options?: any) {
        return MediaPathApiFp(this.configuration).replaceOneMediaPath(requestParameters.id, requestParameters.mediaPath, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update multiple MediaPaths
     * @param {MediaPathApiUpdateManyMediaPathsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaPathApi
     */
    public updateManyMediaPaths(requestParameters: MediaPathApiUpdateManyMediaPathsRequest, options?: any) {
        return MediaPathApiFp(this.configuration).updateManyMediaPaths(requestParameters.mediaPath, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a single MediaPath
     * @param {MediaPathApiUpdateOneMediaPathRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaPathApi
     */
    public updateOneMediaPath(requestParameters: MediaPathApiUpdateOneMediaPathRequest, options?: any) {
        return MediaPathApiFp(this.configuration).updateOneMediaPath(requestParameters.id, requestParameters.mediaPath, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OwnerApi - axios parameter creator
 * @export
 */
export const OwnerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Owners total
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countOwners: async (acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/owners/total/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create multiple Owners
         * @param {CreateManyOwnerDto} createManyOwnerDto 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManyOwner: async (createManyOwnerDto: CreateManyOwnerDto, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createManyOwnerDto' is not null or undefined
            if (createManyOwnerDto === null || createManyOwnerDto === undefined) {
                throw new RequiredError('createManyOwnerDto','Required parameter createManyOwnerDto was null or undefined when calling createManyOwner.');
            }
            const localVarPath = `/owners/bulk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createManyOwnerDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createManyOwnerDto !== undefined ? createManyOwnerDto : {})
                : (createManyOwnerDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a single Owner
         * @param {Owner} owner 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneOwner: async (owner: Owner, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'owner' is not null or undefined
            if (owner === null || owner === undefined) {
                throw new RequiredError('owner','Required parameter owner was null or undefined when calling createOneOwner.');
            }
            const localVarPath = `/owners`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof owner !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(owner !== undefined ? owner : {})
                : (owner || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete multiple Owners
         * @param {Array<string>} requestBody 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyOwners: async (requestBody: Array<string>, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            if (requestBody === null || requestBody === undefined) {
                throw new RequiredError('requestBody','Required parameter requestBody was null or undefined when calling deleteManyOwners.');
            }
            const localVarPath = `/owners/bulk/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof requestBody !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(requestBody !== undefined ? requestBody : {})
                : (requestBody || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a single Owner
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneOwner: async (id: number, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteOneOwner.');
            }
            const localVarPath = `/owners/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple Owners
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyOwner: async (acceptLanguage?: string, fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/owners`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single Owner
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneOwner: async (id: number, acceptLanguage?: string, fields?: Array<string>, join?: Array<string>, cache?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOneOwner.');
            }
            const localVarPath = `/owners/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Replace a single Owner
         * @param {number} id 
         * @param {Owner} owner 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceOneOwner: async (id: number, owner: Owner, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling replaceOneOwner.');
            }
            // verify required parameter 'owner' is not null or undefined
            if (owner === null || owner === undefined) {
                throw new RequiredError('owner','Required parameter owner was null or undefined when calling replaceOneOwner.');
            }
            const localVarPath = `/owners/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof owner !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(owner !== undefined ? owner : {})
                : (owner || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update multiple Owners
         * @param {Array<Owner>} owner 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateManyOwners: async (owner: Array<Owner>, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'owner' is not null or undefined
            if (owner === null || owner === undefined) {
                throw new RequiredError('owner','Required parameter owner was null or undefined when calling updateManyOwners.');
            }
            const localVarPath = `/owners/bulk/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof owner !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(owner !== undefined ? owner : {})
                : (owner || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a single Owner
         * @param {number} id 
         * @param {Owner} owner 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneOwner: async (id: number, owner: Owner, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateOneOwner.');
            }
            // verify required parameter 'owner' is not null or undefined
            if (owner === null || owner === undefined) {
                throw new RequiredError('owner','Required parameter owner was null or undefined when calling updateOneOwner.');
            }
            const localVarPath = `/owners/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof owner !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(owner !== undefined ? owner : {})
                : (owner || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OwnerApi - functional programming interface
 * @export
 */
export const OwnerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Owners total
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countOwners(acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await OwnerApiAxiosParamCreator(configuration).countOwners(acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create multiple Owners
         * @param {CreateManyOwnerDto} createManyOwnerDto 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createManyOwner(createManyOwnerDto: CreateManyOwnerDto, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Owner>>> {
            const localVarAxiosArgs = await OwnerApiAxiosParamCreator(configuration).createManyOwner(createManyOwnerDto, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create a single Owner
         * @param {Owner} owner 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOneOwner(owner: Owner, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Owner>> {
            const localVarAxiosArgs = await OwnerApiAxiosParamCreator(configuration).createOneOwner(owner, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete multiple Owners
         * @param {Array<string>} requestBody 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteManyOwners(requestBody: Array<string>, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OwnerApiAxiosParamCreator(configuration).deleteManyOwners(requestBody, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete a single Owner
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOneOwner(id: number, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Owner>> {
            const localVarAxiosArgs = await OwnerApiAxiosParamCreator(configuration).deleteOneOwner(id, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieve multiple Owners
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyOwner(acceptLanguage?: string, fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyOwnerResponseDto>> {
            const localVarAxiosArgs = await OwnerApiAxiosParamCreator(configuration).getManyOwner(acceptLanguage, fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieve a single Owner
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneOwner(id: number, acceptLanguage?: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Owner>> {
            const localVarAxiosArgs = await OwnerApiAxiosParamCreator(configuration).getOneOwner(id, acceptLanguage, fields, join, cache, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Replace a single Owner
         * @param {number} id 
         * @param {Owner} owner 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceOneOwner(id: number, owner: Owner, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Owner>> {
            const localVarAxiosArgs = await OwnerApiAxiosParamCreator(configuration).replaceOneOwner(id, owner, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update multiple Owners
         * @param {Array<Owner>} owner 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateManyOwners(owner: Array<Owner>, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Owner>>> {
            const localVarAxiosArgs = await OwnerApiAxiosParamCreator(configuration).updateManyOwners(owner, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update a single Owner
         * @param {number} id 
         * @param {Owner} owner 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOneOwner(id: number, owner: Owner, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Owner>> {
            const localVarAxiosArgs = await OwnerApiAxiosParamCreator(configuration).updateOneOwner(id, owner, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OwnerApi - factory interface
 * @export
 */
export const OwnerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get Owners total
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countOwners(acceptLanguage?: string, options?: any): AxiosPromise<number> {
            return OwnerApiFp(configuration).countOwners(acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create multiple Owners
         * @param {CreateManyOwnerDto} createManyOwnerDto 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManyOwner(createManyOwnerDto: CreateManyOwnerDto, acceptLanguage?: string, options?: any): AxiosPromise<Array<Owner>> {
            return OwnerApiFp(configuration).createManyOwner(createManyOwnerDto, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a single Owner
         * @param {Owner} owner 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneOwner(owner: Owner, acceptLanguage?: string, options?: any): AxiosPromise<Owner> {
            return OwnerApiFp(configuration).createOneOwner(owner, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete multiple Owners
         * @param {Array<string>} requestBody 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyOwners(requestBody: Array<string>, acceptLanguage?: string, options?: any): AxiosPromise<void> {
            return OwnerApiFp(configuration).deleteManyOwners(requestBody, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a single Owner
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneOwner(id: number, acceptLanguage?: string, options?: any): AxiosPromise<Owner> {
            return OwnerApiFp(configuration).deleteOneOwner(id, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple Owners
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyOwner(acceptLanguage?: string, fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<GetManyOwnerResponseDto> {
            return OwnerApiFp(configuration).getManyOwner(acceptLanguage, fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single Owner
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneOwner(id: number, acceptLanguage?: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<Owner> {
            return OwnerApiFp(configuration).getOneOwner(id, acceptLanguage, fields, join, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Replace a single Owner
         * @param {number} id 
         * @param {Owner} owner 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceOneOwner(id: number, owner: Owner, acceptLanguage?: string, options?: any): AxiosPromise<Owner> {
            return OwnerApiFp(configuration).replaceOneOwner(id, owner, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update multiple Owners
         * @param {Array<Owner>} owner 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateManyOwners(owner: Array<Owner>, acceptLanguage?: string, options?: any): AxiosPromise<Array<Owner>> {
            return OwnerApiFp(configuration).updateManyOwners(owner, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a single Owner
         * @param {number} id 
         * @param {Owner} owner 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneOwner(id: number, owner: Owner, acceptLanguage?: string, options?: any): AxiosPromise<Owner> {
            return OwnerApiFp(configuration).updateOneOwner(id, owner, acceptLanguage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for countOwners operation in OwnerApi.
 * @export
 * @interface OwnerApiCountOwnersRequest
 */
export interface OwnerApiCountOwnersRequest {
    /**
     * Request language
     * @type {string}
     * @memberof OwnerApiCountOwners
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for createManyOwner operation in OwnerApi.
 * @export
 * @interface OwnerApiCreateManyOwnerRequest
 */
export interface OwnerApiCreateManyOwnerRequest {
    /**
     * 
     * @type {CreateManyOwnerDto}
     * @memberof OwnerApiCreateManyOwner
     */
    readonly createManyOwnerDto: CreateManyOwnerDto

    /**
     * Request language
     * @type {string}
     * @memberof OwnerApiCreateManyOwner
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for createOneOwner operation in OwnerApi.
 * @export
 * @interface OwnerApiCreateOneOwnerRequest
 */
export interface OwnerApiCreateOneOwnerRequest {
    /**
     * 
     * @type {Owner}
     * @memberof OwnerApiCreateOneOwner
     */
    readonly owner: Owner

    /**
     * Request language
     * @type {string}
     * @memberof OwnerApiCreateOneOwner
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for deleteManyOwners operation in OwnerApi.
 * @export
 * @interface OwnerApiDeleteManyOwnersRequest
 */
export interface OwnerApiDeleteManyOwnersRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof OwnerApiDeleteManyOwners
     */
    readonly requestBody: Array<string>

    /**
     * Request language
     * @type {string}
     * @memberof OwnerApiDeleteManyOwners
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for deleteOneOwner operation in OwnerApi.
 * @export
 * @interface OwnerApiDeleteOneOwnerRequest
 */
export interface OwnerApiDeleteOneOwnerRequest {
    /**
     * 
     * @type {number}
     * @memberof OwnerApiDeleteOneOwner
     */
    readonly id: number

    /**
     * Request language
     * @type {string}
     * @memberof OwnerApiDeleteOneOwner
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for getManyOwner operation in OwnerApi.
 * @export
 * @interface OwnerApiGetManyOwnerRequest
 */
export interface OwnerApiGetManyOwnerRequest {
    /**
     * Request language
     * @type {string}
     * @memberof OwnerApiGetManyOwner
     */
    readonly acceptLanguage?: string

    /**
     * Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof OwnerApiGetManyOwner
     */
    readonly fields?: Array<string>

    /**
     * Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {string}
     * @memberof OwnerApiGetManyOwner
     */
    readonly s?: string

    /**
     * Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof OwnerApiGetManyOwner
     */
    readonly filter?: Array<string>

    /**
     * Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof OwnerApiGetManyOwner
     */
    readonly or?: Array<string>

    /**
     * Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof OwnerApiGetManyOwner
     */
    readonly sort?: Array<string>

    /**
     * Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof OwnerApiGetManyOwner
     */
    readonly join?: Array<string>

    /**
     * Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof OwnerApiGetManyOwner
     */
    readonly limit?: number

    /**
     * Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof OwnerApiGetManyOwner
     */
    readonly offset?: number

    /**
     * Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof OwnerApiGetManyOwner
     */
    readonly page?: number

    /**
     * Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof OwnerApiGetManyOwner
     */
    readonly cache?: number
}

/**
 * Request parameters for getOneOwner operation in OwnerApi.
 * @export
 * @interface OwnerApiGetOneOwnerRequest
 */
export interface OwnerApiGetOneOwnerRequest {
    /**
     * 
     * @type {number}
     * @memberof OwnerApiGetOneOwner
     */
    readonly id: number

    /**
     * Request language
     * @type {string}
     * @memberof OwnerApiGetOneOwner
     */
    readonly acceptLanguage?: string

    /**
     * Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof OwnerApiGetOneOwner
     */
    readonly fields?: Array<string>

    /**
     * Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof OwnerApiGetOneOwner
     */
    readonly join?: Array<string>

    /**
     * Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof OwnerApiGetOneOwner
     */
    readonly cache?: number
}

/**
 * Request parameters for replaceOneOwner operation in OwnerApi.
 * @export
 * @interface OwnerApiReplaceOneOwnerRequest
 */
export interface OwnerApiReplaceOneOwnerRequest {
    /**
     * 
     * @type {number}
     * @memberof OwnerApiReplaceOneOwner
     */
    readonly id: number

    /**
     * 
     * @type {Owner}
     * @memberof OwnerApiReplaceOneOwner
     */
    readonly owner: Owner

    /**
     * Request language
     * @type {string}
     * @memberof OwnerApiReplaceOneOwner
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for updateManyOwners operation in OwnerApi.
 * @export
 * @interface OwnerApiUpdateManyOwnersRequest
 */
export interface OwnerApiUpdateManyOwnersRequest {
    /**
     * 
     * @type {Array<Owner>}
     * @memberof OwnerApiUpdateManyOwners
     */
    readonly owner: Array<Owner>

    /**
     * Request language
     * @type {string}
     * @memberof OwnerApiUpdateManyOwners
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for updateOneOwner operation in OwnerApi.
 * @export
 * @interface OwnerApiUpdateOneOwnerRequest
 */
export interface OwnerApiUpdateOneOwnerRequest {
    /**
     * 
     * @type {number}
     * @memberof OwnerApiUpdateOneOwner
     */
    readonly id: number

    /**
     * 
     * @type {Owner}
     * @memberof OwnerApiUpdateOneOwner
     */
    readonly owner: Owner

    /**
     * Request language
     * @type {string}
     * @memberof OwnerApiUpdateOneOwner
     */
    readonly acceptLanguage?: string
}

/**
 * OwnerApi - object-oriented interface
 * @export
 * @class OwnerApi
 * @extends {BaseAPI}
 */
export class OwnerApi extends BaseAPI {
    /**
     * 
     * @summary Get Owners total
     * @param {OwnerApiCountOwnersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OwnerApi
     */
    public countOwners(requestParameters: OwnerApiCountOwnersRequest = {}, options?: any) {
        return OwnerApiFp(this.configuration).countOwners(requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create multiple Owners
     * @param {OwnerApiCreateManyOwnerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OwnerApi
     */
    public createManyOwner(requestParameters: OwnerApiCreateManyOwnerRequest, options?: any) {
        return OwnerApiFp(this.configuration).createManyOwner(requestParameters.createManyOwnerDto, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a single Owner
     * @param {OwnerApiCreateOneOwnerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OwnerApi
     */
    public createOneOwner(requestParameters: OwnerApiCreateOneOwnerRequest, options?: any) {
        return OwnerApiFp(this.configuration).createOneOwner(requestParameters.owner, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete multiple Owners
     * @param {OwnerApiDeleteManyOwnersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OwnerApi
     */
    public deleteManyOwners(requestParameters: OwnerApiDeleteManyOwnersRequest, options?: any) {
        return OwnerApiFp(this.configuration).deleteManyOwners(requestParameters.requestBody, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a single Owner
     * @param {OwnerApiDeleteOneOwnerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OwnerApi
     */
    public deleteOneOwner(requestParameters: OwnerApiDeleteOneOwnerRequest, options?: any) {
        return OwnerApiFp(this.configuration).deleteOneOwner(requestParameters.id, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple Owners
     * @param {OwnerApiGetManyOwnerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OwnerApi
     */
    public getManyOwner(requestParameters: OwnerApiGetManyOwnerRequest = {}, options?: any) {
        return OwnerApiFp(this.configuration).getManyOwner(requestParameters.acceptLanguage, requestParameters.fields, requestParameters.s, requestParameters.filter, requestParameters.or, requestParameters.sort, requestParameters.join, requestParameters.limit, requestParameters.offset, requestParameters.page, requestParameters.cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single Owner
     * @param {OwnerApiGetOneOwnerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OwnerApi
     */
    public getOneOwner(requestParameters: OwnerApiGetOneOwnerRequest, options?: any) {
        return OwnerApiFp(this.configuration).getOneOwner(requestParameters.id, requestParameters.acceptLanguage, requestParameters.fields, requestParameters.join, requestParameters.cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Replace a single Owner
     * @param {OwnerApiReplaceOneOwnerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OwnerApi
     */
    public replaceOneOwner(requestParameters: OwnerApiReplaceOneOwnerRequest, options?: any) {
        return OwnerApiFp(this.configuration).replaceOneOwner(requestParameters.id, requestParameters.owner, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update multiple Owners
     * @param {OwnerApiUpdateManyOwnersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OwnerApi
     */
    public updateManyOwners(requestParameters: OwnerApiUpdateManyOwnersRequest, options?: any) {
        return OwnerApiFp(this.configuration).updateManyOwners(requestParameters.owner, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a single Owner
     * @param {OwnerApiUpdateOneOwnerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OwnerApi
     */
    public updateOneOwner(requestParameters: OwnerApiUpdateOneOwnerRequest, options?: any) {
        return OwnerApiFp(this.configuration).updateOneOwner(requestParameters.id, requestParameters.owner, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PlaceApi - axios parameter creator
 * @export
 */
export const PlaceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Places total
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countPlaces: async (acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/places/total/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create multiple Places
         * @param {CreateManyPlaceDto} createManyPlaceDto 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManyPlace: async (createManyPlaceDto: CreateManyPlaceDto, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createManyPlaceDto' is not null or undefined
            if (createManyPlaceDto === null || createManyPlaceDto === undefined) {
                throw new RequiredError('createManyPlaceDto','Required parameter createManyPlaceDto was null or undefined when calling createManyPlace.');
            }
            const localVarPath = `/places/bulk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createManyPlaceDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createManyPlaceDto !== undefined ? createManyPlaceDto : {})
                : (createManyPlaceDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a single Place
         * @param {Place} place 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOnePlace: async (place: Place, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'place' is not null or undefined
            if (place === null || place === undefined) {
                throw new RequiredError('place','Required parameter place was null or undefined when calling createOnePlace.');
            }
            const localVarPath = `/places`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof place !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(place !== undefined ? place : {})
                : (place || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete multiple Places
         * @param {Array<string>} requestBody 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyPlaces: async (requestBody: Array<string>, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            if (requestBody === null || requestBody === undefined) {
                throw new RequiredError('requestBody','Required parameter requestBody was null or undefined when calling deleteManyPlaces.');
            }
            const localVarPath = `/places/bulk/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof requestBody !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(requestBody !== undefined ? requestBody : {})
                : (requestBody || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a single Place
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOnePlace: async (id: number, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteOnePlace.');
            }
            const localVarPath = `/places/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple Places
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyPlace: async (acceptLanguage?: string, fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/places`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single Place
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOnePlace: async (id: number, acceptLanguage?: string, fields?: Array<string>, join?: Array<string>, cache?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOnePlace.');
            }
            const localVarPath = `/places/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Replace a single Place
         * @param {number} id 
         * @param {Place} place 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceOnePlace: async (id: number, place: Place, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling replaceOnePlace.');
            }
            // verify required parameter 'place' is not null or undefined
            if (place === null || place === undefined) {
                throw new RequiredError('place','Required parameter place was null or undefined when calling replaceOnePlace.');
            }
            const localVarPath = `/places/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof place !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(place !== undefined ? place : {})
                : (place || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update multiple Places
         * @param {Array<Place>} place 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateManyPlaces: async (place: Array<Place>, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'place' is not null or undefined
            if (place === null || place === undefined) {
                throw new RequiredError('place','Required parameter place was null or undefined when calling updateManyPlaces.');
            }
            const localVarPath = `/places/bulk/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof place !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(place !== undefined ? place : {})
                : (place || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a single Place
         * @param {number} id 
         * @param {Place} place 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOnePlace: async (id: number, place: Place, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateOnePlace.');
            }
            // verify required parameter 'place' is not null or undefined
            if (place === null || place === undefined) {
                throw new RequiredError('place','Required parameter place was null or undefined when calling updateOnePlace.');
            }
            const localVarPath = `/places/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof place !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(place !== undefined ? place : {})
                : (place || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlaceApi - functional programming interface
 * @export
 */
export const PlaceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Places total
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countPlaces(acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await PlaceApiAxiosParamCreator(configuration).countPlaces(acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create multiple Places
         * @param {CreateManyPlaceDto} createManyPlaceDto 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createManyPlace(createManyPlaceDto: CreateManyPlaceDto, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Place>>> {
            const localVarAxiosArgs = await PlaceApiAxiosParamCreator(configuration).createManyPlace(createManyPlaceDto, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create a single Place
         * @param {Place} place 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOnePlace(place: Place, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Place>> {
            const localVarAxiosArgs = await PlaceApiAxiosParamCreator(configuration).createOnePlace(place, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete multiple Places
         * @param {Array<string>} requestBody 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteManyPlaces(requestBody: Array<string>, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PlaceApiAxiosParamCreator(configuration).deleteManyPlaces(requestBody, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete a single Place
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOnePlace(id: number, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Place>> {
            const localVarAxiosArgs = await PlaceApiAxiosParamCreator(configuration).deleteOnePlace(id, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieve multiple Places
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyPlace(acceptLanguage?: string, fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyPlaceResponseDto>> {
            const localVarAxiosArgs = await PlaceApiAxiosParamCreator(configuration).getManyPlace(acceptLanguage, fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieve a single Place
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOnePlace(id: number, acceptLanguage?: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Place>> {
            const localVarAxiosArgs = await PlaceApiAxiosParamCreator(configuration).getOnePlace(id, acceptLanguage, fields, join, cache, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Replace a single Place
         * @param {number} id 
         * @param {Place} place 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceOnePlace(id: number, place: Place, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Place>> {
            const localVarAxiosArgs = await PlaceApiAxiosParamCreator(configuration).replaceOnePlace(id, place, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update multiple Places
         * @param {Array<Place>} place 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateManyPlaces(place: Array<Place>, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Place>>> {
            const localVarAxiosArgs = await PlaceApiAxiosParamCreator(configuration).updateManyPlaces(place, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update a single Place
         * @param {number} id 
         * @param {Place} place 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOnePlace(id: number, place: Place, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Place>> {
            const localVarAxiosArgs = await PlaceApiAxiosParamCreator(configuration).updateOnePlace(id, place, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PlaceApi - factory interface
 * @export
 */
export const PlaceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get Places total
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countPlaces(acceptLanguage?: string, options?: any): AxiosPromise<number> {
            return PlaceApiFp(configuration).countPlaces(acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create multiple Places
         * @param {CreateManyPlaceDto} createManyPlaceDto 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManyPlace(createManyPlaceDto: CreateManyPlaceDto, acceptLanguage?: string, options?: any): AxiosPromise<Array<Place>> {
            return PlaceApiFp(configuration).createManyPlace(createManyPlaceDto, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a single Place
         * @param {Place} place 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOnePlace(place: Place, acceptLanguage?: string, options?: any): AxiosPromise<Place> {
            return PlaceApiFp(configuration).createOnePlace(place, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete multiple Places
         * @param {Array<string>} requestBody 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyPlaces(requestBody: Array<string>, acceptLanguage?: string, options?: any): AxiosPromise<void> {
            return PlaceApiFp(configuration).deleteManyPlaces(requestBody, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a single Place
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOnePlace(id: number, acceptLanguage?: string, options?: any): AxiosPromise<Place> {
            return PlaceApiFp(configuration).deleteOnePlace(id, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple Places
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyPlace(acceptLanguage?: string, fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<GetManyPlaceResponseDto> {
            return PlaceApiFp(configuration).getManyPlace(acceptLanguage, fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single Place
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOnePlace(id: number, acceptLanguage?: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<Place> {
            return PlaceApiFp(configuration).getOnePlace(id, acceptLanguage, fields, join, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Replace a single Place
         * @param {number} id 
         * @param {Place} place 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceOnePlace(id: number, place: Place, acceptLanguage?: string, options?: any): AxiosPromise<Place> {
            return PlaceApiFp(configuration).replaceOnePlace(id, place, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update multiple Places
         * @param {Array<Place>} place 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateManyPlaces(place: Array<Place>, acceptLanguage?: string, options?: any): AxiosPromise<Array<Place>> {
            return PlaceApiFp(configuration).updateManyPlaces(place, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a single Place
         * @param {number} id 
         * @param {Place} place 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOnePlace(id: number, place: Place, acceptLanguage?: string, options?: any): AxiosPromise<Place> {
            return PlaceApiFp(configuration).updateOnePlace(id, place, acceptLanguage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for countPlaces operation in PlaceApi.
 * @export
 * @interface PlaceApiCountPlacesRequest
 */
export interface PlaceApiCountPlacesRequest {
    /**
     * Request language
     * @type {string}
     * @memberof PlaceApiCountPlaces
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for createManyPlace operation in PlaceApi.
 * @export
 * @interface PlaceApiCreateManyPlaceRequest
 */
export interface PlaceApiCreateManyPlaceRequest {
    /**
     * 
     * @type {CreateManyPlaceDto}
     * @memberof PlaceApiCreateManyPlace
     */
    readonly createManyPlaceDto: CreateManyPlaceDto

    /**
     * Request language
     * @type {string}
     * @memberof PlaceApiCreateManyPlace
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for createOnePlace operation in PlaceApi.
 * @export
 * @interface PlaceApiCreateOnePlaceRequest
 */
export interface PlaceApiCreateOnePlaceRequest {
    /**
     * 
     * @type {Place}
     * @memberof PlaceApiCreateOnePlace
     */
    readonly place: Place

    /**
     * Request language
     * @type {string}
     * @memberof PlaceApiCreateOnePlace
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for deleteManyPlaces operation in PlaceApi.
 * @export
 * @interface PlaceApiDeleteManyPlacesRequest
 */
export interface PlaceApiDeleteManyPlacesRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof PlaceApiDeleteManyPlaces
     */
    readonly requestBody: Array<string>

    /**
     * Request language
     * @type {string}
     * @memberof PlaceApiDeleteManyPlaces
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for deleteOnePlace operation in PlaceApi.
 * @export
 * @interface PlaceApiDeleteOnePlaceRequest
 */
export interface PlaceApiDeleteOnePlaceRequest {
    /**
     * 
     * @type {number}
     * @memberof PlaceApiDeleteOnePlace
     */
    readonly id: number

    /**
     * Request language
     * @type {string}
     * @memberof PlaceApiDeleteOnePlace
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for getManyPlace operation in PlaceApi.
 * @export
 * @interface PlaceApiGetManyPlaceRequest
 */
export interface PlaceApiGetManyPlaceRequest {
    /**
     * Request language
     * @type {string}
     * @memberof PlaceApiGetManyPlace
     */
    readonly acceptLanguage?: string

    /**
     * Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof PlaceApiGetManyPlace
     */
    readonly fields?: Array<string>

    /**
     * Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {string}
     * @memberof PlaceApiGetManyPlace
     */
    readonly s?: string

    /**
     * Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof PlaceApiGetManyPlace
     */
    readonly filter?: Array<string>

    /**
     * Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof PlaceApiGetManyPlace
     */
    readonly or?: Array<string>

    /**
     * Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof PlaceApiGetManyPlace
     */
    readonly sort?: Array<string>

    /**
     * Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof PlaceApiGetManyPlace
     */
    readonly join?: Array<string>

    /**
     * Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof PlaceApiGetManyPlace
     */
    readonly limit?: number

    /**
     * Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof PlaceApiGetManyPlace
     */
    readonly offset?: number

    /**
     * Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof PlaceApiGetManyPlace
     */
    readonly page?: number

    /**
     * Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof PlaceApiGetManyPlace
     */
    readonly cache?: number
}

/**
 * Request parameters for getOnePlace operation in PlaceApi.
 * @export
 * @interface PlaceApiGetOnePlaceRequest
 */
export interface PlaceApiGetOnePlaceRequest {
    /**
     * 
     * @type {number}
     * @memberof PlaceApiGetOnePlace
     */
    readonly id: number

    /**
     * Request language
     * @type {string}
     * @memberof PlaceApiGetOnePlace
     */
    readonly acceptLanguage?: string

    /**
     * Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof PlaceApiGetOnePlace
     */
    readonly fields?: Array<string>

    /**
     * Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof PlaceApiGetOnePlace
     */
    readonly join?: Array<string>

    /**
     * Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof PlaceApiGetOnePlace
     */
    readonly cache?: number
}

/**
 * Request parameters for replaceOnePlace operation in PlaceApi.
 * @export
 * @interface PlaceApiReplaceOnePlaceRequest
 */
export interface PlaceApiReplaceOnePlaceRequest {
    /**
     * 
     * @type {number}
     * @memberof PlaceApiReplaceOnePlace
     */
    readonly id: number

    /**
     * 
     * @type {Place}
     * @memberof PlaceApiReplaceOnePlace
     */
    readonly place: Place

    /**
     * Request language
     * @type {string}
     * @memberof PlaceApiReplaceOnePlace
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for updateManyPlaces operation in PlaceApi.
 * @export
 * @interface PlaceApiUpdateManyPlacesRequest
 */
export interface PlaceApiUpdateManyPlacesRequest {
    /**
     * 
     * @type {Array<Place>}
     * @memberof PlaceApiUpdateManyPlaces
     */
    readonly place: Array<Place>

    /**
     * Request language
     * @type {string}
     * @memberof PlaceApiUpdateManyPlaces
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for updateOnePlace operation in PlaceApi.
 * @export
 * @interface PlaceApiUpdateOnePlaceRequest
 */
export interface PlaceApiUpdateOnePlaceRequest {
    /**
     * 
     * @type {number}
     * @memberof PlaceApiUpdateOnePlace
     */
    readonly id: number

    /**
     * 
     * @type {Place}
     * @memberof PlaceApiUpdateOnePlace
     */
    readonly place: Place

    /**
     * Request language
     * @type {string}
     * @memberof PlaceApiUpdateOnePlace
     */
    readonly acceptLanguage?: string
}

/**
 * PlaceApi - object-oriented interface
 * @export
 * @class PlaceApi
 * @extends {BaseAPI}
 */
export class PlaceApi extends BaseAPI {
    /**
     * 
     * @summary Get Places total
     * @param {PlaceApiCountPlacesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaceApi
     */
    public countPlaces(requestParameters: PlaceApiCountPlacesRequest = {}, options?: any) {
        return PlaceApiFp(this.configuration).countPlaces(requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create multiple Places
     * @param {PlaceApiCreateManyPlaceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaceApi
     */
    public createManyPlace(requestParameters: PlaceApiCreateManyPlaceRequest, options?: any) {
        return PlaceApiFp(this.configuration).createManyPlace(requestParameters.createManyPlaceDto, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a single Place
     * @param {PlaceApiCreateOnePlaceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaceApi
     */
    public createOnePlace(requestParameters: PlaceApiCreateOnePlaceRequest, options?: any) {
        return PlaceApiFp(this.configuration).createOnePlace(requestParameters.place, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete multiple Places
     * @param {PlaceApiDeleteManyPlacesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaceApi
     */
    public deleteManyPlaces(requestParameters: PlaceApiDeleteManyPlacesRequest, options?: any) {
        return PlaceApiFp(this.configuration).deleteManyPlaces(requestParameters.requestBody, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a single Place
     * @param {PlaceApiDeleteOnePlaceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaceApi
     */
    public deleteOnePlace(requestParameters: PlaceApiDeleteOnePlaceRequest, options?: any) {
        return PlaceApiFp(this.configuration).deleteOnePlace(requestParameters.id, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple Places
     * @param {PlaceApiGetManyPlaceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaceApi
     */
    public getManyPlace(requestParameters: PlaceApiGetManyPlaceRequest = {}, options?: any) {
        return PlaceApiFp(this.configuration).getManyPlace(requestParameters.acceptLanguage, requestParameters.fields, requestParameters.s, requestParameters.filter, requestParameters.or, requestParameters.sort, requestParameters.join, requestParameters.limit, requestParameters.offset, requestParameters.page, requestParameters.cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single Place
     * @param {PlaceApiGetOnePlaceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaceApi
     */
    public getOnePlace(requestParameters: PlaceApiGetOnePlaceRequest, options?: any) {
        return PlaceApiFp(this.configuration).getOnePlace(requestParameters.id, requestParameters.acceptLanguage, requestParameters.fields, requestParameters.join, requestParameters.cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Replace a single Place
     * @param {PlaceApiReplaceOnePlaceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaceApi
     */
    public replaceOnePlace(requestParameters: PlaceApiReplaceOnePlaceRequest, options?: any) {
        return PlaceApiFp(this.configuration).replaceOnePlace(requestParameters.id, requestParameters.place, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update multiple Places
     * @param {PlaceApiUpdateManyPlacesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaceApi
     */
    public updateManyPlaces(requestParameters: PlaceApiUpdateManyPlacesRequest, options?: any) {
        return PlaceApiFp(this.configuration).updateManyPlaces(requestParameters.place, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a single Place
     * @param {PlaceApiUpdateOnePlaceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaceApi
     */
    public updateOnePlace(requestParameters: PlaceApiUpdateOnePlaceRequest, options?: any) {
        return PlaceApiFp(this.configuration).updateOnePlace(requestParameters.id, requestParameters.place, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TechniqueApi - axios parameter creator
 * @export
 */
export const TechniqueApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Techniques total
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countTechniques: async (acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Technique/total/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create multiple Techniques
         * @param {CreateManyTechniqueDto} createManyTechniqueDto 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManyTechnique: async (createManyTechniqueDto: CreateManyTechniqueDto, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createManyTechniqueDto' is not null or undefined
            if (createManyTechniqueDto === null || createManyTechniqueDto === undefined) {
                throw new RequiredError('createManyTechniqueDto','Required parameter createManyTechniqueDto was null or undefined when calling createManyTechnique.');
            }
            const localVarPath = `/Technique/bulk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createManyTechniqueDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createManyTechniqueDto !== undefined ? createManyTechniqueDto : {})
                : (createManyTechniqueDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a single Technique
         * @param {Technique} technique 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneTechnique: async (technique: Technique, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'technique' is not null or undefined
            if (technique === null || technique === undefined) {
                throw new RequiredError('technique','Required parameter technique was null or undefined when calling createOneTechnique.');
            }
            const localVarPath = `/Technique`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof technique !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(technique !== undefined ? technique : {})
                : (technique || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete multiple Techniques
         * @param {Array<string>} requestBody 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyTechniques: async (requestBody: Array<string>, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            if (requestBody === null || requestBody === undefined) {
                throw new RequiredError('requestBody','Required parameter requestBody was null or undefined when calling deleteManyTechniques.');
            }
            const localVarPath = `/Technique/bulk/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof requestBody !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(requestBody !== undefined ? requestBody : {})
                : (requestBody || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a single Technique
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneTechnique: async (id: number, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteOneTechnique.');
            }
            const localVarPath = `/Technique/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple Techniques
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyTechnique: async (acceptLanguage?: string, fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Technique`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single Technique
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneTechnique: async (id: number, acceptLanguage?: string, fields?: Array<string>, join?: Array<string>, cache?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOneTechnique.');
            }
            const localVarPath = `/Technique/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Replace a single Technique
         * @param {number} id 
         * @param {Technique} technique 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceOneTechnique: async (id: number, technique: Technique, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling replaceOneTechnique.');
            }
            // verify required parameter 'technique' is not null or undefined
            if (technique === null || technique === undefined) {
                throw new RequiredError('technique','Required parameter technique was null or undefined when calling replaceOneTechnique.');
            }
            const localVarPath = `/Technique/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof technique !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(technique !== undefined ? technique : {})
                : (technique || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update multiple Techniques
         * @param {Array<Technique>} technique 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateManyTechniques: async (technique: Array<Technique>, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'technique' is not null or undefined
            if (technique === null || technique === undefined) {
                throw new RequiredError('technique','Required parameter technique was null or undefined when calling updateManyTechniques.');
            }
            const localVarPath = `/Technique/bulk/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof technique !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(technique !== undefined ? technique : {})
                : (technique || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a single Technique
         * @param {number} id 
         * @param {Technique} technique 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneTechnique: async (id: number, technique: Technique, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateOneTechnique.');
            }
            // verify required parameter 'technique' is not null or undefined
            if (technique === null || technique === undefined) {
                throw new RequiredError('technique','Required parameter technique was null or undefined when calling updateOneTechnique.');
            }
            const localVarPath = `/Technique/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof technique !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(technique !== undefined ? technique : {})
                : (technique || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TechniqueApi - functional programming interface
 * @export
 */
export const TechniqueApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Techniques total
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countTechniques(acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await TechniqueApiAxiosParamCreator(configuration).countTechniques(acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create multiple Techniques
         * @param {CreateManyTechniqueDto} createManyTechniqueDto 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createManyTechnique(createManyTechniqueDto: CreateManyTechniqueDto, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Technique>>> {
            const localVarAxiosArgs = await TechniqueApiAxiosParamCreator(configuration).createManyTechnique(createManyTechniqueDto, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create a single Technique
         * @param {Technique} technique 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOneTechnique(technique: Technique, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Technique>> {
            const localVarAxiosArgs = await TechniqueApiAxiosParamCreator(configuration).createOneTechnique(technique, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete multiple Techniques
         * @param {Array<string>} requestBody 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteManyTechniques(requestBody: Array<string>, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await TechniqueApiAxiosParamCreator(configuration).deleteManyTechniques(requestBody, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete a single Technique
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOneTechnique(id: number, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Technique>> {
            const localVarAxiosArgs = await TechniqueApiAxiosParamCreator(configuration).deleteOneTechnique(id, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieve multiple Techniques
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyTechnique(acceptLanguage?: string, fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyTechniqueResponseDto>> {
            const localVarAxiosArgs = await TechniqueApiAxiosParamCreator(configuration).getManyTechnique(acceptLanguage, fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieve a single Technique
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneTechnique(id: number, acceptLanguage?: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Technique>> {
            const localVarAxiosArgs = await TechniqueApiAxiosParamCreator(configuration).getOneTechnique(id, acceptLanguage, fields, join, cache, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Replace a single Technique
         * @param {number} id 
         * @param {Technique} technique 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceOneTechnique(id: number, technique: Technique, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Technique>> {
            const localVarAxiosArgs = await TechniqueApiAxiosParamCreator(configuration).replaceOneTechnique(id, technique, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update multiple Techniques
         * @param {Array<Technique>} technique 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateManyTechniques(technique: Array<Technique>, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Technique>>> {
            const localVarAxiosArgs = await TechniqueApiAxiosParamCreator(configuration).updateManyTechniques(technique, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update a single Technique
         * @param {number} id 
         * @param {Technique} technique 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOneTechnique(id: number, technique: Technique, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Technique>> {
            const localVarAxiosArgs = await TechniqueApiAxiosParamCreator(configuration).updateOneTechnique(id, technique, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TechniqueApi - factory interface
 * @export
 */
export const TechniqueApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get Techniques total
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countTechniques(acceptLanguage?: string, options?: any): AxiosPromise<number> {
            return TechniqueApiFp(configuration).countTechniques(acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create multiple Techniques
         * @param {CreateManyTechniqueDto} createManyTechniqueDto 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManyTechnique(createManyTechniqueDto: CreateManyTechniqueDto, acceptLanguage?: string, options?: any): AxiosPromise<Array<Technique>> {
            return TechniqueApiFp(configuration).createManyTechnique(createManyTechniqueDto, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a single Technique
         * @param {Technique} technique 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneTechnique(technique: Technique, acceptLanguage?: string, options?: any): AxiosPromise<Technique> {
            return TechniqueApiFp(configuration).createOneTechnique(technique, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete multiple Techniques
         * @param {Array<string>} requestBody 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyTechniques(requestBody: Array<string>, acceptLanguage?: string, options?: any): AxiosPromise<void> {
            return TechniqueApiFp(configuration).deleteManyTechniques(requestBody, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a single Technique
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneTechnique(id: number, acceptLanguage?: string, options?: any): AxiosPromise<Technique> {
            return TechniqueApiFp(configuration).deleteOneTechnique(id, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple Techniques
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyTechnique(acceptLanguage?: string, fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<GetManyTechniqueResponseDto> {
            return TechniqueApiFp(configuration).getManyTechnique(acceptLanguage, fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single Technique
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneTechnique(id: number, acceptLanguage?: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<Technique> {
            return TechniqueApiFp(configuration).getOneTechnique(id, acceptLanguage, fields, join, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Replace a single Technique
         * @param {number} id 
         * @param {Technique} technique 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceOneTechnique(id: number, technique: Technique, acceptLanguage?: string, options?: any): AxiosPromise<Technique> {
            return TechniqueApiFp(configuration).replaceOneTechnique(id, technique, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update multiple Techniques
         * @param {Array<Technique>} technique 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateManyTechniques(technique: Array<Technique>, acceptLanguage?: string, options?: any): AxiosPromise<Array<Technique>> {
            return TechniqueApiFp(configuration).updateManyTechniques(technique, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a single Technique
         * @param {number} id 
         * @param {Technique} technique 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneTechnique(id: number, technique: Technique, acceptLanguage?: string, options?: any): AxiosPromise<Technique> {
            return TechniqueApiFp(configuration).updateOneTechnique(id, technique, acceptLanguage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for countTechniques operation in TechniqueApi.
 * @export
 * @interface TechniqueApiCountTechniquesRequest
 */
export interface TechniqueApiCountTechniquesRequest {
    /**
     * Request language
     * @type {string}
     * @memberof TechniqueApiCountTechniques
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for createManyTechnique operation in TechniqueApi.
 * @export
 * @interface TechniqueApiCreateManyTechniqueRequest
 */
export interface TechniqueApiCreateManyTechniqueRequest {
    /**
     * 
     * @type {CreateManyTechniqueDto}
     * @memberof TechniqueApiCreateManyTechnique
     */
    readonly createManyTechniqueDto: CreateManyTechniqueDto

    /**
     * Request language
     * @type {string}
     * @memberof TechniqueApiCreateManyTechnique
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for createOneTechnique operation in TechniqueApi.
 * @export
 * @interface TechniqueApiCreateOneTechniqueRequest
 */
export interface TechniqueApiCreateOneTechniqueRequest {
    /**
     * 
     * @type {Technique}
     * @memberof TechniqueApiCreateOneTechnique
     */
    readonly technique: Technique

    /**
     * Request language
     * @type {string}
     * @memberof TechniqueApiCreateOneTechnique
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for deleteManyTechniques operation in TechniqueApi.
 * @export
 * @interface TechniqueApiDeleteManyTechniquesRequest
 */
export interface TechniqueApiDeleteManyTechniquesRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof TechniqueApiDeleteManyTechniques
     */
    readonly requestBody: Array<string>

    /**
     * Request language
     * @type {string}
     * @memberof TechniqueApiDeleteManyTechniques
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for deleteOneTechnique operation in TechniqueApi.
 * @export
 * @interface TechniqueApiDeleteOneTechniqueRequest
 */
export interface TechniqueApiDeleteOneTechniqueRequest {
    /**
     * 
     * @type {number}
     * @memberof TechniqueApiDeleteOneTechnique
     */
    readonly id: number

    /**
     * Request language
     * @type {string}
     * @memberof TechniqueApiDeleteOneTechnique
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for getManyTechnique operation in TechniqueApi.
 * @export
 * @interface TechniqueApiGetManyTechniqueRequest
 */
export interface TechniqueApiGetManyTechniqueRequest {
    /**
     * Request language
     * @type {string}
     * @memberof TechniqueApiGetManyTechnique
     */
    readonly acceptLanguage?: string

    /**
     * Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof TechniqueApiGetManyTechnique
     */
    readonly fields?: Array<string>

    /**
     * Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {string}
     * @memberof TechniqueApiGetManyTechnique
     */
    readonly s?: string

    /**
     * Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof TechniqueApiGetManyTechnique
     */
    readonly filter?: Array<string>

    /**
     * Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof TechniqueApiGetManyTechnique
     */
    readonly or?: Array<string>

    /**
     * Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof TechniqueApiGetManyTechnique
     */
    readonly sort?: Array<string>

    /**
     * Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof TechniqueApiGetManyTechnique
     */
    readonly join?: Array<string>

    /**
     * Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof TechniqueApiGetManyTechnique
     */
    readonly limit?: number

    /**
     * Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof TechniqueApiGetManyTechnique
     */
    readonly offset?: number

    /**
     * Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof TechniqueApiGetManyTechnique
     */
    readonly page?: number

    /**
     * Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof TechniqueApiGetManyTechnique
     */
    readonly cache?: number
}

/**
 * Request parameters for getOneTechnique operation in TechniqueApi.
 * @export
 * @interface TechniqueApiGetOneTechniqueRequest
 */
export interface TechniqueApiGetOneTechniqueRequest {
    /**
     * 
     * @type {number}
     * @memberof TechniqueApiGetOneTechnique
     */
    readonly id: number

    /**
     * Request language
     * @type {string}
     * @memberof TechniqueApiGetOneTechnique
     */
    readonly acceptLanguage?: string

    /**
     * Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof TechniqueApiGetOneTechnique
     */
    readonly fields?: Array<string>

    /**
     * Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof TechniqueApiGetOneTechnique
     */
    readonly join?: Array<string>

    /**
     * Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof TechniqueApiGetOneTechnique
     */
    readonly cache?: number
}

/**
 * Request parameters for replaceOneTechnique operation in TechniqueApi.
 * @export
 * @interface TechniqueApiReplaceOneTechniqueRequest
 */
export interface TechniqueApiReplaceOneTechniqueRequest {
    /**
     * 
     * @type {number}
     * @memberof TechniqueApiReplaceOneTechnique
     */
    readonly id: number

    /**
     * 
     * @type {Technique}
     * @memberof TechniqueApiReplaceOneTechnique
     */
    readonly technique: Technique

    /**
     * Request language
     * @type {string}
     * @memberof TechniqueApiReplaceOneTechnique
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for updateManyTechniques operation in TechniqueApi.
 * @export
 * @interface TechniqueApiUpdateManyTechniquesRequest
 */
export interface TechniqueApiUpdateManyTechniquesRequest {
    /**
     * 
     * @type {Array<Technique>}
     * @memberof TechniqueApiUpdateManyTechniques
     */
    readonly technique: Array<Technique>

    /**
     * Request language
     * @type {string}
     * @memberof TechniqueApiUpdateManyTechniques
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for updateOneTechnique operation in TechniqueApi.
 * @export
 * @interface TechniqueApiUpdateOneTechniqueRequest
 */
export interface TechniqueApiUpdateOneTechniqueRequest {
    /**
     * 
     * @type {number}
     * @memberof TechniqueApiUpdateOneTechnique
     */
    readonly id: number

    /**
     * 
     * @type {Technique}
     * @memberof TechniqueApiUpdateOneTechnique
     */
    readonly technique: Technique

    /**
     * Request language
     * @type {string}
     * @memberof TechniqueApiUpdateOneTechnique
     */
    readonly acceptLanguage?: string
}

/**
 * TechniqueApi - object-oriented interface
 * @export
 * @class TechniqueApi
 * @extends {BaseAPI}
 */
export class TechniqueApi extends BaseAPI {
    /**
     * 
     * @summary Get Techniques total
     * @param {TechniqueApiCountTechniquesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TechniqueApi
     */
    public countTechniques(requestParameters: TechniqueApiCountTechniquesRequest = {}, options?: any) {
        return TechniqueApiFp(this.configuration).countTechniques(requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create multiple Techniques
     * @param {TechniqueApiCreateManyTechniqueRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TechniqueApi
     */
    public createManyTechnique(requestParameters: TechniqueApiCreateManyTechniqueRequest, options?: any) {
        return TechniqueApiFp(this.configuration).createManyTechnique(requestParameters.createManyTechniqueDto, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a single Technique
     * @param {TechniqueApiCreateOneTechniqueRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TechniqueApi
     */
    public createOneTechnique(requestParameters: TechniqueApiCreateOneTechniqueRequest, options?: any) {
        return TechniqueApiFp(this.configuration).createOneTechnique(requestParameters.technique, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete multiple Techniques
     * @param {TechniqueApiDeleteManyTechniquesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TechniqueApi
     */
    public deleteManyTechniques(requestParameters: TechniqueApiDeleteManyTechniquesRequest, options?: any) {
        return TechniqueApiFp(this.configuration).deleteManyTechniques(requestParameters.requestBody, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a single Technique
     * @param {TechniqueApiDeleteOneTechniqueRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TechniqueApi
     */
    public deleteOneTechnique(requestParameters: TechniqueApiDeleteOneTechniqueRequest, options?: any) {
        return TechniqueApiFp(this.configuration).deleteOneTechnique(requestParameters.id, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple Techniques
     * @param {TechniqueApiGetManyTechniqueRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TechniqueApi
     */
    public getManyTechnique(requestParameters: TechniqueApiGetManyTechniqueRequest = {}, options?: any) {
        return TechniqueApiFp(this.configuration).getManyTechnique(requestParameters.acceptLanguage, requestParameters.fields, requestParameters.s, requestParameters.filter, requestParameters.or, requestParameters.sort, requestParameters.join, requestParameters.limit, requestParameters.offset, requestParameters.page, requestParameters.cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single Technique
     * @param {TechniqueApiGetOneTechniqueRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TechniqueApi
     */
    public getOneTechnique(requestParameters: TechniqueApiGetOneTechniqueRequest, options?: any) {
        return TechniqueApiFp(this.configuration).getOneTechnique(requestParameters.id, requestParameters.acceptLanguage, requestParameters.fields, requestParameters.join, requestParameters.cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Replace a single Technique
     * @param {TechniqueApiReplaceOneTechniqueRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TechniqueApi
     */
    public replaceOneTechnique(requestParameters: TechniqueApiReplaceOneTechniqueRequest, options?: any) {
        return TechniqueApiFp(this.configuration).replaceOneTechnique(requestParameters.id, requestParameters.technique, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update multiple Techniques
     * @param {TechniqueApiUpdateManyTechniquesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TechniqueApi
     */
    public updateManyTechniques(requestParameters: TechniqueApiUpdateManyTechniquesRequest, options?: any) {
        return TechniqueApiFp(this.configuration).updateManyTechniques(requestParameters.technique, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a single Technique
     * @param {TechniqueApiUpdateOneTechniqueRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TechniqueApi
     */
    public updateOneTechnique(requestParameters: TechniqueApiUpdateOneTechniqueRequest, options?: any) {
        return TechniqueApiFp(this.configuration).updateOneTechnique(requestParameters.id, requestParameters.technique, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TopicApi - axios parameter creator
 * @export
 */
export const TopicApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Topics total
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countTopics: async (acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/topics/total/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create multiple Topics
         * @param {CreateManyTopicDto} createManyTopicDto 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManyTopic: async (createManyTopicDto: CreateManyTopicDto, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createManyTopicDto' is not null or undefined
            if (createManyTopicDto === null || createManyTopicDto === undefined) {
                throw new RequiredError('createManyTopicDto','Required parameter createManyTopicDto was null or undefined when calling createManyTopic.');
            }
            const localVarPath = `/topics/bulk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createManyTopicDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createManyTopicDto !== undefined ? createManyTopicDto : {})
                : (createManyTopicDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a single Topic
         * @param {Topic} topic 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneTopic: async (topic: Topic, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'topic' is not null or undefined
            if (topic === null || topic === undefined) {
                throw new RequiredError('topic','Required parameter topic was null or undefined when calling createOneTopic.');
            }
            const localVarPath = `/topics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof topic !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(topic !== undefined ? topic : {})
                : (topic || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete multiple Topics
         * @param {Array<string>} requestBody 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyTopics: async (requestBody: Array<string>, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            if (requestBody === null || requestBody === undefined) {
                throw new RequiredError('requestBody','Required parameter requestBody was null or undefined when calling deleteManyTopics.');
            }
            const localVarPath = `/topics/bulk/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof requestBody !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(requestBody !== undefined ? requestBody : {})
                : (requestBody || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a single Topic
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneTopic: async (id: number, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteOneTopic.');
            }
            const localVarPath = `/topics/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple Topics
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyTopic: async (acceptLanguage?: string, fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/topics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single Topic
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneTopic: async (id: number, acceptLanguage?: string, fields?: Array<string>, join?: Array<string>, cache?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOneTopic.');
            }
            const localVarPath = `/topics/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Replace a single Topic
         * @param {number} id 
         * @param {Topic} topic 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceOneTopic: async (id: number, topic: Topic, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling replaceOneTopic.');
            }
            // verify required parameter 'topic' is not null or undefined
            if (topic === null || topic === undefined) {
                throw new RequiredError('topic','Required parameter topic was null or undefined when calling replaceOneTopic.');
            }
            const localVarPath = `/topics/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof topic !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(topic !== undefined ? topic : {})
                : (topic || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update multiple Topics
         * @param {Array<Topic>} topic 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateManyTopics: async (topic: Array<Topic>, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'topic' is not null or undefined
            if (topic === null || topic === undefined) {
                throw new RequiredError('topic','Required parameter topic was null or undefined when calling updateManyTopics.');
            }
            const localVarPath = `/topics/bulk/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof topic !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(topic !== undefined ? topic : {})
                : (topic || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a single Topic
         * @param {number} id 
         * @param {Topic} topic 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneTopic: async (id: number, topic: Topic, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateOneTopic.');
            }
            // verify required parameter 'topic' is not null or undefined
            if (topic === null || topic === undefined) {
                throw new RequiredError('topic','Required parameter topic was null or undefined when calling updateOneTopic.');
            }
            const localVarPath = `/topics/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof topic !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(topic !== undefined ? topic : {})
                : (topic || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TopicApi - functional programming interface
 * @export
 */
export const TopicApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Topics total
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countTopics(acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await TopicApiAxiosParamCreator(configuration).countTopics(acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create multiple Topics
         * @param {CreateManyTopicDto} createManyTopicDto 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createManyTopic(createManyTopicDto: CreateManyTopicDto, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Topic>>> {
            const localVarAxiosArgs = await TopicApiAxiosParamCreator(configuration).createManyTopic(createManyTopicDto, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create a single Topic
         * @param {Topic} topic 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOneTopic(topic: Topic, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Topic>> {
            const localVarAxiosArgs = await TopicApiAxiosParamCreator(configuration).createOneTopic(topic, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete multiple Topics
         * @param {Array<string>} requestBody 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteManyTopics(requestBody: Array<string>, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await TopicApiAxiosParamCreator(configuration).deleteManyTopics(requestBody, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete a single Topic
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOneTopic(id: number, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Topic>> {
            const localVarAxiosArgs = await TopicApiAxiosParamCreator(configuration).deleteOneTopic(id, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieve multiple Topics
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyTopic(acceptLanguage?: string, fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyTopicResponseDto>> {
            const localVarAxiosArgs = await TopicApiAxiosParamCreator(configuration).getManyTopic(acceptLanguage, fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieve a single Topic
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneTopic(id: number, acceptLanguage?: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Topic>> {
            const localVarAxiosArgs = await TopicApiAxiosParamCreator(configuration).getOneTopic(id, acceptLanguage, fields, join, cache, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Replace a single Topic
         * @param {number} id 
         * @param {Topic} topic 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceOneTopic(id: number, topic: Topic, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Topic>> {
            const localVarAxiosArgs = await TopicApiAxiosParamCreator(configuration).replaceOneTopic(id, topic, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update multiple Topics
         * @param {Array<Topic>} topic 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateManyTopics(topic: Array<Topic>, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Topic>>> {
            const localVarAxiosArgs = await TopicApiAxiosParamCreator(configuration).updateManyTopics(topic, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update a single Topic
         * @param {number} id 
         * @param {Topic} topic 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOneTopic(id: number, topic: Topic, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Topic>> {
            const localVarAxiosArgs = await TopicApiAxiosParamCreator(configuration).updateOneTopic(id, topic, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TopicApi - factory interface
 * @export
 */
export const TopicApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get Topics total
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countTopics(acceptLanguage?: string, options?: any): AxiosPromise<number> {
            return TopicApiFp(configuration).countTopics(acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create multiple Topics
         * @param {CreateManyTopicDto} createManyTopicDto 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManyTopic(createManyTopicDto: CreateManyTopicDto, acceptLanguage?: string, options?: any): AxiosPromise<Array<Topic>> {
            return TopicApiFp(configuration).createManyTopic(createManyTopicDto, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a single Topic
         * @param {Topic} topic 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneTopic(topic: Topic, acceptLanguage?: string, options?: any): AxiosPromise<Topic> {
            return TopicApiFp(configuration).createOneTopic(topic, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete multiple Topics
         * @param {Array<string>} requestBody 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyTopics(requestBody: Array<string>, acceptLanguage?: string, options?: any): AxiosPromise<void> {
            return TopicApiFp(configuration).deleteManyTopics(requestBody, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a single Topic
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneTopic(id: number, acceptLanguage?: string, options?: any): AxiosPromise<Topic> {
            return TopicApiFp(configuration).deleteOneTopic(id, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple Topics
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyTopic(acceptLanguage?: string, fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<GetManyTopicResponseDto> {
            return TopicApiFp(configuration).getManyTopic(acceptLanguage, fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single Topic
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneTopic(id: number, acceptLanguage?: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<Topic> {
            return TopicApiFp(configuration).getOneTopic(id, acceptLanguage, fields, join, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Replace a single Topic
         * @param {number} id 
         * @param {Topic} topic 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceOneTopic(id: number, topic: Topic, acceptLanguage?: string, options?: any): AxiosPromise<Topic> {
            return TopicApiFp(configuration).replaceOneTopic(id, topic, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update multiple Topics
         * @param {Array<Topic>} topic 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateManyTopics(topic: Array<Topic>, acceptLanguage?: string, options?: any): AxiosPromise<Array<Topic>> {
            return TopicApiFp(configuration).updateManyTopics(topic, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a single Topic
         * @param {number} id 
         * @param {Topic} topic 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneTopic(id: number, topic: Topic, acceptLanguage?: string, options?: any): AxiosPromise<Topic> {
            return TopicApiFp(configuration).updateOneTopic(id, topic, acceptLanguage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for countTopics operation in TopicApi.
 * @export
 * @interface TopicApiCountTopicsRequest
 */
export interface TopicApiCountTopicsRequest {
    /**
     * Request language
     * @type {string}
     * @memberof TopicApiCountTopics
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for createManyTopic operation in TopicApi.
 * @export
 * @interface TopicApiCreateManyTopicRequest
 */
export interface TopicApiCreateManyTopicRequest {
    /**
     * 
     * @type {CreateManyTopicDto}
     * @memberof TopicApiCreateManyTopic
     */
    readonly createManyTopicDto: CreateManyTopicDto

    /**
     * Request language
     * @type {string}
     * @memberof TopicApiCreateManyTopic
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for createOneTopic operation in TopicApi.
 * @export
 * @interface TopicApiCreateOneTopicRequest
 */
export interface TopicApiCreateOneTopicRequest {
    /**
     * 
     * @type {Topic}
     * @memberof TopicApiCreateOneTopic
     */
    readonly topic: Topic

    /**
     * Request language
     * @type {string}
     * @memberof TopicApiCreateOneTopic
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for deleteManyTopics operation in TopicApi.
 * @export
 * @interface TopicApiDeleteManyTopicsRequest
 */
export interface TopicApiDeleteManyTopicsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof TopicApiDeleteManyTopics
     */
    readonly requestBody: Array<string>

    /**
     * Request language
     * @type {string}
     * @memberof TopicApiDeleteManyTopics
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for deleteOneTopic operation in TopicApi.
 * @export
 * @interface TopicApiDeleteOneTopicRequest
 */
export interface TopicApiDeleteOneTopicRequest {
    /**
     * 
     * @type {number}
     * @memberof TopicApiDeleteOneTopic
     */
    readonly id: number

    /**
     * Request language
     * @type {string}
     * @memberof TopicApiDeleteOneTopic
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for getManyTopic operation in TopicApi.
 * @export
 * @interface TopicApiGetManyTopicRequest
 */
export interface TopicApiGetManyTopicRequest {
    /**
     * Request language
     * @type {string}
     * @memberof TopicApiGetManyTopic
     */
    readonly acceptLanguage?: string

    /**
     * Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof TopicApiGetManyTopic
     */
    readonly fields?: Array<string>

    /**
     * Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {string}
     * @memberof TopicApiGetManyTopic
     */
    readonly s?: string

    /**
     * Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof TopicApiGetManyTopic
     */
    readonly filter?: Array<string>

    /**
     * Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof TopicApiGetManyTopic
     */
    readonly or?: Array<string>

    /**
     * Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof TopicApiGetManyTopic
     */
    readonly sort?: Array<string>

    /**
     * Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof TopicApiGetManyTopic
     */
    readonly join?: Array<string>

    /**
     * Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof TopicApiGetManyTopic
     */
    readonly limit?: number

    /**
     * Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof TopicApiGetManyTopic
     */
    readonly offset?: number

    /**
     * Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof TopicApiGetManyTopic
     */
    readonly page?: number

    /**
     * Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof TopicApiGetManyTopic
     */
    readonly cache?: number
}

/**
 * Request parameters for getOneTopic operation in TopicApi.
 * @export
 * @interface TopicApiGetOneTopicRequest
 */
export interface TopicApiGetOneTopicRequest {
    /**
     * 
     * @type {number}
     * @memberof TopicApiGetOneTopic
     */
    readonly id: number

    /**
     * Request language
     * @type {string}
     * @memberof TopicApiGetOneTopic
     */
    readonly acceptLanguage?: string

    /**
     * Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof TopicApiGetOneTopic
     */
    readonly fields?: Array<string>

    /**
     * Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof TopicApiGetOneTopic
     */
    readonly join?: Array<string>

    /**
     * Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof TopicApiGetOneTopic
     */
    readonly cache?: number
}

/**
 * Request parameters for replaceOneTopic operation in TopicApi.
 * @export
 * @interface TopicApiReplaceOneTopicRequest
 */
export interface TopicApiReplaceOneTopicRequest {
    /**
     * 
     * @type {number}
     * @memberof TopicApiReplaceOneTopic
     */
    readonly id: number

    /**
     * 
     * @type {Topic}
     * @memberof TopicApiReplaceOneTopic
     */
    readonly topic: Topic

    /**
     * Request language
     * @type {string}
     * @memberof TopicApiReplaceOneTopic
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for updateManyTopics operation in TopicApi.
 * @export
 * @interface TopicApiUpdateManyTopicsRequest
 */
export interface TopicApiUpdateManyTopicsRequest {
    /**
     * 
     * @type {Array<Topic>}
     * @memberof TopicApiUpdateManyTopics
     */
    readonly topic: Array<Topic>

    /**
     * Request language
     * @type {string}
     * @memberof TopicApiUpdateManyTopics
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for updateOneTopic operation in TopicApi.
 * @export
 * @interface TopicApiUpdateOneTopicRequest
 */
export interface TopicApiUpdateOneTopicRequest {
    /**
     * 
     * @type {number}
     * @memberof TopicApiUpdateOneTopic
     */
    readonly id: number

    /**
     * 
     * @type {Topic}
     * @memberof TopicApiUpdateOneTopic
     */
    readonly topic: Topic

    /**
     * Request language
     * @type {string}
     * @memberof TopicApiUpdateOneTopic
     */
    readonly acceptLanguage?: string
}

/**
 * TopicApi - object-oriented interface
 * @export
 * @class TopicApi
 * @extends {BaseAPI}
 */
export class TopicApi extends BaseAPI {
    /**
     * 
     * @summary Get Topics total
     * @param {TopicApiCountTopicsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TopicApi
     */
    public countTopics(requestParameters: TopicApiCountTopicsRequest = {}, options?: any) {
        return TopicApiFp(this.configuration).countTopics(requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create multiple Topics
     * @param {TopicApiCreateManyTopicRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TopicApi
     */
    public createManyTopic(requestParameters: TopicApiCreateManyTopicRequest, options?: any) {
        return TopicApiFp(this.configuration).createManyTopic(requestParameters.createManyTopicDto, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a single Topic
     * @param {TopicApiCreateOneTopicRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TopicApi
     */
    public createOneTopic(requestParameters: TopicApiCreateOneTopicRequest, options?: any) {
        return TopicApiFp(this.configuration).createOneTopic(requestParameters.topic, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete multiple Topics
     * @param {TopicApiDeleteManyTopicsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TopicApi
     */
    public deleteManyTopics(requestParameters: TopicApiDeleteManyTopicsRequest, options?: any) {
        return TopicApiFp(this.configuration).deleteManyTopics(requestParameters.requestBody, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a single Topic
     * @param {TopicApiDeleteOneTopicRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TopicApi
     */
    public deleteOneTopic(requestParameters: TopicApiDeleteOneTopicRequest, options?: any) {
        return TopicApiFp(this.configuration).deleteOneTopic(requestParameters.id, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple Topics
     * @param {TopicApiGetManyTopicRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TopicApi
     */
    public getManyTopic(requestParameters: TopicApiGetManyTopicRequest = {}, options?: any) {
        return TopicApiFp(this.configuration).getManyTopic(requestParameters.acceptLanguage, requestParameters.fields, requestParameters.s, requestParameters.filter, requestParameters.or, requestParameters.sort, requestParameters.join, requestParameters.limit, requestParameters.offset, requestParameters.page, requestParameters.cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single Topic
     * @param {TopicApiGetOneTopicRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TopicApi
     */
    public getOneTopic(requestParameters: TopicApiGetOneTopicRequest, options?: any) {
        return TopicApiFp(this.configuration).getOneTopic(requestParameters.id, requestParameters.acceptLanguage, requestParameters.fields, requestParameters.join, requestParameters.cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Replace a single Topic
     * @param {TopicApiReplaceOneTopicRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TopicApi
     */
    public replaceOneTopic(requestParameters: TopicApiReplaceOneTopicRequest, options?: any) {
        return TopicApiFp(this.configuration).replaceOneTopic(requestParameters.id, requestParameters.topic, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update multiple Topics
     * @param {TopicApiUpdateManyTopicsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TopicApi
     */
    public updateManyTopics(requestParameters: TopicApiUpdateManyTopicsRequest, options?: any) {
        return TopicApiFp(this.configuration).updateManyTopics(requestParameters.topic, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a single Topic
     * @param {TopicApiUpdateOneTopicRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TopicApi
     */
    public updateOneTopic(requestParameters: TopicApiUpdateOneTopicRequest, options?: any) {
        return TopicApiFp(this.configuration).updateOneTopic(requestParameters.id, requestParameters.topic, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Users total
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countUsers: async (acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/total/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create multiple Users
         * @param {CreateManyUserDto} createManyUserDto 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManyUser: async (createManyUserDto: CreateManyUserDto, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createManyUserDto' is not null or undefined
            if (createManyUserDto === null || createManyUserDto === undefined) {
                throw new RequiredError('createManyUserDto','Required parameter createManyUserDto was null or undefined when calling createManyUser.');
            }
            const localVarPath = `/users/bulk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createManyUserDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createManyUserDto !== undefined ? createManyUserDto : {})
                : (createManyUserDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a single User
         * @param {User} user 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneUser: async (user: User, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            if (user === null || user === undefined) {
                throw new RequiredError('user','Required parameter user was null or undefined when calling createOneUser.');
            }
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof user !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(user !== undefined ? user : {})
                : (user || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete multiple Users
         * @param {Array<string>} requestBody 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyUsers: async (requestBody: Array<string>, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            if (requestBody === null || requestBody === undefined) {
                throw new RequiredError('requestBody','Required parameter requestBody was null or undefined when calling deleteManyUsers.');
            }
            const localVarPath = `/users/bulk/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof requestBody !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(requestBody !== undefined ? requestBody : {})
                : (requestBody || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a single User
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneUser: async (id: number, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteOneUser.');
            }
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple Users
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyUser: async (acceptLanguage?: string, fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single User
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneUser: async (id: number, acceptLanguage?: string, fields?: Array<string>, join?: Array<string>, cache?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOneUser.');
            }
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Replace a single User
         * @param {number} id 
         * @param {User} user 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceOneUser: async (id: number, user: User, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling replaceOneUser.');
            }
            // verify required parameter 'user' is not null or undefined
            if (user === null || user === undefined) {
                throw new RequiredError('user','Required parameter user was null or undefined when calling replaceOneUser.');
            }
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof user !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(user !== undefined ? user : {})
                : (user || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update multiple Users
         * @param {Array<User>} user 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateManyUsers: async (user: Array<User>, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            if (user === null || user === undefined) {
                throw new RequiredError('user','Required parameter user was null or undefined when calling updateManyUsers.');
            }
            const localVarPath = `/users/bulk/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof user !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(user !== undefined ? user : {})
                : (user || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a single User
         * @param {number} id 
         * @param {User} user 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneUser: async (id: number, user: User, acceptLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateOneUser.');
            }
            // verify required parameter 'user' is not null or undefined
            if (user === null || user === undefined) {
                throw new RequiredError('user','Required parameter user was null or undefined when calling updateOneUser.');
            }
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                if (Array.isArray(localVarQueryParameter[key])) for (const param of localVarQueryParameter[key]) queryParameters.append(key, param); else queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof user !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(user !== undefined ? user : {})
                : (user || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Users total
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countUsers(acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).countUsers(acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create multiple Users
         * @param {CreateManyUserDto} createManyUserDto 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createManyUser(createManyUserDto: CreateManyUserDto, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).createManyUser(createManyUserDto, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create a single User
         * @param {User} user 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOneUser(user: User, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).createOneUser(user, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete multiple Users
         * @param {Array<string>} requestBody 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteManyUsers(requestBody: Array<string>, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).deleteManyUsers(requestBody, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete a single User
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOneUser(id: number, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).deleteOneUser(id, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieve multiple Users
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyUser(acceptLanguage?: string, fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyUserResponseDto>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).getManyUser(acceptLanguage, fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieve a single User
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneUser(id: number, acceptLanguage?: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).getOneUser(id, acceptLanguage, fields, join, cache, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Replace a single User
         * @param {number} id 
         * @param {User} user 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceOneUser(id: number, user: User, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).replaceOneUser(id, user, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update multiple Users
         * @param {Array<User>} user 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateManyUsers(user: Array<User>, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).updateManyUsers(user, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update a single User
         * @param {number} id 
         * @param {User} user 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOneUser(id: number, user: User, acceptLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).updateOneUser(id, user, acceptLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get Users total
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countUsers(acceptLanguage?: string, options?: any): AxiosPromise<number> {
            return UserApiFp(configuration).countUsers(acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create multiple Users
         * @param {CreateManyUserDto} createManyUserDto 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManyUser(createManyUserDto: CreateManyUserDto, acceptLanguage?: string, options?: any): AxiosPromise<Array<User>> {
            return UserApiFp(configuration).createManyUser(createManyUserDto, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a single User
         * @param {User} user 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneUser(user: User, acceptLanguage?: string, options?: any): AxiosPromise<User> {
            return UserApiFp(configuration).createOneUser(user, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete multiple Users
         * @param {Array<string>} requestBody 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyUsers(requestBody: Array<string>, acceptLanguage?: string, options?: any): AxiosPromise<void> {
            return UserApiFp(configuration).deleteManyUsers(requestBody, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a single User
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneUser(id: number, acceptLanguage?: string, options?: any): AxiosPromise<User> {
            return UserApiFp(configuration).deleteOneUser(id, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple Users
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyUser(acceptLanguage?: string, fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<GetManyUserResponseDto> {
            return UserApiFp(configuration).getManyUser(acceptLanguage, fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single User
         * @param {number} id 
         * @param {string} [acceptLanguage] Request language
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneUser(id: number, acceptLanguage?: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<User> {
            return UserApiFp(configuration).getOneUser(id, acceptLanguage, fields, join, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Replace a single User
         * @param {number} id 
         * @param {User} user 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceOneUser(id: number, user: User, acceptLanguage?: string, options?: any): AxiosPromise<User> {
            return UserApiFp(configuration).replaceOneUser(id, user, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update multiple Users
         * @param {Array<User>} user 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateManyUsers(user: Array<User>, acceptLanguage?: string, options?: any): AxiosPromise<Array<User>> {
            return UserApiFp(configuration).updateManyUsers(user, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a single User
         * @param {number} id 
         * @param {User} user 
         * @param {string} [acceptLanguage] Request language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneUser(id: number, user: User, acceptLanguage?: string, options?: any): AxiosPromise<User> {
            return UserApiFp(configuration).updateOneUser(id, user, acceptLanguage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for countUsers operation in UserApi.
 * @export
 * @interface UserApiCountUsersRequest
 */
export interface UserApiCountUsersRequest {
    /**
     * Request language
     * @type {string}
     * @memberof UserApiCountUsers
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for createManyUser operation in UserApi.
 * @export
 * @interface UserApiCreateManyUserRequest
 */
export interface UserApiCreateManyUserRequest {
    /**
     * 
     * @type {CreateManyUserDto}
     * @memberof UserApiCreateManyUser
     */
    readonly createManyUserDto: CreateManyUserDto

    /**
     * Request language
     * @type {string}
     * @memberof UserApiCreateManyUser
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for createOneUser operation in UserApi.
 * @export
 * @interface UserApiCreateOneUserRequest
 */
export interface UserApiCreateOneUserRequest {
    /**
     * 
     * @type {User}
     * @memberof UserApiCreateOneUser
     */
    readonly user: User

    /**
     * Request language
     * @type {string}
     * @memberof UserApiCreateOneUser
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for deleteManyUsers operation in UserApi.
 * @export
 * @interface UserApiDeleteManyUsersRequest
 */
export interface UserApiDeleteManyUsersRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof UserApiDeleteManyUsers
     */
    readonly requestBody: Array<string>

    /**
     * Request language
     * @type {string}
     * @memberof UserApiDeleteManyUsers
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for deleteOneUser operation in UserApi.
 * @export
 * @interface UserApiDeleteOneUserRequest
 */
export interface UserApiDeleteOneUserRequest {
    /**
     * 
     * @type {number}
     * @memberof UserApiDeleteOneUser
     */
    readonly id: number

    /**
     * Request language
     * @type {string}
     * @memberof UserApiDeleteOneUser
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for getManyUser operation in UserApi.
 * @export
 * @interface UserApiGetManyUserRequest
 */
export interface UserApiGetManyUserRequest {
    /**
     * Request language
     * @type {string}
     * @memberof UserApiGetManyUser
     */
    readonly acceptLanguage?: string

    /**
     * Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof UserApiGetManyUser
     */
    readonly fields?: Array<string>

    /**
     * Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {string}
     * @memberof UserApiGetManyUser
     */
    readonly s?: string

    /**
     * Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof UserApiGetManyUser
     */
    readonly filter?: Array<string>

    /**
     * Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof UserApiGetManyUser
     */
    readonly or?: Array<string>

    /**
     * Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof UserApiGetManyUser
     */
    readonly sort?: Array<string>

    /**
     * Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof UserApiGetManyUser
     */
    readonly join?: Array<string>

    /**
     * Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof UserApiGetManyUser
     */
    readonly limit?: number

    /**
     * Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof UserApiGetManyUser
     */
    readonly offset?: number

    /**
     * Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof UserApiGetManyUser
     */
    readonly page?: number

    /**
     * Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof UserApiGetManyUser
     */
    readonly cache?: number
}

/**
 * Request parameters for getOneUser operation in UserApi.
 * @export
 * @interface UserApiGetOneUserRequest
 */
export interface UserApiGetOneUserRequest {
    /**
     * 
     * @type {number}
     * @memberof UserApiGetOneUser
     */
    readonly id: number

    /**
     * Request language
     * @type {string}
     * @memberof UserApiGetOneUser
     */
    readonly acceptLanguage?: string

    /**
     * Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof UserApiGetOneUser
     */
    readonly fields?: Array<string>

    /**
     * Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {Array<string>}
     * @memberof UserApiGetOneUser
     */
    readonly join?: Array<string>

    /**
     * Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @type {number}
     * @memberof UserApiGetOneUser
     */
    readonly cache?: number
}

/**
 * Request parameters for replaceOneUser operation in UserApi.
 * @export
 * @interface UserApiReplaceOneUserRequest
 */
export interface UserApiReplaceOneUserRequest {
    /**
     * 
     * @type {number}
     * @memberof UserApiReplaceOneUser
     */
    readonly id: number

    /**
     * 
     * @type {User}
     * @memberof UserApiReplaceOneUser
     */
    readonly user: User

    /**
     * Request language
     * @type {string}
     * @memberof UserApiReplaceOneUser
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for updateManyUsers operation in UserApi.
 * @export
 * @interface UserApiUpdateManyUsersRequest
 */
export interface UserApiUpdateManyUsersRequest {
    /**
     * 
     * @type {Array<User>}
     * @memberof UserApiUpdateManyUsers
     */
    readonly user: Array<User>

    /**
     * Request language
     * @type {string}
     * @memberof UserApiUpdateManyUsers
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for updateOneUser operation in UserApi.
 * @export
 * @interface UserApiUpdateOneUserRequest
 */
export interface UserApiUpdateOneUserRequest {
    /**
     * 
     * @type {number}
     * @memberof UserApiUpdateOneUser
     */
    readonly id: number

    /**
     * 
     * @type {User}
     * @memberof UserApiUpdateOneUser
     */
    readonly user: User

    /**
     * Request language
     * @type {string}
     * @memberof UserApiUpdateOneUser
     */
    readonly acceptLanguage?: string
}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary Get Users total
     * @param {UserApiCountUsersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public countUsers(requestParameters: UserApiCountUsersRequest = {}, options?: any) {
        return UserApiFp(this.configuration).countUsers(requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create multiple Users
     * @param {UserApiCreateManyUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createManyUser(requestParameters: UserApiCreateManyUserRequest, options?: any) {
        return UserApiFp(this.configuration).createManyUser(requestParameters.createManyUserDto, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a single User
     * @param {UserApiCreateOneUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createOneUser(requestParameters: UserApiCreateOneUserRequest, options?: any) {
        return UserApiFp(this.configuration).createOneUser(requestParameters.user, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete multiple Users
     * @param {UserApiDeleteManyUsersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteManyUsers(requestParameters: UserApiDeleteManyUsersRequest, options?: any) {
        return UserApiFp(this.configuration).deleteManyUsers(requestParameters.requestBody, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a single User
     * @param {UserApiDeleteOneUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteOneUser(requestParameters: UserApiDeleteOneUserRequest, options?: any) {
        return UserApiFp(this.configuration).deleteOneUser(requestParameters.id, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple Users
     * @param {UserApiGetManyUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getManyUser(requestParameters: UserApiGetManyUserRequest = {}, options?: any) {
        return UserApiFp(this.configuration).getManyUser(requestParameters.acceptLanguage, requestParameters.fields, requestParameters.s, requestParameters.filter, requestParameters.or, requestParameters.sort, requestParameters.join, requestParameters.limit, requestParameters.offset, requestParameters.page, requestParameters.cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single User
     * @param {UserApiGetOneUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getOneUser(requestParameters: UserApiGetOneUserRequest, options?: any) {
        return UserApiFp(this.configuration).getOneUser(requestParameters.id, requestParameters.acceptLanguage, requestParameters.fields, requestParameters.join, requestParameters.cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Replace a single User
     * @param {UserApiReplaceOneUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public replaceOneUser(requestParameters: UserApiReplaceOneUserRequest, options?: any) {
        return UserApiFp(this.configuration).replaceOneUser(requestParameters.id, requestParameters.user, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update multiple Users
     * @param {UserApiUpdateManyUsersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateManyUsers(requestParameters: UserApiUpdateManyUsersRequest, options?: any) {
        return UserApiFp(this.configuration).updateManyUsers(requestParameters.user, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a single User
     * @param {UserApiUpdateOneUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateOneUser(requestParameters: UserApiUpdateOneUserRequest, options?: any) {
        return UserApiFp(this.configuration).updateOneUser(requestParameters.id, requestParameters.user, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }
}


