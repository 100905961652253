import { RESET } from 'jotai/utils'
import { SetStateAction } from 'jotai';
import { getSafeAreas, THEMES } from 'src/constants'
import { ThemeConfigI } from 'src/theme';
import atomWithLocalStorage from 'src/lib/atomWithLocalStorage';
import { StoreI } from 'src/store';

export interface SettingsStoreI {
    isLoading?: boolean;
    debug?: string;
    compact?: boolean;
    safeArea?: { top: number, left: number, right: number, bottom: number };
}

const Atom = atomWithLocalStorage<ThemeConfigI & SettingsStoreI>(
    "SettingsStore",
    {
        isLoading: false,
        debug: null,
        compact: true,
        direction: 'ltr',
        responsiveFontSizes: true,
        roundedCorners: true,
        theme: window.matchMedia('(prefers-color-scheme: dark)').matches
            ? THEMES.DARK
            : THEMES.LIGHT,
        safeArea: await getSafeAreas()
    }
)

const Reducer = (current: SettingsStoreI, setter: (update?: typeof RESET | SetStateAction<SettingsStoreI>) => void) => {
    return {
        set: async (payload: Partial<SettingsStoreI>): Promise<void> => {
            await setter({ ...current, ...payload })
        },

        reset: async (): Promise<void> => {
            await setter(RESET)
        }
    }
}

const SettingsStore: StoreI<ThemeConfigI & SettingsStoreI, typeof Reducer> = { atom: Atom, reducer: Reducer }

export default SettingsStore