import { Capacitor } from '@capacitor/core';
import env from "src/lib/env";
import { isMobile } from 'react-device-detect';
import { SafeArea } from 'capacitor-plugin-safe-area';

export enum THEMES {
  LIGHT = 'LIGHT',
  DARK = 'DARK',
  NATURE = 'NATURE'
};

export const IS_DEV = process.env.NODE_ENV === "development";

export const IS_NATIVE = Capacitor.isNativePlatform();

export const IS_MOBILE = isMobile;
// export const IS_MOBILE = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

export const getSafeAreas = async () => IS_NATIVE ? (await SafeArea.getSafeAreaInsets())?.insets : { top: 0, left: 0, right: 0, bottom: 0 };

export const PASS_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{6,20}$/
// Minimum eight and maximum 10 characters, at least one uppercase letter, one lowercase letter, one number and one special character
// /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,10}$/ 

export const NAV_HEIGHT = 100;

export const API_BASE_URL = IS_DEV && window?.location?.hostname?.includes(env('LR_HOST'))
  ? `https://${env('API_HOST')}.${env('LR_HOST')}`
  : IS_DEV
    ? `http://${env('LOCAL_IP')}:${env('API_PORT')}`
    : `https://${env('API_HOST')}`

export const API_BASE_URL_SOCKET = IS_DEV && window?.location?.hostname?.includes(env('LR_HOST'))
  ? `https://${env('API_HOST')}.${env('LR_HOST')}`
  : IS_DEV
    ? `http://localhost:${env('API_PORT')}`
    : `https://${env('API_HOST')}`

export const APP_HOST = IS_DEV
  ? `http://${env('BACKOFFICE_HOST')}.localhost:${env('NGINX_HTTP_PORT')}`
  : `https://${env('BACKOFFICE_HOST')}`