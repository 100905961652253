import { RESET } from 'jotai/utils'
import { SetStateAction } from 'jotai'
import { Language } from 'src/api/generated';
import Utilities from 'src/lib/utilities';
import atomWithLocalStorage from 'src/lib/atomWithLocalStorage';
import { StoreI } from 'src/store';

const languages = await Utilities.getLanguages();

export interface LocalizationStoreI {
    languages: Language[];
    current: Language;
    primary: Language;
    dateFormat: string;
}

const Atom = atomWithLocalStorage<LocalizationStoreI>(
    "LocalizationStore",
    {
        languages: languages,
        current: languages?.find(lang => lang.primary),
        primary: languages?.find(lang => lang.primary),
        dateFormat: "dd/MM/yyyy"
    }
)

const Reducer = (current: LocalizationStoreI, setter: (update?: typeof RESET | SetStateAction<LocalizationStoreI>) => void) => {
    return {
        set: async (payload: Partial<LocalizationStoreI>): Promise<void> => {
            await setter({ ...current, ...payload })
        },

        reset: async (): Promise<void> => {
            await setter(RESET)
        }
    }
}

const LocalizationStore: StoreI<LocalizationStoreI, typeof Reducer> = { atom: Atom, reducer: Reducer }

export default LocalizationStore