import { createTheme, responsiveFontSizes, ThemeOptions } from '@mui/material/styles';
import type { Direction, Theme } from '@mui/material';
import { THEMES } from 'src/constants';
import { lightShadows, darkShadows } from 'src/theme/shadows';

export interface ThemeConfigI {
  direction?: Direction;
  responsiveFontSizes?: boolean;
  roundedCorners?: boolean;
  theme?: string;
}

const defaultTheme = createTheme({
  breakpoints: {}
});

const baseTheme = createTheme(defaultTheme,
  {
    direction: 'ltr',
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          'body *[class*=css]': {
            fontFamily: 'Lato',
          },
          'h1[class*=css], h2[class*=css], h3[class*=css], h4[class*=css], h5[class*=css], h6[class*=css]': {
            fontFamily: 'Alata',
          },
          html: {
            height: '100%',
            width: '100%',
          },
          body: {
            height: '100%'
          },
          a: {
            textDecoration: 'none'
          },
          '#root': {
            height: '100%',
            width: '100%'
          },
          '#nprogress .bar': {
            zIndex: defaultTheme.zIndex.drawer + 500
          },
          '.MuiSnackbarContent-message': {
            whiteSpace: 'break-spaces'
          },
          /**
           * DataGrid
          */
          '.MuiDataGrid-filterForm .MuiFormControl-root': {
            marginRight: "10px"
          },
          '.MuiDataGrid-filterForm .MuiDataGrid-filterFormOperatorInput, .MuiDataGrid-filterForm .MuiDataGrid-filterFormColumnInput': {
            minWidth: "15%"
          },
          '.MuiDataGrid-filterForm .MuiDataGrid-filterFormLinkOperatorInput': {
            minWidth: "10%"
          },
          '.MuiDataGrid-filterForm .MuiFormControl-root:last-child': {
            width: "100%",
            maxWidth: "650px"
          },
          '.MuiDataGrid-filterFormOperatorInput select': {
            textTransform: 'capitalize!important'
          },
          '.MuiDataGrid-panel, .MuiDataGrid-panelWrapper': {
            width: `calc(100vw - 280px - ${defaultTheme.spacing(6)})`, // vw - sidebar width - layout spacing
            maxWidth: "1370px"
          },
          '.MuiDataGrid-main>div[style*=absolute]': { // MUI X Pro dev license notice hide
            display: 'none'
          },
          [defaultTheme.breakpoints.down('lg')]: {
            '.MuiDataGrid-panel, .MuiDataGrid-panelWrapper': {
              width: `calc(100vw - ${defaultTheme.spacing(6)})` // vw - sidebar width - layout spacing
              // maxWidth: `calc(100vw - ${defaultTheme.spacing(2)})`,
              // left: `-${defaultTheme.spacing(1)}!important`
            },
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none'
          }
        }
      },
      MuiPopover: {
        styleOverrides: {
          root: {
            zIndex: defaultTheme.zIndex.drawer + 500
          }
        }
      },
      MuiCardHeader: {
        defaultProps: {
          titleTypographyProps: {
            variant: 'h6'
          }
        }
      },
      MuiLinearProgress: {
        styleOverrides: {
          root: {
            borderRadius: 3,
            overflow: 'hidden'
          }
        }
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            minWidth: 'auto',
            marginRight: '15px'
          }
        }
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundImage: 'none'
          }
        }
      },
      MuiDataGrid: {
        styleOverrides: {
          root: {
            '& *:focus, & *:focus-within': {
              outline: 'none!important'
            }
          }
        }
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            whiteSpace: 'break-spaces'
          }
        }
      }
    },
    typography: {
      button: {
        fontWeight: 600
      },
      h1: {
        fontWeight: 600,
        fontSize: '3.5rem'
      },
      h2: {
        fontWeight: 600,
        fontSize: '3rem'
      },
      h3: {
        fontWeight: 600,
        fontSize: '2.25rem'
      },
      h4: {
        fontWeight: 600,
        fontSize: '2rem'
      },
      h5: {
        fontWeight: 600,
        fontSize: '1.5rem'
      },
      h6: {
        fontWeight: 600,
        fontSize: '1.125rem'
      },
      overline: {
        fontWeight: 600
      }
    }
  } as ThemeOptions
);

const customThemeGetters: Record<string, () => Theme> = {
  [THEMES.LIGHT]: () => {
    const options: ThemeOptions = {
      shadows: lightShadows,
      components: {
        MuiInputBase: {
          styleOverrides: {
            input: {
              '&::placeholder': {
                opacity: 0.86,
                color: '#42526e'
              },
              '&:-webkit-autofill': {
                WebkitBoxShadow: '0 0 0px 1000px #ffffff inset!important',
                WebkitTextFillColor: '#172b4d',
                caretColor: '#172b4d'
              },
            }
          }
        }
      },
      palette: {
        mode: 'light',
        action: {
          active: '#6b778c'
        },
        background: {
          default: '#f4f5f7',
          paper: '#ffffff'
        },
        error: {
          contrastText: '#ffffff',
          main: '#f44336'
        },
        primary: {
          contrastText: '#ffffff',
          main: '#5664d2'
        },
        success: {
          contrastText: '#ffffff',
          main: '#4caf50'
        },
        text: {
          primary: '#172b4d',
          secondary: '#6b778c'
        },
        warning: {
          contrastText: '#ffffff',
          main: '#ff9800'
        }
      }
    }
    return createTheme(options);
  },
  [THEMES.DARK]: () => {
    const options: ThemeOptions = {
      shadows: darkShadows,
      components: {
        MuiInputBase: {
          styleOverrides: {
            input: {
              '&:-webkit-autofill': {
                WebkitBoxShadow: "0 0 0px 1000px #222b36 inset!important",
              }
            }
          }
        }
      },
      palette: {
        mode: 'dark',
        background: {
          default: '#171c24',
          paper: '#222b36'
        },
        divider: 'rgba(145, 158, 171, 0.24)',
        error: {
          contrastText: '#ffffff',
          main: '#f44336'
        },
        primary: {
          contrastText: '#ffffff',
          main: '#688eff'
        },
        success: {
          contrastText: '#ffffff',
          main: '#4caf50'
        },
        text: {
          primary: '#ffffff',
          secondary: '#919eab',
        },
        warning: {
          contrastText: '#ffffff',
          main: '#ff9800'
        }
      }
    }
    return createTheme(options);
  },
  [THEMES.NATURE]: () => {
    const options: ThemeOptions = {
      shadows: darkShadows,
      components: {
        MuiInputBase: {
          styleOverrides: {
            input: {
              '&:-webkit-autofill': {
                WebkitBoxShadow: '0 0 0px 1000px #293142 inset!important'
              }
            }
          }
        }
      },
      palette: {
        mode: 'dark',
        background: {
          default: '#1c2531',
          paper: '#293142'
        },
        divider: 'rgba(145, 158, 171, 0.24)',
        error: {
          contrastText: '#ffffff',
          main: '#f44336'
        },
        primary: {
          contrastText: '#ffffff',
          main: '#01ab56'
        },
        success: {
          contrastText: '#ffffff',
          main: '#4caf50'
        },
        text: {
          primary: '#ffffff',
          secondary: '#919eab'
        },
        warning: {
          contrastText: '#ffffff',
          main: '#ff9800'
        }
      }
    }
    return createTheme(options);
  }
};

export const createCustomTheme = (config: ThemeConfigI = {}): Theme => {
  let getCustomTheme = customThemeGetters[config.theme];

  if (config.theme && !getCustomTheme) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    getCustomTheme = customThemeGetters[THEMES.LIGHT];
  }

  let finalTheme = createTheme(
    baseTheme,
    getCustomTheme(),
    {
      direction: config.direction,
      shape: config.roundedCorners ? { borderRadius: 16 } : {}
    }
  );

  if (config.responsiveFontSizes)
    finalTheme = responsiveFontSizes(finalTheme);

  return finalTheme;
};

