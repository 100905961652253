import { FC, useState } from 'react';
import { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Alert, Box, Button, Container, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import gtm from 'src/lib/gtm';
import env from 'src/lib/env';
import { useTranslation } from 'react-i18next';
import SuspenseLoader from 'src/components/SuspenseLoader';
import useIsSmallScreen from 'src/hooks/useIsSmallScreen';
import { mailApi } from 'src/api/api';
import { useAtom } from 'jotai';
import { AuthStore } from 'src/store';
import Utilities from 'src/lib/utilities';
import { makeToast } from 'src/components/Toast';

const ActivationRequired: FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useIsSmallScreen();
  const [authStore, setAuthStore] = useAtom(AuthStore.atom)
  const authDispatch = AuthStore.reducer(authStore, setAuthStore);
  const user = authStore.user;
  const [isPending, setIsPending] = useState<boolean>(false);

  const handleActivateUser = async () => {
    try {
      setIsPending(true)
      await mailApi().activateUser({ emailDto: { email: user.email } });
      await authDispatch.logout();
      makeToast(`${t('common:messages.email_sent').capitalize()} ${t("glossary:to")} ${user.email}.`)
    } catch (error) {
      console.error(error)
      makeToast(Utilities.handleError(error).capitalize())
    }
    setIsPending(false)
  }

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  return (
    <SuspenseLoader loading={!user || isPending}>
      <Helmet>
        <title>{`${t('glossary:error').capitalize()}: 400 | ${env('PROJECT_SLUG')?.toUpperCase()}`}</title>
      </Helmet>
      <Box sx={{ alignItems: 'center', backgroundColor: 'background.paper', display: 'flex', minHeight: '100%', px: 3, py: '80px' }}>
        <Container maxWidth="lg">

          <Typography align="center" color="textPrimary" variant={isSmallScreen ? 'h4' : 'h2'}>
            400: Activation required
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 6 }}>
            <Box
              alt="Under development"
              component="img"
              src={`/static/error/error401_${theme.palette.mode}.svg`}
              sx={{ height: 'auto', maxWidth: '100%', width: 400 }} />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 6 }}>
            <Button color="primary" onClick={handleActivateUser} variant="outlined" sx={{ mx: 1 }}>
              {t('glossary:activate_user').capitalize()}
            </Button>
            <Button color="primary" onClick={async () => await authDispatch.logout()} variant="outlined" sx={{ mx: 1 }}>
              {t('glossary:login').capitalize()}
            </Button>
          </Box>
        </Container>
      </Box>
    </SuspenseLoader>
  );
};

export default ActivationRequired;
