import { useAtom } from 'jotai';
import type { FC, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import ActivationRequired from 'src/pages/ActivationRequired';
import { AuthStore } from 'src/store';

interface ActivationGuardProps {
  children?: ReactNode;
}

const ActivationGuard: FC<ActivationGuardProps> = ({ children }) => {
  const [authStore,] = useAtom(AuthStore.atom)
  const isActive = authStore.user?.isActive;

  if (!isActive) {
    return <ActivationRequired />;
  }

  return <>{children || <Outlet />}</>;
};

export default ActivationGuard;
