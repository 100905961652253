import { useEffect } from 'react';
import type { FC } from 'react';
import NProgress from 'nprogress';
import { Box, CircularProgress, Theme, SxProps } from '@mui/material';

interface LoadingScreenProps {
  sx?: SxProps<Theme>;
}

const LoadingScreen: FC<LoadingScreenProps> = ({ sx }) => {
  useEffect(() => {
    NProgress.start();

    return (): void => {
      NProgress.done();
    };
  }, []);

  return (
    <Box sx={{ backgroundColor: 'background.paper', minHeight: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', ...sx }}>
      <CircularProgress size={70} />
    </Box>
  );
};

export default LoadingScreen;
