import { RESET } from 'jotai/utils'
import { SetStateAction } from 'jotai';
import atomWithLocalStorage from 'src/lib/atomWithLocalStorage';
import { StoreI } from 'src/store';
import { SelectionBox } from '@air/react-drag-to-select';
import { isMobile } from 'react-device-detect';

export interface DragAndSelectStoreI {
    onSelectionChange: (box: SelectionBox) => void,
    onSelectionStart: () => void,
    onSelectionEnd: () => void,
    isEnabled: boolean
}

const Atom = atomWithLocalStorage<DragAndSelectStoreI>(
    "DragAndSelectStore",
    {
        onSelectionChange: null,
        onSelectionStart: null,
        onSelectionEnd: null,
        isEnabled: !isMobile
    },
    {
        excludeFromPersistence: ['isEnabled']
    }
)

const Reducer = (current: DragAndSelectStoreI, setter: (update?: typeof RESET | SetStateAction<DragAndSelectStoreI>) => void) => {
    return {
        set: async (payload: Partial<DragAndSelectStoreI>): Promise<void> => {
            await setter({ ...current, ...payload })
        },

        reset: async (): Promise<void> => {
            await setter(RESET)
        },
    }
}

const DragAndSelectStore: StoreI<DragAndSelectStoreI, typeof Reducer> = { atom: Atom, reducer: Reducer }

export default DragAndSelectStore