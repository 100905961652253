import { useEffect } from 'react';
import type { FC } from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import RTL from 'src/components/RTL';
import useScrollReset from 'src/hooks/useScrollReset';
import routes from 'src/routes';
import { createCustomTheme } from 'src/theme';
import { ConfirmProvider } from 'material-ui-confirm';
import SettingsDrawer from 'src/components/SettingsDrawer';
import { useAtom } from 'jotai';
import { useAtomDevtools, useAtomsDebugValue } from 'jotai-devtools';
import { AuthStore, SettingsStore } from 'src/store';
import { useRoutes } from 'react-router-dom';
import SplashScreen from 'src/components/SplashScreen';
import gtm from 'src/lib/gtm';
import env from 'src/lib/env';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import 'src/i18n';
import { LocalizationStore } from 'src/store';
import { useTranslation } from 'react-i18next';
import Lightbox from 'src/components/Lightbox';
import LoadingScreen from 'src/components/LoadingScreen';

// MUI X Pro
LicenseInfo.setLicenseKey(env('MUI_X_PRO_KEY'));

const App: FC = () => {
  const { i18n } = useTranslation();
  const content = useRoutes(routes);
  const [settings,] = useAtom(SettingsStore.atom)
  const [authStore, setAuthStore] = useAtom(AuthStore.atom)
  const [localizationStore,] = useAtom(LocalizationStore.atom)
  const authDispatch = AuthStore.reducer(authStore, setAuthStore);
  const theme = createCustomTheme(settings);

  useEffect(() => {
    i18n.changeLanguage(localizationStore.current?.code)
  }, [localizationStore.current])

  useEffect(() => {
    authDispatch.initialize();
    gtm.initialize({ containerId: env('GTM_ID') });
  }, []);

  // Debug Jotai in React Dev Tools
  // const DebugAtoms = () => { useAtomsDebugValue(); return null }
  // Debug Jotai in Redux Dev Tools
  // useAtomDevtools(SettingsStore.atom)

  useScrollReset();

  return (
    <ThemeProvider theme={theme}>
      <ConfirmProvider>
        <RTL direction={settings.direction || 'ltr'}>
          {/* <DebugAtoms /> */}
          <CssBaseline />
          <SettingsDrawer />
          <Lightbox />
          {authStore.isInitialized ? content : <SplashScreen />}
          {settings.isLoading && <LoadingScreen sx={{ position: "fixed", width: "100%", top: 0, left: 0, zIndex: theme => theme.zIndex.drawer + 500, background: theme => `${theme.palette.background.paper}60` }} />}
        </RTL>
      </ConfirmProvider>
    </ThemeProvider>
  );
};

export default App;
