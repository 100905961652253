export { }

// Extend prototypes

String.prototype.capitalize = function (this: string) {
    return this.charAt(0).toUpperCase() + this.slice(1)
}

String.prototype.parseInt = function (this: string) {
    return parseInt(this)
}

Array.prototype.first = function (this: Array<unknown>) {
    return this?.[0]
}