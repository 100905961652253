import { useEffect, useState } from 'react';
import type { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Container, Grid, Typography, useTheme } from '@mui/material';
import gtm from 'src/lib/gtm';
import env from 'src/lib/env';
import { useAtom } from 'jotai';
import { AuthStore, SettingsStore } from 'src/store';
import { useTranslation } from 'react-i18next';
import Tilt, { ReactParallaxTiltProps } from 'react-parallax-tilt';
import useClasses from 'src/hooks/useClasses';
import { RiArtboardLine, RiFoldersFill, RiGalleryLine } from 'react-icons/ri';
import { FiUsers } from 'react-icons/fi';
import { TbMapPins } from 'react-icons/tb';
import { BiNetworkChart, BiPaint } from 'react-icons/bi';
import { Link as RouterLink } from 'react-router-dom';
import { FaRegAddressBook } from 'react-icons/fa';
import { MdOutlineDocumentScanner } from 'react-icons/md';
import useIsSmallScreen from 'src/hooks/useIsSmallScreen';

const Dashboard: FC = () => {
    const { t } = useTranslation()
    const classes = useClasses()
    const theme = useTheme()
    const isSmallScreen = useIsSmallScreen()
    const [settings,] = useAtom(SettingsStore.atom)
    const [authStore,] = useAtom(AuthStore.atom)

    const tiltProps: ReactParallaxTiltProps = {
        className: classes.tiltParallaxGlareScale,
        perspective: 1200,
        glareEnable: true,
        glareMaxOpacity: 0.6,
        // glareBorderRadius: `${theme.shape.borderRadius}px`,
        scale: 1.01,
        tiltEnable: !isSmallScreen
    }
    // const [tiltProps, setTiltProps] = useState<ReactParallaxTiltProps>({})
    // useEffect(() => {
    //     setTiltProps({
    //         className: classes.tiltParallaxGlareScale,
    //         perspective: 1200,
    //         glareEnable: true,
    //         glareMaxOpacity: 0.6,
    //         glareBorderRadius: `${theme.shape.borderRadius}px`,
    //         scale: 1.01,
    //         tiltEnable: !isSmallScreen
    //     })
    // }, [theme])

    useEffect(() => {
        gtm.push({ event: 'page_view' })
    }, []);

    return (
        <>
            <Helmet>
                <title>{`${t('glossary:dashboard').capitalize()} | ${env('PROJECT_SLUG')?.toUpperCase()}`}</title>
            </Helmet>
            <Box sx={{ backgroundColor: "background.default", minHeight: '100%', py: 8, "& .glare-wrapper": { borderRadius: `${theme.shape.borderRadius}px!important` } }}>
                <Container maxWidth={settings.compact ? 'xl' : false} sx={{ textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <Typography color="textSecondary" variant="overline">{t('glossary:dashboard').capitalize()}</Typography>
                    <Typography color="textPrimary" variant="h5">
                        {t('glossary:hi').capitalize()} {authStore.user?.userName}
                    </Typography>
                    <Box sx={{ mt: 3, display: "flex", flexWrap: "wrap", justifyContent: "center", alignItems: "center", maxWidth: "900px", padding: "20px" }}>
                        <Tilt {...tiltProps}>
                            <Box className="inner-element" component={RouterLink} to="/dashboard/artworks">
                                <RiArtboardLine />
                                <Typography>{t('glossary:artworks').capitalize()}</Typography>
                            </Box>
                        </Tilt>
                        <Tilt {...tiltProps}>
                            <Box className="inner-element" component={RouterLink} to="/dashboard/exhibitions">
                                <RiGalleryLine />
                                <Typography>{t('glossary:exhibitions').capitalize()}</Typography>
                            </Box>
                        </Tilt>
                        <Tilt {...tiltProps}>
                            <Box className="inner-element" component={RouterLink} to="/dashboard/owners">
                                <FaRegAddressBook />
                                <Typography>{t('glossary:owners').capitalize()}</Typography>
                            </Box>
                        </Tilt>
                        <Tilt {...tiltProps}>
                            <Box className="inner-element" component={RouterLink} to="/dashboard/documents">
                                <MdOutlineDocumentScanner />
                                <Typography>{t('glossary:documents').capitalize()}</Typography>
                            </Box>
                        </Tilt>
                        <Tilt {...tiltProps}>
                            <Box className="inner-element" component={RouterLink} to="/dashboard/topics">
                                <BiNetworkChart />
                                <Typography>{t('glossary:topics').capitalize()}</Typography>
                            </Box>
                        </Tilt>
                        <Tilt {...tiltProps}>
                            <Box className="inner-element" component={RouterLink} to="/dashboard/techniques">
                                <BiPaint />
                                <Typography>{t('glossary:techniques').capitalize()}</Typography>
                            </Box>
                        </Tilt>
                        <Tilt {...tiltProps}>
                            <Box className="inner-element" component={RouterLink} to="/dashboard/places">
                                <TbMapPins />
                                <Typography>{t('glossary:places').capitalize()}</Typography>
                            </Box>
                        </Tilt>
                        <Tilt {...tiltProps}>
                            <Box className="inner-element" component={RouterLink} to="/dashboard/filemanager">
                                <RiFoldersFill />
                                <Typography>{t('glossary:filemanager').capitalize()}</Typography>
                            </Box>
                        </Tilt>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default Dashboard;
