import type { FC } from 'react';
import { Theme, Typography, useTheme, Box, SxProps } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import env from 'src/lib/env';

interface LogoProps {
  sx?: SxProps<Theme>;
}

const Logo: FC<LogoProps> = ({ sx }) => {
  const theme: Theme = useTheme();

  return (
    <Box>
      <CardMedia
        component="img"
        alt="logo"
        sx={{ ...sx, objectFit: 'contain' }}
        image={`/static/logo_${theme.palette.mode}_mode.png`}
      />
      {/* <Typography variant="h4" sx={{ color: theme.palette.primary.dark, ...sx }}>{env('TITLE')}</Typography> */}
    </Box>
  );
};

export default Logo;
