import { RESET } from 'jotai/utils'
import { SetStateAction } from 'jotai';
import { ThemeConfigI } from 'src/theme';
import * as api from 'src/api/generated';
import { mediaApi, mediaPathsApi } from 'src/api/api';
import atomWithLocalStorage from 'src/lib/atomWithLocalStorage';
import { StoreI } from 'src/store';

export interface FileManagerStoreI {
    pathId: number;
    paths: api.MediaPath[],
    pathsTree: api.MediaPathTreeDto[],
    media: api.Media[],
    expandedPaths: string[],
    selectedMedia: api.Media[],
    selectedPaths: api.MediaPath[]
    cutMedia: api.Media[],
    cutPaths: api.MediaPath[],
    showHidden: boolean,
    isLoading: boolean,
}

const Atom = atomWithLocalStorage<ThemeConfigI & FileManagerStoreI>(
    "FileManagerStore",
    {
        pathId: null,
        pathsTree: null,
        expandedPaths: ["0"],
        media: [],
        paths: [],
        selectedMedia: [],
        selectedPaths: [],
        cutMedia: [],
        cutPaths: [],
        isLoading: false,
        showHidden: false,
    },
    {
        excludeFromPersistence: ['selected', 'cut', 'isLoading'],
        resetNull: true
    }
)

const Reducer = (current: FileManagerStoreI, setter: (update?: SetStateAction<FileManagerStoreI | typeof RESET>) => void) => {
    return {
        set: async (payload: Partial<FileManagerStoreI>): Promise<void> => {
            await setter({ ...current, ...payload })
        },

        reset: async (): Promise<void> => {
            await setter(RESET)
        },

        setPath: async (payload: Partial<FileManagerStoreI> & { reset?: boolean }): Promise<void> => {
            await setter({ ...current, isLoading: true });
            await setter({
                ...current,
                ...payload,
                isLoading: false,
                paths: (await mediaPathsApi().getManyMediaPath({ filter: [payload.pathId > 0 ? `parent.id||$eq||${payload.pathId}` : "parent.id||$isnull"], sort: ["pathname,ASC"], join: ["parent"] }))?.data?.data,
                pathsTree: (await mediaPathsApi().getMediaPathsTree())?.data,
                media: (await mediaApi().getManyMedia({ filter: [payload.pathId > 0 ? `path.id||$eq||${payload.pathId}` : "path.id||$isnull", payload.showHidden ? "" : "hidden||$ne||true"], sort: ["name,ASC"], join: ["path"] }))?.data?.data,
                ...(payload?.reset && {
                    selectedMedia: [],
                    selectedPaths: [],
                    cutMedia: [],
                    cutPaths: [],
                })
            })
        },

        setSelected: async (payload?: { media: api.Media[], paths: api.MediaPath[] }): Promise<void> => {
            const update: FileManagerStoreI = {
                ...current,
                selectedMedia: payload?.media || [],
                selectedPaths: payload?.paths || [],
            }
            await setter(update)
        },

        setCut: async (payload?: { media: api.Media[], paths: api.MediaPath[] }): Promise<void> => {
            await setter({
                ...current,
                cutMedia: payload?.media || [],
                cutPaths: payload?.paths || [],
            })
        },

        setPathsTree: async (): Promise<void> => {
            await setter({ ...current, pathsTree: (await mediaPathsApi().getMediaPathsTree())?.data })
        }
    }
}

const FileManagerStore: StoreI<FileManagerStoreI, typeof Reducer> = { atom: Atom, reducer: Reducer }

export default FileManagerStore