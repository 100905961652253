import { useAtom } from 'jotai';
import { FC, useEffect, useState } from 'react';
import ImageLightbox from 'react-image-lightbox';
import { LightboxStore } from 'src/store';
import { Box, useTheme } from '@mui/material';
import 'react-image-lightbox/style.css';

const Lightbox: FC<any> = () => {
  const theme = useTheme()
  const [lightboxStore, setLightboxStore] = useAtom(LightboxStore.atom)
  const lightboxDispatch = LightboxStore.reducer(lightboxStore, setLightboxStore)
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [current, setCurrent] = useState<string>(null);
  const [next, setNext] = useState<string>(null);
  const [prev, setPrev] = useState<string>(null);

  useEffect(() => {
    const newCurrent = lightboxStore.images?.[lightboxStore.currentIndex] || lightboxStore.images?.[0] || null,
      newNext = lightboxStore.images?.[(lightboxStore.currentIndex + 1) % lightboxStore.images?.length] || null,
      newPrev = lightboxStore.images?.[(lightboxStore.currentIndex + lightboxStore.images?.length - 1) % lightboxStore.images?.length] || null
    setCurrent(newCurrent);
    setNext(newNext);
    setPrev(newPrev);
    setIsOpen(lightboxStore.open)
  }, [lightboxStore])

  return (
    <Box>
      <style>{`
        .ReactModal__Overlay { z-index: ${theme.zIndex.drawer + 1000}!important }
        .ril__loadingContainer { display: flex; align-items: center; justify-content: center; width: 0; margin: 0 auto }
      `}</style>
      {
        !!isOpen && !!current &&
        <ImageLightbox
          mainSrc={current}
          nextSrc={next}
          prevSrc={prev}
          onCloseRequest={() => lightboxDispatch.set({ open: false, currentIndex: 0, images: [] })}
          onMovePrevRequest={() => lightboxDispatch.set({ currentIndex: lightboxStore.images?.findIndex(image => image == prev) })}
          onMoveNextRequest={() => lightboxDispatch.set({ currentIndex: lightboxStore.images?.findIndex(image => image == next) })}
          // fix - avoid infinite loading
          onAfterOpen={() => [...document.querySelectorAll('.ril-not-loaded')]?.map((x: HTMLElement) => x.removeAttribute('class'))}
          {...({ loader: <img className='ril-image-current ril__image' src={current} style={{ maxHeight: "98vh", maxWidth: "98vw", transform: "translateX(-50%)" }} /> })}
        />
      }
    </Box>
  );
};

export default Lightbox;
