import React, { FC, useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import App from 'src/App'
import { BrowserRouter } from 'react-router-dom'
import StyledEngineProvider from '@mui/material/StyledEngineProvider'
import { HelmetProvider } from 'react-helmet-async'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { ErrorBoundary } from "react-error-boundary";
import 'src/App.css'
import 'src/global.utils'

const Fallback: FC<{ error: any; resetErrorBoundary: (...args: any[]) => void }> = ({ error, resetErrorBoundary }) => {
  // useEffect(() => { resetErrorBoundary() }, [])
  return <div role="alert" style={{ padding: "30px" }}><p>Error:</p><pre style={{ color: "red", whiteSpace: "break-spaces" }}>{error.message}</pre></div>
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <StyledEngineProvider injectFirst>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ErrorBoundary
              FallbackComponent={Fallback}
              onReset={(details) => { console.log(details) }}
            >
              <App />
            </ErrorBoundary>
          </LocalizationProvider>
        </StyledEngineProvider>
      </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>
)
