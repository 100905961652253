import { useRef, useState } from 'react';
import type { FC } from 'react';
import { Box, IconButton, ListItemIcon, ListItemText, MenuItem, Popover, Typography } from '@mui/material';
import { hasFlag } from 'country-flag-icons';
import Flags from 'country-flag-icons/react/3x2';
import { Language } from 'src/api/generated';
import { useAtom } from 'jotai';
import { LocalizationStore } from 'src/store';
import { BsQuestionSquareFill } from 'react-icons/bs';

const LanguagePopover: FC = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [localizationStore, setLocalizationStore] = useAtom(LocalizationStore.atom)
  const languages: Record<string, Language> = localizationStore.languages.reduce((acc: Record<string, Language>, curr: Language) => { acc[curr.code] = curr; return acc }, {})
  const getFlag = (code?: string): FC => code && hasFlag(code) ? Flags[code] : Flags['GB']
  // const getFlag = (code?: string): FC => code && hasFlag(code) ? Flags[code] : BsQuestionSquareFill

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleChangeLanguage = (language: Language): void => {
    setLocalizationStore({ ...localizationStore, current: language })
    setOpen(false);
  };

  const SelectedFlag = getFlag(localizationStore.current?.code?.toUpperCase());

  return (
    <Box>
      <IconButton onClick={handleOpen} ref={anchorRef}>
        <Box sx={{ display: 'flex', alignItems: 'center', height: 20, width: 20, minWidth: '0!important', '& img, & svg': { width: '100%' } }}>
          <SelectedFlag />
        </Box>
      </IconButton>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{ sx: { width: 240, zIndex: (theme) => theme.zIndex.drawer + 500 } }}
      >
        {
          Object.entries(languages).map(([code, language]) => {
            const Flag = getFlag(code?.toUpperCase());
            return (
              <MenuItem onClick={() => handleChangeLanguage(language)} key={code}>
                <ListItemIcon sx={{ display: 'flex', alignItems: 'center', height: 20, width: 20, minWidth: '0!important', '& img, & svg': { width: '100%' } }}>
                  <Flag />
                </ListItemIcon>
                <ListItemText
                  primary={(
                    <Typography color="textPrimary" variant="subtitle2">
                      {language.label}
                    </Typography>
                  )}
                />
              </MenuItem>
            )
          })
        }
      </Popover>
    </Box>
  );
};

export default LanguagePopover;
