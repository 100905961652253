import { useAtom } from 'jotai';
import { FC, ReactNode } from 'react';
import { useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import Login from 'src/pages/authentication/Login';
import { AuthStore } from 'src/store';

interface AuthGuardProps {
  children?: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
  const [authStore,] = useAtom(AuthStore.atom)
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>();

  if (!authStore.isAuthenticated) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }

    return <Login />;
  }

  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children || <Outlet />}</>;
};

export default AuthGuard;
