import { useEffect, useState } from 'react';
import type { FC } from 'react';
import { Box, Button, Drawer, Fab, FormControlLabel, Switch, TextField, Tooltip, Typography } from '@mui/material';
import { THEMES } from 'src/constants';
import { HiAdjustments } from 'react-icons/hi';
import { useAtom } from 'jotai';
import { SettingsStore } from 'src/store';
import { useTranslation } from 'react-i18next';
import LanguagePopover from 'src/components/LanguagePopover';

const SettingsDrawer: FC = () => {
  const { t } = useTranslation();
  const [settings, setSettings] = useAtom(SettingsStore.atom)
  const [open, setOpen] = useState<boolean>(false);
  const [values, setValues] = useState(settings);

  useEffect(() => {
    setValues(settings);
  }, [settings]);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleChange = (field: string, value: string | boolean): void => {
    setValues({
      ...values,
      [field]: value
    });
  };

  const handleSave = (): void => {
    setSettings(values);
    setOpen(false);
  };

  return <>
    <Tooltip title={t('glossary:settings').capitalize()}>
      <Fab
        color="primary"
        onClick={handleOpen}
        size="medium"
        sx={{ bottom: 0, margin: (theme) => theme.spacing(4), position: 'fixed', right: 0, zIndex: (theme) => theme.zIndex.speedDial }}
      >
        <HiAdjustments />
      </Fab>
    </Tooltip>
    <Drawer anchor="right" onClose={handleClose} open={open} sx={{ zIndex: (theme) => theme.zIndex.drawer + 200 }} PaperProps={{ sx: { p: 2, width: 370, maxWidth: '80vw' } }}>
      <Typography color="textPrimary" variant="h6">
        {t('glossary:settings').capitalize()}
      </Typography>
      <Box sx={{ mt: 2 }}>
        <LanguagePopover />
      </Box>
      <Box sx={{ mt: 3 }}>
        <TextField
          fullWidth
          label={t('glossary:theme').capitalize()}
          name="theme"
          onChange={(event): void => handleChange('theme', event.target.value)}
          select
          SelectProps={{ native: true }}
          value={values.theme}
          variant="outlined"
        >
          {Object.keys(THEMES).map((theme) => (
            <option key={theme} value={theme} >
              {theme.replace(/_/g, ' ').toLowerCase().capitalize()}
            </option>
          ))}
        </TextField>
      </Box>
      <Box sx={{ mt: 2, px: 1.5 }} >
        <FormControlLabel
          control={(
            <Switch
              checked={values.direction === 'rtl'}
              color="primary"
              edge="start"
              name="direction"
              onChange={(event): void => handleChange('direction', event.target.checked ? 'rtl' : 'ltr')}
            />
          )}
          label={(
            <div>
              {t('glossary:rtl').toUpperCase()}
              <Typography color="textSecondary" component="p" variant="caption" >
                {t('common:messages.change_text_direction').capitalize()}
              </Typography>
            </div>
          )}
        />
      </Box>
      <Box sx={{ mt: 2, px: 1.5 }} >
        <FormControlLabel
          control={(
            <Switch
              checked={values.responsiveFontSizes}
              color="primary"
              edge="start"
              name="direction"
              onChange={(event): void => handleChange(
                'responsiveFontSizes',
                event.target.checked
              )}
            />
          )}
          label={(<div>{t('common:messages.responsive_font_sizes').capitalize()}</div>)}
        />
      </Box>
      <Box sx={{ mt: 2, px: 1.5 }}>
        <FormControlLabel
          control={(
            <Switch
              checked={values.compact}
              color="primary"
              edge="start"
              name="compact"
              onChange={(event): void => handleChange('compact', event.target.checked)}
            />
          )}
          label={(<div>{t('glossary:compact_width').capitalize()}</div>)}
        />
      </Box>
      <Box sx={{ mt: 2, px: 1.5 }}>
        <FormControlLabel
          control={(
            <Switch
              checked={values.roundedCorners}
              color="primary"
              edge="start"
              name="roundedCorners"
              onChange={(event): void => handleChange('roundedCorners', event.target.checked)}
            />
          )}
          label={(<div>{t('glossary:rounded_corners').capitalize()}</div>)}
        />
      </Box>
      <Box sx={{ mt: 3 }}>
        <Button color="primary" fullWidth onClick={handleSave} variant="contained">
          {t('glossary:save').capitalize()}
        </Button>
      </Box>
    </Drawer>
  </>;
};

export default SettingsDrawer;
