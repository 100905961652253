import Utilities from 'src/lib/utilities';
import { UserRoleEnum } from 'src/api/generated';
import { IoBarChartOutline } from 'react-icons/io5';
import { FaLanguage, FaRegAddressBook } from 'react-icons/fa';
import { FiUsers } from 'react-icons/fi';
import { TFunction } from 'i18next';
import { RiArtboardLine, RiFoldersFill, RiGalleryLine } from 'react-icons/ri';
import { BiNetworkChart, BiPaint } from 'react-icons/bi';
import { TbMapPins } from 'react-icons/tb';
import { MdOutlineDocumentScanner } from 'react-icons/md';

const sidebarRoutes = (t: TFunction<"translation", undefined>) => {
    return Utilities.getUserRole() === UserRoleEnum.Admin
        ? [
            {
                title: t('glossary:general').capitalize(),
                items: [
                    {
                        title: t('glossary:dashboard').capitalize(),
                        path: '/dashboard',
                        icon: <IoBarChartOutline />,
                        children: []
                    },
                    {
                        title: t('glossary:filemanager').capitalize(),
                        path: '/dashboard/filemanager',
                        icon: <RiFoldersFill />,
                        children: []
                    },
                    {
                        title: t('glossary:languages').capitalize(),
                        path: '/dashboard/languages',
                        icon: <FaLanguage />,
                        children: []
                    },
                    {
                        title: t('glossary:users').capitalize(),
                        path: '/dashboard/users',
                        icon: <FiUsers />,
                        children: []
                    },
                ]
            },
            {
                title: t('glossary:archive').capitalize(),
                items: [
                    {
                        title: t('glossary:artworks').capitalize(),
                        path: '/dashboard/artworks',
                        icon: <RiArtboardLine />,
                        children: []
                    },
                    {
                        title: t('glossary:exhibitions').capitalize(),
                        path: '/dashboard/exhibitions',
                        icon: <RiGalleryLine />,
                        children: []
                    },
                    {
                        title: t('glossary:owners').capitalize(),
                        path: '/dashboard/owners',
                        icon: <FaRegAddressBook />,
                        children: []
                    },
                    {
                        title: t('glossary:documents').capitalize(),
                        path: '/dashboard/documents',
                        icon: <MdOutlineDocumentScanner />,
                        children: []
                    },
                    {
                        title: t('glossary:topics').capitalize(),
                        path: '/dashboard/topics',
                        icon: <BiNetworkChart />,
                        children: []
                    },
                    {
                        title: t('glossary:techniques').capitalize(),
                        path: '/dashboard/techniques',
                        icon: <BiPaint />,
                        children: []
                    },
                    {
                        title: t('glossary:places').capitalize(),
                        path: '/dashboard/places',
                        icon: <TbMapPins />,
                        children: []
                    },
                ]
            }
        ]
        : [
            {
                title: t('glossary:general').capitalize(),
                items: [
                    {
                        title: t('glossary:dashboard').capitalize(),
                        path: '/dashboard',
                        icon: <IoBarChartOutline />,
                        children: []
                    },
                ]
            }
        ]
}

export default sidebarRoutes;