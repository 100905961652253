import _ from "lodash"

const env = (key: string): string => {
    const getEnvVar = (key: string): string => _.get(process.env, key) || _.get(import.meta.env, key);
    let value = getEnvVar(key)
    if (!value) {
        const prefixes = getEnvVar('ENV_PREFIX')?.split(',')
        if (prefixes?.length && typeof prefixes[Symbol.iterator] === 'function')
            for (const prefix of prefixes) {
                if (!value && getEnvVar(prefix + key))
                    value = getEnvVar(prefix + key)
            }
    }
    return value?.toString?.() || null;
};
export default env;