import type { FC } from 'react';
import { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Button, Container, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import gtm from 'src/lib/gtm';
import env from 'src/lib/env';
import { useTranslation } from 'react-i18next';
import useIsSmallScreen from 'src/hooks/useIsSmallScreen';

const AuthorizationRequired: FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useIsSmallScreen();

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  return (
    <>
      <Helmet>
        <title>{`${t('glossary:error').capitalize()}: 401 | ${env('PROJECT_SLUG')?.toUpperCase()}`}</title>
      </Helmet>
      <Box sx={{ alignItems: 'center', backgroundColor: 'background.paper', display: 'flex', minHeight: '100%', px: 3, py: '80px' }}>
        <Container maxWidth="lg">
          <Typography align="center" color="textPrimary" variant={isSmallScreen ? 'h4' : 'h2'}>
            401: Authorization required
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 6 }}>
            <Box
              alt="Under development"
              component="img"
              src={`/static/error/error401_${theme.palette.mode}.svg`}
              sx={{ height: 'auto', maxWidth: '100%', width: 400 }} />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 6 }}>
            <Button color="primary" component={RouterLink} to="/" variant="outlined">
              {t('glossary:back').capitalize()}
            </Button>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default AuthorizationRequired;
