import { atom, WritableAtom } from "jotai"
import _ from "lodash";

interface OptionsI {
    excludeFromPersistence?: string[],
    resetNull?: boolean
}

/**
 * jotai atomWithLocalStorage utility extension - added options
*/
export default function atomWithLocalStorage<Value>(key: string, initialValue: Value, options?: OptionsI): WritableAtom<any, [update: unknown], void> {
    const { excludeFromPersistence, resetNull } = options || {};
    const getInitialValue = () => {
        const item = localStorage.getItem(key)
        return item ? JSON.parse(item) : initialValue
    }
    const baseAtom = atom(getInitialValue())
    const derivedAtom = atom(
        (get) => get(baseAtom),
        (get, set, update) => {
            let nextValue = typeof update === 'function' ? update(get(baseAtom)) : update
            // Reset null keys to default
            if (resetNull)
                nextValue = { ...initialValue, ..._.omitBy(nextValue, (value, key) => !value) }
            set(baseAtom, nextValue)
            // Exclude non-serializable keys before saving to localStorage
            localStorage.setItem(key, JSON.stringify(_.omit(nextValue, excludeFromPersistence)))
        }
    )
    return derivedAtom
}