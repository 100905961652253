import { FC } from 'react';
import { Box, Button } from '@mui/material';
import useMounted from 'src/hooks/useMounted';
import { AuthStore } from 'src/store';
import { useAtom } from 'jotai';
import { useForm } from 'react-hook-form';
import { FormContainer, PasswordElement, TextFieldElement } from 'react-hook-form-mui'
import { makeToast } from 'src/components/Toast';
import env from 'src/lib/env';
import { useTranslation } from 'react-i18next';
import Utilities from 'src/lib/utilities';
import { LoginCredentialsDto } from 'src/api/generated';
import SuspenseLoader from 'src/components/SuspenseLoader';

// type FormData = {
//   userName: string;
//   password: string;
// };

type FormData = LoginCredentialsDto

const Login: FC = () => {
  const { t } = useTranslation();
  const isMounted = useMounted();
  const [authStore, setAuthStore] = useAtom(AuthStore.atom)
  const authDispatch = AuthStore.reducer(authStore, setAuthStore);

  const formContext = useForm<FormData>({
    shouldUnregister: false,
    defaultValues: {
      userName: env('API_DEFAULT_ADMIN_USER'),
      password: env('API_DEFAULT_ADMIN_USER_PASS'),
      // userName: null,
      // password: null,
    }
  })
  const { control, setValue, watch, formState: { isSubmitting, errors } } = formContext;
  const handleSuccess = async (data: FormData) => {
    try {
      await authDispatch.login({ credentials: { ...data } });
    } catch (error) {
      console.error(error)
      makeToast(Utilities.handleError(error).capitalize())
    }
  }

  return (
    <SuspenseLoader loading={isSubmitting}>
      <FormContainer formContext={formContext} onSuccess={data => handleSuccess(data)}>
        <TextFieldElement autoFocus fullWidth name="userName" label={t('glossary:username').capitalize()} margin="normal" validation={{ required: t('validation:errors.required') }} />
        <PasswordElement fullWidth name="password" label={t('glossary:password').capitalize()} margin="normal" validation={{ required: t('validation:errors.required') }} />
        <Box sx={{ mt: 2 }}>
          <Button color="primary" disabled={isSubmitting} fullWidth size="large" type="submit" variant="contained" >
            {t('glossary:login').capitalize()}
          </Button>
        </Box>
      </FormContainer>
    </SuspenseLoader>
  );
};

export default Login;
