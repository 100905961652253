import { useState, useEffect, FC, ReactNode } from 'react';
import { Browser } from '@capacitor/browser';
import { Box } from '@mui/material';
import { io, Socket } from "socket.io-client";
import { API_BASE_URL, IS_NATIVE } from 'src/constants';
import Utilities from 'src/lib/utilities';
import { makeToast } from 'src/components/Toast';
import { usersApi } from 'src/api/api';
import { AuthStore } from 'src/store';
import { useAtom } from 'jotai';

interface SocketPopupProps {
    apiUrl: string;
    event: string;
    callback?: (...args: any[]) => void;
    children?: ReactNode;
    features?: {
        width?: number,
        height?: number,
        left?: number,
        top?: number
    }
}

const SocketPopup: FC<SocketPopupProps> = ({ apiUrl, event, callback, children, features }) => {
    const [authStore,] = useAtom(AuthStore.atom)
    const [popupUrl, setPopupUrl] = useState<string>(null);

    const setUrl = async (socket: Socket): Promise<string | URL> => {
        const url = new URL(apiUrl);
        url.searchParams.append('socketId', socket?.id)
        url.searchParams.append('callback_url', `${window.location.origin}/close`)
        setPopupUrl(() => url.toString());
        return url
    }

    const openPopup = async (openUrl: string | URL) => {
        if (popupUrl) {
            const width = features?.width || 600,
                height = features?.height || 600,
                left = features?.left || (window.innerWidth / 2) - (width / 2),
                top = features?.top || (window.innerHeight / 2) - (height / 2);
            await Utilities.windowOpen(openUrl, '_blank',
                `toolbar=no, location=no, directories=no, status=no, menubar=no, 
                scrollbars=no, resizable=no, copyhistory=no, width=${width}, 
                height=${height}, top=${top}, left=${left}`
            );
        }
    }

    useEffect(() => {
        if (apiUrl) {
            try {
                const authSocket = io(API_BASE_URL, { transports: ["polling"] });
                authSocket.on('connect', async () => {
                    setUrl(authSocket);
                    authStore.user?.id &&
                        (await usersApi().updateOneUser({ id: authStore.user.id, user: { lastSocketId: authSocket.id } }))
                })
                authSocket.on(event, async (data) => {
                    IS_NATIVE && Browser.close()
                    callback?.(data)
                });
                return async () => {
                    authStore.user?.id &&
                        (await usersApi().updateOneUser({ id: authStore.user.id, user: { lastSocketId: null } }))
                    authSocket.off('connect')
                    authSocket.off(event)
                    authSocket.close()
                };
            } catch (error) {
                makeToast(Utilities.handleError(error)?.capitalize?.())
                console.error(error)
                return error.message
            }
        }
    }, [apiUrl]);

    return (
        <Box onClick={() => openPopup(popupUrl)}>
            {children || popupUrl}
        </Box>
    );
}

export default SocketPopup;