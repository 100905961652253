import { Browser } from "@capacitor/browser";
import { FC, useEffect } from "react";
import { IS_NATIVE } from "src/constants";

const WindowClose: FC = () => {
    useEffect(() => {
        IS_NATIVE
            ? Browser.close()
            : window.close();
    }, [])
    return <></>
};

export default WindowClose