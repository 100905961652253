import i18next from "i18next";
import i18nBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { API_BASE_URL } from 'src/constants';
import Utilities from "src/lib/utilities";

const languages = await Utilities.getLanguages(),
  primary = languages?.find(lang => lang.primary)?.code,
  codes = languages?.map(lang => lang.code);

await i18next
  .use(i18nBackend)
  .use(initReactI18next)
  .init(
    {
      lng: primary,
      fallbackLng: primary,
      preload: codes,
      initImmediate: false,
      ns: ['common', 'glossary', 'validation'],
      defaultNS: 'glossary',
      interpolation: { escapeValue: false },
      debug: false,
      react: { useSuspense: true },
      backend: { loadPath: `${API_BASE_URL}/i18n/{{lng}}/{{ns}}.json` }
    },
    (err, t) => err && console.error(err, t)
  );

export default i18next.init;

export const T = (key: string): string => i18next.t(key) // use useTranslation() hook to refresh components on locale change
