import { useCallback, useEffect, useRef } from 'react';

const useMounted = (): () => Boolean => {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true
    return () => { isMounted.current = false }
  }, [])

  return useCallback(() => isMounted.current, [])
};

export default useMounted;
