import { BsQuestionSquareFill } from 'react-icons/bs';
import { mime } from 'mime-types';
import { Language, LanguageApiGetManyLanguageRequest, User, UserRoleEnum } from 'src/api/generated';
import _ from "lodash";
import { API_BASE_URL, IS_NATIVE } from "src/constants";
import { authApi, languagesApi } from 'src/api/api';
import { Browser } from '@capacitor/browser';
// import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
// import { FileOpener } from '@capacitor-community/file-opener';
// import write_blob from "capacitor-blob-writer";
import { Observable } from 'rxjs';
import { SettingsStore } from 'src/store';
// import { rerender } from 'src/App';
import { Buffer } from 'buffer';
import { FC } from 'react';
import { hasFlag } from 'country-flag-icons';
import Flags from 'country-flag-icons/react/3x2';

export default class Utilities {
    // List api languages
    static getLanguages = async (requestParameters: LanguageApiGetManyLanguageRequest = { sort: ['primary,DESC'] }, options: Record<string, any> = {}): Promise<Language[]> => {
        try { return (await languagesApi().getManyLanguage(requestParameters, options))?.data?.data } catch (error) { return [] }
    }

    // Generate array of random data
    // eg. Utilities.makeArray(20, randomWord)
    static makeArray = <T>(length: number, generator: () => T): T[] => {
        return Array.from({ length }, generator)
    }

    // Get random object/enum key
    static randomKey = (obj: Record<string, unknown>): any => {
        const keys = Object.keys(obj);
        return obj[keys[Math.floor(Math.random() * keys.length)]]
    }

    // Convert string to boolean
    static parseBoolean = (string: string): boolean => ((/true/i)).test(string) || string === '1'

    // Serialize function (eg for saving to redux)
    static serializeFn = (fn: (...args: any[]) => any): string => fn.toString()

    // Deserialize function (eg retrieved from redux)
    static deserializeFn = (fn: string): (...args: any[]) => any => eval(fn)

    // Random image source from unsplash api
    static randomImgSrc = (keywords: string | string[]): string => (`https://source.unsplash.com/random?${_.isArray(keywords) ? keywords.join(",") : keywords},${Math.floor(Math.random() * 9999)}`)

    // Random image source from unsplash api
    static apiMediaUrl = (path: string): string => path ? path?.includes('://') ? path : `${API_BASE_URL}/${path}` : ''

    // Get current user role
    static getUserRole = (): UserRoleEnum => localStorage.AuthStore ? (JSON.parse(localStorage.AuthStore)?.user as User)?.role : null

    // Pretty error handling
    static handleError = (error: Record<string, any>): string => {
        // class-validator errors
        if (error.response?.data?.errors)
            return error.response?.data?.errors?.map(err => err.constraints ? err.property.toUpperCase() + ':\n' + Object.values(err.constraints).join('\n') : err)?.join('\n\n')
        // Api HttpException errors
        if (error.response?.data?.message)
            return error.response?.data?.message?.sqlMessage || error.response?.data?.message
        // arrayBuffer errors
        if (error.response?.data?.constructor?.name === 'ArrayBuffer')
            return JSON.parse(Buffer.from(error.response?.data).toString())?.message
        return error.message
    }

    // Verify jwt validity
    static verifyJwt = async (jwt: string): Promise<boolean> => (await authApi().verifyJwt({ jwtDto: { token: jwt } }))?.data?.isValid;

    // Get age from date
    static getAge = (birthDate: Date): Number => {
        let today = new Date(),
            birth = new Date(birthDate),
            age = today.getFullYear() - birth.getFullYear(),
            m = today.getMonth() - birth.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birth.getDate()))
            age = age - 1;
        return age;
    }

    static readFileAsync = async (file: Blob, mime?: string): Promise<string> => {
        return `data:${mime || file.type};base64,${Buffer.from(await file.arrayBuffer()).toString('base64')}`
        // return new Promise((resolve, reject) => {
        //     let reader = new FileReader();
        //     reader.onload = () => { resolve(reader.result as string) };
        //     reader.onerror = reject;
        //     reader.readAsDataURL(file);
        // })
    }

    // Download file
    static downloadFile = async (url: string, filename: string = new Date().toString(), mime: string = ''): Promise<void> => {
        // const settings = SettingsStore.atom.read(get => get?.init);
        try {
            let blob: Blob;
            if (Utilities.isUrl(url)) {
                if (IS_NATIVE) {
                    Utilities.windowOpen(url)
                    return;
                }
                else blob = await (await fetch(url))?.blob();
            } else
                blob = new Blob([url], { type: mime }) // need to set responseType to "arraybuffer"
            if (IS_NATIVE) {
                // settings.isLoading = true; 
                // rerender();
                // https://capacitorjs.com/docs/apis/filesystem#example
                // const uri = (await Filesystem.writeFile({
                //     path: filename,
                //     data: await Utilities.readFileAsync(blob),
                //     directory: Directory.Documents,
                //     encoding: Encoding.UTF8,
                // }))?.uri
                // https://github.com/diachedelic/capacitor-blob-writer
                // await write_blob({
                //     path: filename,
                //     directory: Directory.Documents,
                //     blob,
                //     fast_mode: true,
                //     recursive: true,
                //     async on_fallback(error) { console.log(error) }
                // })
                // const { uri } = await Filesystem.getUri({ path: filename, directory: Directory.Documents })
                // // https://www.npmjs.com/package/@capacitor-community/file-opener
                // const opening = new Observable(subscriber => {
                //     subscriber.next();
                //     (async () => {
                //         await FileOpener.open({ filePath: uri, contentType: mime, openWithDefault: false });
                //         subscriber.complete();
                //     })()
                // })
                // opening.subscribe({
                //     next(x) { console.log('Start opening file.') },
                //     error(error) { console.error(error) },
                //     complete() { console.log('End opening file.') /* settings.isLoading = false; rerender() */ },
                // })
            } else {
                const fileURL = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = fileURL;
                a.download = `${filename}`;
                a.click();
            }
        } catch (error) {
            console.error(error)
        }
    }

    // Cross platform window open
    static windowOpen = async (url?: string | URL, target?: string, features?: string): Promise<Window> => {
        const mobileOpen = (async (url: string | URL): Promise<any> => await Browser.open({ url: url.toString() })) as unknown as typeof window.open
        return IS_NATIVE ? mobileOpen(url) : window.open(url, target, features).window
    }

    // Check if a string is a valid url
    static isUrl = (url: string) => {
        try { new URL(url); return url.includes('http') }
        catch (error) { return false }
    }

    // Get country flag by code
    static getFlag = (code?: string): FC => code?.toUpperCase()?.includes('EN')
        ? Flags['GB']
        : hasFlag(code)
            ? Flags[code?.toUpperCase()]
            : BsQuestionSquareFill

    // Copy string to clipboard 
    static copyToClipboard = (string: string) => {
        navigator.clipboard.writeText(string)
    }
}

