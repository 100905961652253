import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Box, IconButton, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { AppBarProps } from '@mui/material';
import { HiMenuAlt2 } from 'react-icons/hi';
import AccountPopover from 'src/components/dashboard/navbar/AccountPopover';
import Logo from 'src/components/Logo';
import { NAV_HEIGHT } from 'src/constants';
import NotificationsPopover from 'src/components/dashboard/navbar/NotificationsPopover';

interface DashboardNavbarProps extends AppBarProps {
  onSidebarMobileOpen?: () => void;
}

const DashboardNavbarRoot = styled(AppBar)(
  ({ theme }) => (
    {
      ...(
        theme.palette.mode === 'light' && {
          backgroundColor: theme.palette.primary.main,
          boxShadow: 'none',
          color: theme.palette.primary.contrastText
        }
      ),
      ...(
        theme.palette.mode === 'dark' && {
          backgroundColor: theme.palette.background.paper,
          borderBottom: `1px solid ${theme.palette.divider}`,
          boxShadow: 'none'
        }
      ),
      zIndex: theme.zIndex.drawer + 100
    }
  )
);

const DashboardNavbar: FC<DashboardNavbarProps> = (props) => {
  const { onSidebarMobileOpen, ...other } = props;

  return (
    <DashboardNavbarRoot
      {...other}
      sx={{ minHeight: NAV_HEIGHT, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
    >
      <Toolbar>
        <IconButton color="inherit" onClick={onSidebarMobileOpen} sx={{ display: { lg: 'none' } }} >
          <HiMenuAlt2 />
        </IconButton>
        <RouterLink to="/">
          <Logo sx={{ display: { lg: 'inline', xs: 'none' }, marginTop: '10px', height: NAV_HEIGHT - 20, pb: '5px' }} />
        </RouterLink>
        <Box sx={{ flexGrow: 1, ml: 2 }} />
        {/* <Box sx={{ mx: 1 }}>
          <NotificationsPopover />
        </Box> */}
        <Box sx={{ ml: 2 }}>
          <AccountPopover />
        </Box>
      </Toolbar>
    </DashboardNavbarRoot >
  );
};

export default DashboardNavbar;
