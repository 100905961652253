import { RESET } from 'jotai/utils'
import { SetStateAction } from 'jotai';
import atomWithLocalStorage from 'src/lib/atomWithLocalStorage';
import { StoreI } from 'src/store';

export interface LightboxStoreI {
    open: boolean,
    currentIndex: number,
    images: string[]
}

const Atom = atomWithLocalStorage<LightboxStoreI>(
    "LightboxStore",
    {
        open: false,
        currentIndex: 0,
        images: []
    },
    {
        excludeFromPersistence: ['open', 'currentIndex', 'images'],
        resetNull: true
    }
)

const Reducer = (current: LightboxStoreI, setter: (update?: typeof RESET | SetStateAction<LightboxStoreI>) => void) => {
    return {
        set: async (payload: Partial<LightboxStoreI>): Promise<void> => {
            await setter({ ...current, ...payload })
        },

        reset: async (): Promise<void> => {
            await setter(RESET)
        }
    }
}

const LightboxStore: StoreI<LightboxStoreI, typeof Reducer> = { atom: Atom, reducer: Reducer }

export default LightboxStore