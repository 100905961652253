import { useEffect, useState } from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Card, CardContent, Container, Divider, IconButton, Link, Typography, useTheme } from '@mui/material';
import LoginForm from 'src/components/authentication/Login';
import Logo from 'src/components/Logo';
import gtm from 'src/lib/gtm';
import env from 'src/lib/env';
import SocketPopup from 'src/components/SocketPopup';
import { ImGoogle3 } from 'react-icons/im';
import { FaFacebook } from 'react-icons/fa';
import Utilities from 'src/lib/utilities';
import { useTranslation } from 'react-i18next';
import { LoginResponseDto, UserProviderEnum } from 'src/api/generated';
import { makeToast } from 'src/components/Toast';
import { useAtom } from 'jotai';
import { AuthStore } from 'src/store';
import { authApi } from 'src/api/api';
import { API_BASE_URL_SOCKET } from 'src/constants';

const Login: FC = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [authStore, setAuthStore] = useAtom(AuthStore.atom)
    const authDispatch = AuthStore.reducer(authStore, setAuthStore);
    const [googleLoginUrl, setGoogleLoginUrl] = useState<string>()
    const [fbLoginUrl, setFbLoginUrl] = useState<string>()

    const loginCallback = async (data) => {
        const { user, token } = data as LoginResponseDto;
        // console.log(user, token)
        data?.hasOwnProperty('error')
            ? makeToast(Utilities.handleError(data)?.capitalize()) && console.error(data)
            : authDispatch.loggedIn({ user, accessToken: token })
    }

    useEffect(() => {
        gtm.push({ event: 'page_view' });
        (async () => {
            // setGoogleLoginUrl(`${API_BASE_URL_SOCKET}${(await authApi().googleLoginUrl())?.data}`)
            // setFbLoginUrl(`${API_BASE_URL_SOCKET}${(await authApi().fbLoginUrl())?.data}`)
        })()
    }, []);

    return (
        <>
            <Helmet>
                <title>{`${t('glossary:login').capitalize()} | ${env('PROJECT_SLUG')?.toUpperCase()}`}</title>
            </Helmet>
            <Box sx={{ backgroundColor: 'background.default', display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                <Container maxWidth="sm" sx={{ py: '80px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                        <RouterLink to="/">
                            <Logo sx={{ height: 200, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', ...(theme.palette.mode === 'light' && { filter: 'drop-shadow(0px 2px 1px #aaa)' }) }} />
                        </RouterLink>
                    </Box>
                    <Card>
                        <CardContent sx={{ display: 'flex', flexDirection: 'column', p: 4 }}>
                            <Box sx={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
                                <div>
                                    <Typography color="textPrimary" variant="h4">
                                        {t('glossary:login').capitalize()}
                                    </Typography>
                                </div>
                                {
                                    Utilities.parseBoolean(env('ENABLE_OAUTH')) &&
                                    <Box sx={{ alignItems: 'center', display: 'flex', justifyContent: 'flex-end' }}>
                                        <Box sx={{ height: 32, '& > img': { maxHeight: '100%', width: 'auto' } }}>
                                            <SocketPopup event={UserProviderEnum.Google} apiUrl={googleLoginUrl} callback={loginCallback}>
                                                <IconButton color="primary">
                                                    <ImGoogle3 />
                                                </IconButton>
                                            </SocketPopup>
                                        </Box>
                                        <Box sx={{ height: 32, '& > img': { maxHeight: '100%', width: 'auto' } }}>
                                            <SocketPopup event={UserProviderEnum.Facebook} apiUrl={fbLoginUrl} callback={loginCallback}>
                                                <IconButton color="primary">
                                                    <FaFacebook />
                                                </IconButton>
                                            </SocketPopup>
                                        </Box>
                                    </Box>
                                }
                            </Box>
                            <Box sx={{ flexGrow: 1, mt: 2 }}>
                                <LoginForm />
                            </Box>
                            <Divider sx={{ my: 3 }} />
                            {/* <Link color="textSecondary" component={RouterLink} to="/authentication/register" variant="body2">
                                {t('common:messages.register').capitalize()} {t('glossary:register').capitalize()}
                            </Link> */}
                            <Link color="textSecondary" component={RouterLink} sx={{ mt: 1 }} to="/authentication/password-recovery" variant="body2">
                                {t('common:messages.password_forgot').capitalize()}
                            </Link>
                        </CardContent>
                    </Card>
                </Container>
            </Box>
        </>
    );
};

export default Login;
