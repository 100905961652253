import { useEffect } from 'react';
import type { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Container } from '@mui/material';
import gtm from 'src/lib/gtm';
import env from 'src/lib/env';
import { useAtom } from 'jotai';
import { AuthStore, SettingsStore } from 'src/store';
import { useTranslation } from 'react-i18next';

const Dashboard: FC = () => {
    const { t } = useTranslation();
    const [settings,] = useAtom(SettingsStore.atom);
    const [authStore,] = useAtom(AuthStore.atom)

    useEffect(() => {
        gtm.push({ event: 'page_view' });
    }, []);

    return (
        <>
            <Helmet>
                <title>{`${t('glossary:dashboard').capitalize()} | ${env('PROJECT_SLUG')?.toUpperCase()}`}</title>
            </Helmet>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 8 }}>
                <Container maxWidth={settings.compact ? 'xl' : false}>
                    <h2>{t('glossary:dashboard').capitalize()}</h2>
                    {t('glossary:hi').capitalize()} {authStore.user?.userName}
                </Container>
            </Box>
        </>
    );
};

export default Dashboard;
