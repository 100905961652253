import type { Theme } from "@mui/material"
import { createStyles } from "@mui/styles"

/**
 * JSS Classes
 * @param theme Theme
 * @returns ClassNameMap<never>
 */
const classes = (theme: Theme) => {
    return createStyles({
        filename: {
            color: 'red!important',
            '& .Mui-disabled:before': {
                border: "none!important",
                color: theme.palette.action.active
            }
        },
        tiltParallaxGlareScale: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "170px",
            height: "170px",
            cursor: "pointer",
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
            transformStyle: "preserve-3d",
            margin: "20px",
            borderRadius: theme.shape.borderRadius,
            "& .inner-element": {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "35px",
                color: theme.palette.primary.main,
                transform: "translateZ(60px)",
            }
        }
    })
}

export default classes