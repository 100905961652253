import { useRef, useState } from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Avatar, Box, Button, ButtonBase, Divider, ListItemIcon, ListItemText, MenuItem, Popover, Typography } from '@mui/material';
import { IoCog } from 'react-icons/io5';
import env from 'src/lib/env';
import { makeToast } from 'src/components/Toast';
import { useAtom } from 'jotai';
import { AuthStore } from 'src/store';
import Utilities from 'src/lib/utilities';
import { useTranslation } from 'react-i18next';
import ConfirmButton from 'src/components/buttons/ConfirmButton';

const AccountPopover: FC = () => {
  const { t } = useTranslation();
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [authStore, setAuthStore] = useAtom(AuthStore.atom)
  const authDispatch = AuthStore.reducer(authStore, setAuthStore);
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      await authDispatch.logout();
      navigate('/dashboard');
    } catch (err) {
      console.error(err);
      makeToast(t('common:errors.logout'));
    }
  };

  return (
    <>
      <Box component={ButtonBase} onClick={handleOpen} ref={anchorRef} sx={{ alignItems: 'center', display: 'flex', borderRadius: '50%' }}>
        <Avatar src={Utilities.apiMediaUrl(authStore.user?.avatar?.formats?.original?.url) || authStore.user?.avatarUrl} sx={{ height: 32, width: 32 }} />
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        keepMounted
        onClose={handleClose}
        onClick={handleClose}
        open={open}
        PaperProps={{ sx: { width: 240 } }}
      >
        <Box sx={{ p: 2 }}>
          <Typography color="textPrimary" variant="subtitle2">
            {authStore.user.userName}
          </Typography>
          <Typography color="textSecondary" variant="subtitle2">
            {env('TITLE')}
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ mt: 2 }}>
          <MenuItem component={RouterLink} to="/dashboard/account">
            <ListItemIcon>
              <IoCog />
            </ListItemIcon>
            <ListItemText
              primary={(
                <Typography color="textPrimary" variant="subtitle2">
                  {t('glossary:settings').capitalize()}
                </Typography>
              )}
            />
          </MenuItem>
        </Box>
        <Box sx={{ p: 2 }}>
          <ConfirmButton color="primary" fullWidth onConfirm={handleLogout} variant="outlined">
            {t('glossary:logout').capitalize()}
          </ConfirmButton>
        </Box>
      </Popover>
    </>
  );
};

export default AccountPopover;
