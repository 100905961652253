import { useAtom } from 'jotai';
import type { FC, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import { UserRoleEnum } from 'src/api/generated';
import AuthorizationRequired from 'src/pages/AuthorizationRequired';
import { AuthStore } from 'src/store';

interface GuestGuardProps {
  roles: UserRoleEnum[];
  children?: ReactNode;
}

const RoleGuard: FC<GuestGuardProps> = ({ roles, children }) => {
  const [authStore,] = useAtom(AuthStore.atom)
  const userRole = authStore.user?.role;

  if (!roles.includes(userRole)) {
    return <AuthorizationRequired />;
  }

  return <>{children || <Outlet />}</>;
};

export default RoleGuard;
