import { useEffect } from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Avatar, Box, Button, Divider, Drawer, Typography } from '@mui/material';
import type { Theme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import Logo from 'src/components/Logo';
import NavSection from 'src/components/dashboard/sidebar/NavSection';
import Scrollbar from 'src/components/Scrollbar';
import { API_BASE_URL, APP_HOST, NAV_HEIGHT } from 'src/constants';
import { useAtom } from 'jotai';
import { AuthStore } from 'src/store';
import Utilities from 'src/lib/utilities';
import sidebarRoutes from 'src/sidebarRoutes';
import { useTranslation } from 'react-i18next';

interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const { t } = useTranslation();
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const [authStore,] = useAtom(AuthStore.atom)

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box sx={{ display: { lg: 'none', xs: 'flex' }, justifyContent: 'center', p: 2 }}>
          <RouterLink to="/">
            <Logo sx={{ height: NAV_HEIGHT - 20 }} />
          </RouterLink>
        </Box>
        <Box sx={{ p: 2 }}>
          <Box sx={{ alignItems: 'center', backgroundColor: 'background.default', borderRadius: 1, display: 'flex', overflow: 'hidden', p: 2 }}>
            <RouterLink to="/dashboard/account">
              <Avatar src={Utilities.apiMediaUrl(authStore.user?.avatar?.formats?.original?.url) || authStore.user?.avatarUrl} sx={{ cursor: 'pointer', height: 48, width: 48 }} />
            </RouterLink>
            <Box sx={{ ml: 2 }}>
              <Typography color="textPrimary" variant="subtitle2">
                {authStore.user.userName}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sidebarRoutes(t).map((section) => (
            <NavSection key={section.title} pathname={location.pathname} sx={{ '& + &': { mt: 1 } }} {...section} />
          ))}
        </Box>
        {/* <Divider />
        <Box sx={{ p: 2 }}>
          <Button color="primary" fullWidth sx={{ mt: 2 }} href={APP_HOST} target="_blank" variant="contained">
            {t('glossary:public').capitalize()}
          </Button>
          <Button color="primary" fullWidth sx={{ my: 2 }} href={`${API_BASE_URL}/docs`} target="_blank" variant="contained">
            {t('glossary:api').capitalize()}
          </Button>
        </Box> */}
      </Scrollbar>
    </Box>
  );

  return lgUp
    ? (
      <Drawer
        anchor="left"
        open
        PaperProps={{ sx: { backgroundColor: 'background.paper', height: `calc(100% - ${NAV_HEIGHT}px)!important`, top: `${NAV_HEIGHT}px!important`, width: 280 } }}
        variant="permanent"
      >
        {content}
      </Drawer>
    )
    : (
      <Drawer
        anchor="left"
        onClose={onMobileClose}
        open={openMobile}
        PaperProps={{ sx: { backgroundColor: 'background.paper', top: `${NAV_HEIGHT}px!important`, width: 280 } }}
        variant="temporary"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}
      >
        {content}
      </Drawer>
    );
};

export default DashboardSidebar;
